<template>
  <div v-html="value"></div>
</template>

<script>
export default {
  name: "RichText",
  props: { field: Object, value: Boolean },
};
</script>
