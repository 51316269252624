import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import VueI18n from 'vue-i18n';
import Popup from 'vue-bgl-popup';
import bfIcon from './views/admin/components/icon.vue';
import VueClipboard from 'vue-clipboard2';

import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
Vue.use(Toast);

Vue.use(VueClipboard);
Vue.component('popup', Popup);

import Buefy from 'buefy';

Vue.use(Buefy);
Vue.component('bf-icon', bfIcon);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

Vue.use(VueI18n);

import en from './lang/en';
import he from './lang/he';
// Creates an instance of VueI18n that must be inserted into the vue object creation
var languagesData = { en, he };
Vue.use(VueRouter);
import Bagel from '@bageldb/bagel-db';
const db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
Vue.prototype.$db = db;
Vue.prototype.$ga = (event) => window.dataLayer.push(event);
Vue.config.productionTip = false;
Vue.prototype.$lang = new VueI18n({
  locale: 'he',
  fallbackLocale: 'he',
  messages: languagesData,
});

Vue.prototype.$l = function (key) {
  var values = [],
    len = arguments.length - 1;
  while (len-- > 0) values[len] = arguments[len + 1];

  var i18n = this.$lang;
  return i18n._t.apply(i18n, [key, i18n.locale, i18n._getMessages(), this].concat(values));
};

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
