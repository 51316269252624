<template>
  <span
    class="material-symbols-outlined"
    :style="{
      'font-size': size + 'px',
    }"
  >
    {{ icon }}
  </span>
</template>

<script>
export default {
  name: "bf-icon",
  props: {
    icon: String,
    size: [String, Number],
  },
};
</script>
