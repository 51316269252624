<template>
<div class="main">
  <popup
    width="90%"
    v-model="youtube"
  >
    <!--YOUR SLOT HERE-->
    <div class="yt-wrap">
      <iframe
        class="yt"
        :src="youtube"
        title="YouTube video player"
        frameborder="0"
      ></iframe>
    </div>
  </popup>

  <div class="home-bg" v-if="$lang.locale == 'he'">
    <div class="home-dark-sec">
      <img
        class="main-flower"
        src="../assets/flower2.png"
        alt="flower"
      />

      <h1 class="home-title">
          בשנה בה אדמת הארץ נספגה בדם גיבורים, אנו זוכרים וצומחים מצוואתם: חיים ותקומה.
        </h1>
        <p class="home-subtitle">
          ביום הזיכרון הקרוב נענוד כולנו את סיכת פרח דם המכבים המסמלת ומזכירה את הצמיחה למרות ומתוך השכול והשבר.
        </p>
    </div>
        <div class="top-box-link-wrap">
          <router-link class="top-box-link" to="/companies">
            <p>לקבלת הצעת מחיר לחברות</p>
            <div></div>
            <img src="../assets/link1.jpg" alt="לקבלת הצעת מחיר לחברות">
          </router-link>
          <router-link class="top-box-link" to="/points">
            <p>לרשימת נקודות המכירה שלנו</p>
            <div></div>
            <img src="../assets/link2.jpg" alt="לרשימת נקודות המכירה שלנו">
          </router-link>
          <router-link class="top-box-link" to="/schools">
            <p>לקבלת הצעת מחיר לבתי ספר</p>
            <div></div>
            <img src="../assets/link3.jpg" alt="לקבלת הצעת מחיר לבתי ספר">
          </router-link>
        </div>
   <div class="home-dark-sec">
          <router-link
            to="/donations"
            class="btn btn-white"
          >{{
            $l("donate")
          }}</router-link>
          <br>
          <p class="home-open">*בני משפחות שכולות מוזמנים לאסוף סיכות ללא תשלום מהעמדות הפתוחות
          </p>
  
</div>
  </div>

  <div class="home-bg" v-if="$lang.locale == 'en'">
    <div class="home-dark-sec">
      <img
        class="main-flower"
        src="../assets/flower2.png"
        alt="flower"
      />

      <h1 class="home-title">In a year that was marked by the great sacrifice of our nation’s heroes, whose blood leaves its marks on our land for eternity, we honor their legacy of strength and devotion.
        </h1>
        <p class="home-subtitle">This Yom HaZikaron we will all wear the Dam HaMaccabim flower pin, symbolizing our strength, perseverance and resilience in the wake of great tragedy and loss.
        </p>
        
    </div>
        <div class="top-box-link-wrap">
          <router-link class="top-box-link" to="/companies">
            <p>Get a quote for companies</p>
            <div></div>
            <img src="../assets/link1.jpg" alt="Get a quote for companies">
          </router-link>
          <router-link class="top-box-link" to="/points">
            <p>List of our Distribution Locations</p>
            <div></div>
            <img src="../assets/link2.jpg" alt="List of our Distribution Locations">
          </router-link>
          <router-link class="top-box-link" to="/schools">
            <p>Get a quote for schools</p>
            <div></div>
            <img src="../assets/link3.jpg" alt="Get a quote for schools">
          </router-link>
        </div>
   <div class="home-dark-sec">
    <p>For a USA tax-deductible donation, please click here:</p>
        <a
          class="btn btn-white"
          href="https://pe4ch.com/ref/3SAQjLjZ2che?lang=en"
          target="_blank"
        >Donate</a>
        <!-- <router-link
          to="/donations"
          class="btn top"
        >{{
      $l("donate")
    }}</router-link> -->
        <br>
        <p class="home-open">Bereaved families are invited to collect pins free of charge from all stall locations.
        </p>
</div>
  </div>

  <div class="last-txt-wrap">
    <p>
      {{ $l("silverPlatter1") }}
      <br />
      {{ $l("silverPlatter2") }}
    </p>
    <img src="../assets/quotation.svg" />
  </div>

  <div class="dark-links-wrap">
    <router-link
      to="/story"
      class="dark-link"
    >
      <div class="dark-link-txt-wrap">
        <p class="dark-link-name">{{ $l("link1") }}</p>
        <p class="dark-link-small">{{ $l("smallLink1") }}</p>
      </div>
      <div class="dark-link-grad"></div>
      <img
        class="dark-link-img"
        src="../assets/pick2.jpg"
      />
    </router-link>
    <router-link
      to="/donations"
      class="dark-link"
    >
      <div class="dark-link-txt-wrap">
        <p class="dark-link-name">{{ $l("link2") }}</p>
        <p class="dark-link-small">{{ $l("smallLink2") }}</p>
      </div>
      <div class="dark-link-grad"></div>
      <img
        class="dark-link-img"
        src="../assets/home1.jpg"
      />
    </router-link>
    <router-link
      to="/about"
      class="dark-link"
    >
      <div class="dark-link-txt-wrap">
        <p class="dark-link-name">{{ $l("link3") }}</p>
        <p class="dark-link-small">{{ $l("smallLink3") }}</p>
      </div>
      <div class="dark-link-grad"></div>
      <img
        class="dark-link-img"
        src="../assets/flowers3.jpg"
      />
    </router-link>
    <router-link
      to="/families"
      class="dark-link"
    >
      <div class="dark-link-txt-wrap">
        <p class="dark-link-name">{{ $l("link4") }}</p>
        <p class="dark-link-small">{{ $l("smallLink4") }}</p>
      </div>
      <div class="dark-link-grad"></div>
      <img
        class="dark-link-img"
        src="../assets/home4.jpg"
      />
    </router-link>
    <router-link
      to="/points"
      class="dark-link"
    >
      <div class="dark-link-txt-wrap">
        <p class="dark-link-name">{{ $l("link5") }}</p>
        <p class="dark-link-small">{{ $l("smallLink5") }}</p>
      </div>
      <div class="dark-link-grad"></div>
      <img
        class="dark-link-img"
        src="../assets/partners.jpg"
      />
    </router-link>
    <router-link
      to="/gallery#news"
      class="dark-link"
    >
      <div class="dark-link-txt-wrap">
        <p class="dark-link-name">{{ $l("link6") }}</p>
        <p class="dark-link-small">{{ $l("smallLink6") }}</p>
      </div>
      <div class="dark-link-grad"></div>
      <img
        class="dark-link-img"
        src="../assets/news.jpg"
      />
    </router-link>
  </div>
</div>
</template>

<script>
import lang from "../lang/en";
// import bannerLink from "../components/banner.vue";
export default {
  // components: { bannerLink },
  name: "Home",
  data() {
    return {
      youtube: "",
    };
  },
  computed: {
    lang() {
      return lang;
    },
  },
};
</script>

<style>
.btn-white{
  color: #1d3779;
  background: white;
  padding-inline: 5rem;
  margin-bottom: 2rem;
}
.btn-white::after{
  border: white 1px solid;
}
.top-box-link-wrap{
  display: flex;
  gap: 1rem;
  max-width: 1160px;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  background: rgba(29, 55, 121, 0.9);
}

.top-box-link{
  font-family: "Expresivi", serif;
  color: inherit;
  text-decoration: none;
  font-size: 28px;
  max-height: 500px;
  height: 40vw;
  position: relative;
  flex-grow: 1;
  z-index: 3;
  transition: all 200ms ease;
}
.top-box-link img{
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 2;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  /* filter: grayscale(70%) ; */
  transition: all 200ms ease;
}
.top-box-link p{
  position: absolute;
  text-align: center;
  color: white;
  text-wrap: balance;
  z-index: 4;
  width: 255px;
  margin: 0 auto;
  inset: auto 0 2rem 0;
}
.top-box-link div{
  position: absolute;
  inset: 0;
  background: linear-gradient(#0003, #000000cf);
  z-index: 3;
  transition: all 400ms ease;
  opacity: 0.6;


}
.top-box-link:hover img{
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.5);
  filter: grayscale(0%);
}
/* .top-box-link:hover div{
opacity: 0.6;
} */
.top-box-link:hover{
transform: scale(1.01);
}


.top-box-link:active img{
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}
.top-box-link:active div{
opacity: 1;
}
.top-box-link:active{
transform: scale(1);
}

.red {
  color: #b61937;
}

.home-title-wrap {
  max-width: 745px;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  color: white;
  padding: 0rem 0;

}

.home-title {
  font-family: "Expresivi", serif;
  font-size: 54px;
  margin: 0;
  font-weight: 400;
}

.eng .home-title {
  font-family: serif;
  font-size: 40px;
  margin-bottom: 0.75rem
}

.home-subtitle {
  font-family: "Expresivi", serif;
  font-size: 28px;
  line-height: 1.1;
  margin: 0% auto;
  /* max-width: 460px; */
  text-wrap: balance;
  padding: 1rem 0;
}

.eng .home-subtitle {
  font-family: serif;
  line-height: 1.3;
}


.home-open {
  font-size: 20px;
  line-height: 1.5;
  text-wrap: balance;
  margin: 0;
  padding-bottom: 0.5rem;
}

.eng .home-open {
  font-family: serif;
}

.home-open.txt24 {
  font-size: 24px;
  padding-top: 1rem;
  padding-bottom: 2rem;
}


.home-bg {
  /* background: url(../assets/home-bg.jpg); */
  background: url(../assets/bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  padding: 6rem 0 0 0;
}

.home-dark-sec {
  background: #f1f1f1;
  background: rgba(29, 55, 121, 0.9);
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 3rem 6rem;
  color: white;
  text-align: center;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.dark-links-wrap::before{
  content: "";
  max-width: 1000px;
  width: 90%;
  height: 30px;
  margin: 0 auto;
  background: rgba(29, 55, 121, 0.9);
}
@media screen and (max-width: 767px) {
  .home-dark-sec{
    padding: 2rem 2rem;
  }

  .home-title-wrap {
    width: 98%;
    padding: 1.5rem 1rem;
  }

  .home-title {
    font-size: 32px;
  }

  .home-subtitle {
    font-size: 24px;
    text-wrap: balance;
  }

  .home-open {
    font-size: 16px;
    text-wrap: unset;
  }
  .top-box-link-wrap{
  flex-direction: column;
  /* max-width: 300px; */
}
.top-box-link-wrap{
  align-items: center;
}

.top-box-link {
    height: 300px;
   width: 300px;
}
.last-txt-wrap {
  position: relative;
  margin: 60px 20px;
}
}
</style>