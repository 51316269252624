<template>
  <div>
    <div>{{ qr }}</div>
    <button v-if="!waiting" @click="initQRcode">Start</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Whatsapp",
  data() {
    return { qr: "", waiting: false };
  },
  methods: {
    initQRcode() {
      axios.post("/api/whatsapp-auth-background");
      this.qr = "waiting...";
      this.$db.collection("whatsappAuth").listen(({ data }) => {
        let { item } = JSON.parse(data);
        this.qr = item.qr;
      });
    },
  },
};
</script>
