<template>
  <div class="main">
    <iframe
      class="iframe-payment"
      v-if="paymentURL"
      :src="paymentURL"
    ></iframe>
    <!-- <div class="big-white-box move-top for-now" v-else-if="finished">
      <h3>{{ $l("thankYou") }}</h3>
      <p>
        {{ $l("JustAround") }}<br />
        {{ $l("start") }}
        <br />
        <br />
        {{ $l("Sincerely") }}
        <br />
        {{ $l("orgName") }}
        <br />
      </p>
      <a
        class="footer-link"
        href="https://www.facebook.com/damhamacabim/"
        target="_blank"
      >
        <img src="../assets/facebook.svg" alt="facebook" />
      </a>
    </div>-->
    <div
      v-else
      class="big-white-box move-top"
    >
      <h1 class="top-head">הזמנת סיכות</h1>
      <p class="nonprofitsub">
        משפחות שכולות - אנא לחצו
        <router-link
          class="small-link"
          to="/families"
        >כאן</router-link>לקבלת
        הסיכות ללא עלות.
      </p>
      <form
        class="order-form"
        @submit.prevent="submitOrder"
        v-if="orderStatus != 'success'"
      >
        <div
          class="order-option"
          v-if="!order.delivery"
        >
          <h3 class="order-small-head">אנא בחרו נקודת איסוף</h3>
          <a
            href="https://www.google.com/maps/d/u/0/viewer?mid=1WZIQyv5t_9wIp7IBNGIyZmYID91LT4xc"
            target="_blank"
            class="order-small-btn"
          >היעזרו במפה שלנו למציאת נקודת האיסוף הנוחה עבורכם</a>
          <multiselect
            v-model="selectedSpot"
            :options="distributionStock"
            :multiple="false"
            group-values="spots"
            group-label="name"
            openDirection="bottom"
            :showLabels="false"
            :group-select="false"
            placeholder="הקלד/י שם עיר או יישוב"
            track-by="address"
            :customLabel="locationLabel"
            selectLabel
            deselectLabel
            @select="selectSpot"
          >
            <template #singleLabel="props">
              <input
                style="width: 100%; background: transparent; border: none"
                type="text"
                required
                :value="`${props.option.name} - ${props.option.address}`"
              />
            </template>
            <template #placeholder>
              <input
                placeholder="הקלד/י שם עיר או יישוב"
                style="width: 100%; background: transparent; border: none"
                type="text"
                required
              />
            </template>
            <template #option="props">
              <div class="option__desc">
                {{ props.option.$groupLabel }}
                <b class="option__title">{{ props.option.name }}</b>
                <span
                  v-if="props.option.address"
                  class="option__small"
                >- {{ props.option.address }}</span>
                <p
                  v-if="!props.option.$isLabel"
                  style="font-size: smaller; opacity: 0.9; margin-top: 6px"
                >
                  נותרו:
                  <b>
                    {{ props.option.stockReal }}
                  </b>
                  פרחים אמיתיים
                  <b> {{ props.option.stockPlastic }} </b>דמוי פרחים מפלסטיק
                </p>
                <p
                  style="font-size: smaller; opacity: 0.7"
                  v-if="!!numReal &&
                    props.option.maxReal < props.option.numReal + numReal
                    "
                >
                  אין מספיק מלאי של פרח אמיתי בנקודה להזמנה זו
                </p>
                <p
                  style="font-size: smaller; opacity: 0.7"
                  v-if="!!numPlastic &&
                    props.option.maxPlastic <
                    props.option.numPlastic + numPlastic
                    "
                >
                  אין מספיק מלאי של דמוי פרח בנקודה להזמנה זו
                </p>
              </div>
            </template>
            <span slot="noResult">לא מצאנו את האיזור המבוקש</span>
          </multiselect>

          <!-- <div class="order-select-wrap" v-if="!$route.query.dist">
            <select
              class="order-input"
              name="Distribution"
              required
              v-model="order.distribution[0].itemRefID"
              @change="order.distribution[0].spots[0].itemRefID = ''"
            >
              <option disabled value>אזור *</option>
              <option v-for="dist in distribution" :value="dist._id" :key="dist._id">{{ dist.name }}</option>
            </select>
            <img class="down-arrow" src="../assets/down.svg" alt />
          </div>-->
          <!-- <div class="order-select-wrap" :class="{ inactive: !locationSpots.length }">
            <select
              class="order-input"
              name="Dist Spots"
              required
              :disabled="!locationSpots.length"
              v-model="order.distribution[0].spots[0].itemRefID"
            >
              <option value>נקודת איסוף *</option>
              <optgroup v-for="(spots, key) in groupArray(locationSpots)" :key="key" :label="key">
                <option
                  v-for="spot in spots"
                  :key="spot._id"
                  :value="spot._id"
                  :disabled="
                    (!!numReal && spot.maxReal < spot.numReal + numReal) ||
                    (!!numPlastic &&
                      spot.maxPlastic < spot.numPlastic + numPlastic)
                  "
                >
                  {{ spot.address }}
                  <b
                    v-if="!!numReal && spot.maxReal < spot.numReal + numReal"
                  >- אזל מלאי של פרח אמיתי</b>
                  <b
                    v-if="
                      !!numPlastic &&
                      spot.maxPlastic < spot.numPlastic + numPlastic
                    "
                  >- אזל מלאי של דמוי פרח</b>
                </option>
              </optgroup>
            </select>
            <img class="down-arrow" src="../assets/down.svg" alt />
          </div>-->

          <!-- <button
            type="button"
            class="order-small-btn"
            @click.prevent="order.delivery = true"
          >
            לחצו כאן לביצוע משלוח בתוספת 50 ₪
          </button>-->
          <div v-if="selectedSpot">
            <h3>{{ selectedSpot.name }}</h3>
            <p>
              <b>כתובת:</b>
              {{ selectedSpot.address }}
            </p>
            <p>
              <b>איש.ת קשר:</b>
              {{ selectedSpot.contactPerson }}
            </p>
            <p>
              <b>טלפון:</b>
              {{ selectedSpot.phone }}
            </p>
          </div>
        </div>
        <div class="flower-types-wrap">
          <div
            class="flower-type"
            :class="{ outof: selectedSpot ? !selectedSpot.stockReal : false }"
          >
            <p class="flower-type-head">
              פרח
              <br />דם המכבים
            </p>
            <img
              src="../assets/buy1.jpg"
              alt
            />
            <p class="price">
              <span style="font-size: 14px">מחיר ליחידה ₪{{ priceReal }}</span>
              <br />
              ₪{{ numReal * priceReal }}
            </p>
            <NumToggle
              :max="selectedSpot ? selectedSpot.stockReal : 0"
              v-model="numReal"
              :min="0"
            />
            <!-- <router-link
              class="nextyear"
              to="/next-year"
            >הזמינו לשנה הבאה</router-link> -->
          </div>
          <div
            class="flower-type"
            :class="{
              outof: selectedSpot ? !selectedSpot.stockPlastic : false,
            }"
          >
            <p class="flower-type-head">
              פלסטיק
              <br />דמוי פרח דם המכבים
            </p>
            <img
              src="../assets/buy2.jpg"
              alt
            />
            <p class="price">
              <span style="font-size: 14px">מחיר ליחידה ₪{{ pricePlastic }}</span>
              <br />
              ₪{{ numPlastic * 10 }}
            </p>
            <NumToggle
              :max="selectedSpot ? selectedSpot.stockPlastic : 0"
              v-model="numPlastic"
              :min="0"
            />
            <!-- <router-link
              class="next-year"
              to="/next-year"
            >הזמינו לשנה הבאה</router-link> -->
          </div>
        </div>
        <label
          class="order-input bgl-checkbox-label"
          v-if="!disableOrgChange"
        >
          <input
            class="bgl-checkbox"
            @change="selectedNonprofit = ''"
            type="checkbox"
            v-model="order.distributeEqually"
          />
          <span class="checkbox">✔</span>
          חלוקה שווה בין העמותות
        </label>
        <br />
        <VueSelect
          :options="nonprofits"
          label="name"
          :reduce="(nonprofit) => nonprofit._id"
          placeholder="בחירת עמותה"
          class="order-select-wrap"
          dir="rtl"
          v-model="selectedNonprofit"
          :disabled="order.distributeEqually || disableOrgChange"
        >
          <template v-slot:option="option">
            <div>
              <img
                class="logo"
                :src="option.logo.imageURL"
              />
              {{ option.name }}
            </div>
          </template>
          <template #no-options>אין תוצאות</template>
        </VueSelect>
        <br />
        <input
          v-model="order.openAmount"
          class="order-input"
          placeholder="תרומה חופשית"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          min="0"
        />
        <div class="inputgroup">
          <input
            v-model="order.firstName"
            class="order-input"
            placeholder="שם פרטי*"
            type="text"
            required
          />
          <input
            v-model="order.lastName"
            class="order-input"
            placeholder="שם משפחה*"
            type="text"
            required
          />
        </div>
        <div class="inputgroup">
          <input
            v-model="order.email"
            class="order-input"
            placeholder="דוא״ל*"
            type="email"
            required
            pattern="^[\w.-]+@[\w]+[\w-]*(?:\.[\w-]+)*(?:\.[\w-]{2,})+$"
          />
          <input
            dir="ltr"
            style="text-align: right"
            v-model="order.phone"
            class="order-input"
            placeholder="* טלפון"
            type="tel"
            required
            inputmode="numeric"
            pattern="\+?(?:0|972)5\d{1}-?\d{3}-?\d{4}"
          />
        </div>
        <label>
          הקדשת הסיכות לזכר נופל
          <textarea
            v-model="order.dedication"
            placeholder="שם הנופל"
            style="margin-top: 6px"
            name
            class="order-input"
          ></textarea>
        </label>

        <div
          v-if="order.delivery"
          class="order-option"
        >
          <h3 class="order-small-head">פרטי משלוח</h3>
          <button
            @click.prevent="order.delivery = false"
            class="order-small-btn"
          >
            לחצו כאן לחזרה לנקודות איסוף
          </button>
          <input
            v-model="order.city"
            class="order-input"
            placeholder="עיר*"
            type="text"
            required
          />
          <input
            v-model="order.street"
            class="order-input"
            placeholder="רחוב*"
            type="text"
            required
          />
          <input
            v-model="order.aptNumber"
            class="order-input"
            placeholder="מספר דירה*"
            type="text"
            required
          />
          <input
            v-model="order.zipCode"
            class="order-input"
            placeholder="מיקוד"
            type="number"
            required
          />
        </div>

        <div
          class="coupon-wrap"
          v-if="couponStatus != 'success'"
        >
          <input
            @keydown.enter.prevent="checkCoupon"
            v-model="couponCode"
            @blur="checkCoupon"
            class="order-input"
            placeholder="קוד קופון "
            type="text"
          />
          <bgl-button
            type="button"
            :loading="couponStatus == 'checking'"
            @click.prevent="checkCoupon"
          >אישור</bgl-button>
        </div>

        <div
          v-else
          class="coupon-wrap"
          style="text-align: center"
        >
          {{ couponMessage }}
          <br />

          <p>
            <b
              style="color: #af1836"
              v-if="couponNumReal && numReal"
            >
              זוכתם עבור {{ Math.min(numReal, couponNumReal) }} סיכות פרח דם
              המכבים
            </b>
          </p>
          <p>
            <b
              style="color: #af1836"
              v-if="couponNumPlastic && numPlastic"
            >
              זוכתם עבור {{ Math.min(numPlastic, couponNumPlastic) }} סיכות פרח
              דם המכבים
            </b>
          </p>
        </div>
        <p v-if="couponStatus == 'fail'">{{ couponMessage }}</p>
        <p class="order-total">סה״כ: ₪{{ totalPrice.toLocaleString() }}</p>
        <label
          v-if="totalPrice != 0"
          class="order-input bgl-checkbox-label tos"
        >
          <input
            class="bgl-checkbox"
            required
            type="checkbox"
          />
          <span class="checkbox">✔</span>
          אני מסכים
          <a
            target="_blank"
            href="/terms"
          >לתנאי השימוש</a>
        </label>
        <p v-if="!numPlastic && !numReal">יש לבחור לפחות סיכה אחת</p>
        <div
          class="order-btns"
          v-if="totalPrice != 0"
        >
          <bgl-button
            :loading="formStatus === 'sending' && donateMethod === 'donateCardcom'
              "
            @click="donateMethod = 'donateCardcom'"
            type="submit"
            class="btn"
          >לתשלום באשראי</bgl-button>
          <bgl-button
            type="submit"
            :loading="formStatus === 'sending' && donateMethod === 'donateBit'"
            @click="donateMethod = 'donateBit'"
            class="btn bit"
          >
            לתשלום בביט
            <img
              src="../assets/bit.png"
              alt
            />
          </bgl-button>
        </div>
        <div v-else>
          <bgl-button
            :loading="formStatus === 'sending'"
            type="submit"
            style="display: block; width: 100%"
            class="btn"
            @click="donateMethod = 'free'"
          >אישור הזמנה</bgl-button>
        </div>
      </form>
      <div
        class="big-white-box move-top"
        v-if="orderStatus == 'success'"
      >
        תודה רבה
      </div>
      <!-- <banner-link
        :msg="'אזל המלאי? שריינו סיכות לשנה הבאה!'"
        :to="'/next-year'"
      /> -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import NumToggle from "@/components/NumToggle.vue";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import BglButton from "../components/bgl-button.vue";
// import BannerLink from "../components/banner.vue";
export default {
  components: {
    NumToggle,
    VueSelect,
    BglButton,
    Multiselect,
    // BannerLink,
  },
  async beforeMount() {
    // this.$router.push("next-year");
    this.$ga({
      Category: "pageview",
      Action: "addtocart",
      event: "auto_event",
    });

    const { org, preview, coupon, dist } = this.$route.query;
    let call = this.$db.collection("distribution").everything();
    call = dist ? call.query("_id", "=", dist) : call;
    let { data: distribution } = await call.get();

    this.distribution = distribution.map((d) => {
      d.spots = d.spots.sort((a, b) => a.name - b.name).filter((s) => !s.hide);
      d.spots = d.spots.map((d) => {
        d.stockPlastic = Math.max(0, (d.maxPlastic || 0) - (d.numPlastic || 0));
        d.stockReal = Math.max(0, (d.maxReal || 0) - (d.numReal || 0));
        return d;
      });
      return d;
    });

    let { data: nonprofits } = await this.$db
      .collection("nonprofits")
      .sort("order", "asc")
      .get();
    this.nonprofits = nonprofits;

    if (process.env.NODE_ENV == "development") this.finished = false;

    this.selectedNonprofit = org || "";
    this.disableOrgChange = !!org;
    if (preview) this.finished = false;
    if (coupon) {
      this.couponCode = coupon;
      this.checkCoupon();
    }
    this.observeSuccess();
  },
  computed: {
    distributionStock() {
      const { numReal, numPlastic } = this;
      return this.distribution.map((d) => {
        d.spots.map((spot) => {
          spot.$isDisabled =
            (!!numReal && spot.maxReal < spot.numReal + numReal) ||
            (!!numPlastic && spot.maxPlastic < spot.numPlastic + numPlastic);
          return spot;
        });
        return d;
      });
    },
    totalPrice() {
      let total = 0;
      let { numReal, numPlastic } = this;
      if (this.couponStatus == "success") {
        numReal = Math.max(0, numReal - this.couponNumReal);
        numPlastic = Math.max(0, numPlastic - this.couponNumPlastic);
      }
      total += numPlastic * this.pricePlastic;
      total += numReal * this.priceReal;
      total += parseInt(this.order.openAmount || "0");
      if (this.order.delivery) total += 50;
      return total;
    },
  },
  watch: {
    numReal() {
      //   if (!this.order.distribution[0].spots[0].itemRefID) return;
      //   let spot = this.locationSpots.find(
      //     l => l._id == this.order.distribution[0].spots[0].itemRefID
      //   );
      //   if (spot.maxReal < spot.numReal + this.numReal)
      //     this.order.distribution[0].spots[0].itemRefID = "";
    },
    numPlastic() {
      //   if (!this.order.distribution[0].spots[0].itemRefID) return;
      //   let spot = this.locationSpots.find(
      //     l => l._id == this.order.distribution[0].spots[0].itemRefID
      //   );
      //   if (spot.maxPlastic < spot.numPlastic + this.numPlastic)
      //     this.order.distribution[0].spots[0].itemRefID = "";
    },
    selectedNonprofit(nonprofit) {
      if (!nonprofit) this.order.nonprofit = [];
      else this.order.nonprofit = [{ itemRefID: nonprofit }];
    },
  },
  methods: {
    selectSpot(e) {
      let dist = this.distribution.find((d) =>
        d.spots.find((s) => s._id === e._id)
      );
      this.order.distribution = [
        { itemRefID: dist._id, spots: [{ itemRefID: e._id }] },
      ];
    },
    locationLabel({ name, address }) {
      return `${name} - ${address}`;
    },

    // groupArray(arr) {
    //   let group = arr.reduce((r, a) => {
    //     r[a.name] = [...(r[a.name] || []), a];
    //     return r;
    //   }, {});
    //   return group;
    // },
    async submitOrder() {
      if (!this.numPlastic && !this.numReal) return;
      if (this.formStatus === "sending") return;
      this.formStatus = "sending";
      try {
        const order = Object.assign({}, this.order);
        order.totalPrice = this.totalPrice;
        if (!order.distribution[0].itemRefID) delete order.distribution;
        if (
          !this.totalPrice ||
          order.distributeEqually ||
          (order.nonprofit && !order.nonprofit[0].itemRefID)
        ) {
          order.distributeEqually = true;
          delete order.nonprofit;
        }
        if (this.numReal) order.numReal = this.numReal;
        if (this.numPlastic) order.numPlastic = this.numPlastic;
        if (!this.totalPrice) delete order.nonprofit;
        if (
          this.couponStatus === "success" &&
          this.order.delivery &&
          this.totalPrice === 50
        )
          delete order.nonprofit;
        if (order.delivery) delete order.distribution;
        let { data } = await axios.post(
          `/.netlify/functions/${this.donateMethod}`,
          order
        );
        this.paymentURL = data.url;
        this.formStatus = "sent";
        window.scroll(0, 0);
        this.$ga({
          Category: "checkout",
          Action: "SEND",
          event: "auto_event",
          value: order.totalPrice,
        });
        // this.orderStatus = "success";
      } catch (err) {
        this.formStatus = "failed";
        console.log(err);
      }
    },
    observeSuccess() {
      window.addEventListener(
        "message",
        (message) => {
          let { data } = message;
          if (data === "success") {
            let optns = {};
            optns.Category = "purchase";
            optns.Action = "buy";
            optns.event = "auto_event";
            optns.value = this.totalPrice;
            this.$ga(optns);
          }
        },
        false
      );
    },
    async checkCoupon() {
      if (!this.couponCode) {
        this.couponMessage = "";
        return;
      }
      this.couponStatus = "checking";
      const { data } = await axios.post("/api/checkCoupon", {
        couponCode: this.couponCode,
      });
      this.couponMessage = data.message;
      if (!data.isValid) {
        this.couponStatus = "fail";
        return;
      }
      this.couponStatus = "success";
      const coupon = { itemRefID: data.couponID };
      this.order[data.type].push(coupon);
      this.couponNumReal = data.numReal;
      this.couponNumPlastic = data.numPlastic;
      console.log(data);
    },
  },
  data() {
    return {
      selectedSpot: null,
      couponNumReal: 0,
      couponNumPlastic: 0,
      paymentMethod: "",
      paymentURL: "",
      orderStatus: "",
      order: {
        delivery: false,
        distribution: [{ itemRefID: "", spots: [{ itemRefID: "" }] }],
        email: "",
        phone: "",
        city: "",
        street: "",
        dedication: "",
        distributeEqually: false,
        family: [],
        coupon: [],
        nonprofit: [
          {
            itemRefID: "",
          },
        ],
      },
      distribution: [],
      nonprofits: [],
      finished: true,
      numPlastic: 0,
      pricePlastic: 10,
      priceReal: 18,
      numReal: 0,
      couponCode: "",
      couponStatus: "",
      couponMessage: "",
      disableOrgChange: false,
      selectedNonprofit: "",
      formStatus: "",
    };
  },
};
</script>

<style>
.inputgroup {
  display: flex;
  justify-content: space-between;
}

.inputgroup input {
  width: 49%;
}

@media screen and (max-width: 600px) {
  .inputgroup {
    display: block;
  }

  .inputgroup input {
    width: 100%;
  }
}

.logo {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 6px;
  background: white;
}

input.order-input:not(:placeholder-shown):not(:valid):not(:focus) {
  border: 1px solid salmon;
  background: pink;
}

.iframe-payment {
  border: none;
  height: 600px;
  width: 100%;
  max-width: 720px;
  margin: auto;
  display: block;
}
</style>
<style src="../assets/css/vue-multiselect.css"></style>
