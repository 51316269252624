export default {
  orgName: 'דם המכבים',
  switchLanguage: 'English',
  explainerVideo: 'סרטון הסבר',
  getYourPin: 'הזמנת סיכה',
  Project: 'עמותת',
  Sub: 'פרויקט ההתרמה לעמותות ומיזמים שמנציחים את הנופלים ומסייעים למשפחותיהם.',
  silverPlatter1: 'אנחנו מגש הכסף שעליו לך',
  silverPlatter2: 'ניתנה מדינת היהודים',
  link1: 'זיכרון מאחד. זיכרון מצמיח.',
  smallLink1: 'סיפור הפרויקט',
  link2: 'רקמה אנושית אחת חיה',
  smallLink2: 'תרמו לצמיחת המיזם',
  link3: 'מהשדה בכרמל לדש החולצה',
  smallLink3: 'מבט לתהליך',
  link4: 'משפחות שכולות - הסיכות למענכם',
  smallLink4: 'לחצו לפרטים',
  link5: 'כאיש אחד בלב אחד',
  smallLink5: 'נקודות המכירה',
  link6: 'הד בלבבות אחרים',
  smallLink6: 'אודותנו בתקשורת',
  pop: 'תרומה לטובת סיכות 2024',
  About: 'אודות',
  Story: 'סיפור',
  Donations: 'תרומות',
  Gallery: 'גלריה',
  Contact: 'יצירת קשר',
  cell: 'נייד',
  Email: 'אימייל',
  Submit: 'שליחה',
  Subject: 'בחרו נושא יצרת קשר',
  volunteering: 'התנדבות בעמותה',
  largeOrder: 'בקשת הצעת מחיר להזמנה גדולה',
  Points: 'רשימת נקודות המכירה',
  TermsOfUse:'תנאי השימוש',
  Accessibility:'נגישות',
  harvest: 'קטיף',
  CollectingPoint: 'נקודת איסוף',
  general: 'כללי',
  Name: 'שם',
  Phone: 'טלפון',
  mail: 'דוא״ל',
  message: 'הודעה',
  error: 'חלה שגיאה במהלך השליחה',
  tnx: 'תודה על שליחת ההודעה',
  DonationsHead: 'תרמו לצמיחה שלנו',
  DonationsTxt:
    'תודה. תודה שהמיזם הזה נוגע לליבכם כפי שהוא נוגע בליבנו. התרומה שלכם תאפשר לנו להמשיך לזכור ולצמוח בשנים הבאות. ביחד.',
  nonprofit: 'עמותות שותפות',
  thankYou: 'תודה שבאתם!',
  JustAround: 'עוד מעט, עוד קצת..',
  start: 'הזמנת הסיכות לשבוע הזיכרון טרם החלה. עקבו אחרינו בפייסבוק ותוכלו לדעת ברגע שההזמנות מתחילות.',
  Sincerely: 'בברכה והערכה',
  news: 'אודותנו בתקשורת',
  selectdonate: 'תרומות',
  selectpartner: 'עמותה שותפה',
  tnxFamily: 'משפחות שכולות יקרות',
  familyHead: 'משפחות שכולות',
  bigtxtFamily: 'את הפרוייקט הזה הקמנו למענכם ולמען יקיריכם.',
  txtFamily: 'לקבלת הסיכות, מלאו את הפרטים מטה ועקבו אחר הוראות המייל שתקבלו מיד לאחר מכן.',
  costFamily: 'ללא עלות',
  txtFamily2: 'בהערכה ובאהבה,',
  txtFamily3: 'עמותת דם המכבים',
  DonationsSmallTxt: 'שימו לב: אתם נמצאים בעמוד התרומות לעמותת דם המכבים. לרכישת סיכות ליום ליום הזיכרון,',
  DonationsPress: 'לחצו כאן.',
  nonprofitsub1: 'מיזם דם המכבים הוא מגבית לאומית של יום הזיכרון.',
  nonprofitsub2:
    'כל הכנסות הסיכות מגיעות לעמותות ומיזמים שתומכים במשפחות שכולות או מנציחים את הנופלים בצורה של צמיחה ואחדות.',
  nonprofitsub3: 'להלן רשימת העמותות השותפות שלנו לשנת 2023, מוזמנים לעיין ולקרוא אודות כל אחת מהן.',
  emailWillBeSent: 'תודה! מייל נשלח אליכם. אנא מלאו אחר ההוראות שבמייל כדי להזמין את הסיכות.',
  fallenName: 'שם הנופל',
  donate: 'תרומה',
  homeTitle: 'זיכרון, צמיחה ותקומה.',
  homeSubTitle: 'השתתפו בזיכרון הנופלים ותקומת הארץ.',
  homeOpen1:
    'בשנה בה אדמת הארץ נספגה בדם גיבורים וגיבורות של ה7 לאוקטובר ומלחמת חרבות ברזל, אנו זוכרים את צוואתם: צמיחה ותקומה.',
  homeOpen2: 'בימים אלו, עובדי העמותה מגוייסים כולם למאמץ המלחמה ואנו נאלצים למקד את פעילותנו.',
  homeOpen3: 'עד לפתיחת האתר להזמנות, נשמח לתרומתכם לחלוקת הפרחים למשפחות השכולות.',
  homeOpen4:
    'בנוסף לרכישת הסיכות, ניתן לתרום לפעילות העמותה להנצחת חללי צה"ל ופעולות האיבה ולתמיכה במשפחות השכול על ידי מיזמי תקומה וצמיחה',
  donPin: 'נשמח לתרומתכם להענק הסיכות למשפחות השכולות',

  newHomeOpen1: 'בשנה בה אדמת הארץ נספגה בדם גיבורים, אנו זוכרים את צוואתם: חיים ותקומה.',
  newHomeOpen2:
    'ביום הזיכרון הקרוב נענוד כולנו את סיכת פרח דם המכבים המסמלת ומזכירה את הצמיחה למרות ומתוך השכול והשבר.',
  newHomeOpen3:
    'מכירת סיכות דם המכבים מהווה מגבית לאומית לתמיכה במשפחות שכולות ובהנצחתם של חללי צה"ל ונפגעי פעולות האיבה.',
  newHomeOpen4:
    'השנה ניתן יהיה לקבל את הסיכות שלנו באחת מהעמדות שיפתחו בקניונים ובמקומות מרכזיים ברחבי הארץ בימים שלפני יום הזיכרון. רשימת העמדות המלאה תפורסם בהקדם.',
  newHomeOpen5: 'להזמנת סיכות בכמויות גדולות לקבוצות, מוסדות וארגונים ניתן ליצור קשר עם מיקי:',
  newHomeOpen6: '*בני משפחות שכולות מוזמנים לאסוף סיכות ללא תשלום מהעמדות הפתוחות',
  organization:'הצעת מחיר עבור ארגון',
  educationalInstitution:'הצעת מחיר עבור מוסד חינוכי',
  wholeFamily:'משפחה שכולה',
};
