<template>
  <div class="amont-wrap">
    <a @click="toggleDown()" class="plus-min-btn">–</a>
    <input
      placeholder="0"
      type="text"
      class="amount"
      v-model="numValue"
      pattern="[0-9]*"
      inputmode="numeric"
    />
    <a @click="toggleUp()" class="plus-min-btn">+</a>
  </div>
</template>

<script>
export default {
  name: "numToggle",
  data() {
    return {
      numValue: 1,
    };
  },
  props: {
    value: [Number, String],
    required: Boolean,
    min: { type: Number, default: 1 },
    max: Number,
  },
  mounted() {
    this.numValue = parseInt(this.value);
  },
  methods: {
    toggleUp() {
      console.log(this.max)
      if (this.max && this.numValue >= this.max) return;
        this.numValue++;
        this.$emit("input", this.numValue || 0);
      
    },
    toggleDown() {
      if (this.numValue > this.min || 0) {
        this.numValue--;
        this.$emit("input", this.numValue || 0);
      }
    },
  },
  watch: {
    max(max) {
      if (this.numValue > max || 0) this.numValue = max;
    },
    value() {
      if (this.numValue != this.value) this.numValue = parseInt(this.value);
    },
    numValue(val) {
      if (val === parseInt((`${val}`?.match(/\d+/g) || []).join(""))) return;

      let v = parseInt((`${val}`?.match(/\d+/g) || []).join("") || 0);
      if (v < this.min) v = this.min;
      this.numValue = v;
      this.$emit("input", v || 0);
    },
  },
};
</script>

<style scoped>
.amont-wrap {
  background: #f7f2f2;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
}
.amount {
  background: transparent;
  border: none;
  color: #1d3779;
  text-align: center;
  font-size: 20px;
  width: 60px;
  position: relative;
}
.amount:focus-visible {
  outline: none;
}
.plus-min-btn:first-child {
  line-height: 1.2;
}
.plus-min-btn {
  height: 50px;
  width: 60px;
  border-radius: 10px;
  background: #1d3779;
  color: white;
  font-size: 36px;
  font-family: "Assistant", sans-serif;
  line-height: 1.3;
  text-align: center;
  user-select: none;
  cursor: pointer;
  transition: all 200ms ease;
}
.plus-min-btn:hover {
  filter: brightness(110%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.178);
}

.plus-min-btn:active {
  filter: brightness(80%);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.178);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
