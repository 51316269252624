<template>
  <div dir="ltr">
    <b-field :label="field.name">
      <b-switch @input="emitValue" type="is-success" v-model="val"></b-switch>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "Bool",
  props: { field: Object, value: Boolean },
  data() {
    return {
      val: this.value,
    };
  },
  watch: {
    value(value) {
      if (value !== this.val) this.val = value;
    },
  },
  methods: {
    emitValue() {
      this.$emit("input", this.val);
    },
  },
};
</script>
