<template>
  <button
    :type="type || 'button'"
    @click="emitClick"
    :disabled="disabled || loading"
    :class="color"
  >
    <div v-if="loading" class="loader-2" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: "bgl-button",
  props: {
    type: String,
    loading: Boolean,
    status: String,
    color: String,
    disabled: Boolean,
  },
  methods: {
    emitClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped>
.loader-2 {
  margin-top: 7px;
  border-radius: 100px;
  display: inline-block;
  height: 18px;
  width: 18px;
  border: 1px solid #ffffff5e;
  border-right: 1px solid white;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* button {
  border-radius: 5px;
  border: 0;
  padding: 6px 9px;
  color: white;
  line-height: 1;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 13px;
  width: 100%;
  font-family: "ploni", Helvetica, Arial, "bagelfont", sans-serif;
  margin: auto;
  text-align: center;
  height: 34px;
  line-height: 33px;
  padding: 0;
} */

button.green {
  background: #2da44e;
}

button.dark {
  background: var(--bgl-dark);
}

button:disabled {
  opacity: 0.7;
}
</style>
