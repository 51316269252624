<template>
  <div class="dashboard-card side-info">
    <div class="dashboard-col">
      <p class="card-title">{{ title }}</p>
      <p class="card-num">{{suffix}}{{ total.toLocaleString() }}</p>
      <div class="piechart">
        <div
          v-for="(item, i) in value"
          :key="i"
          class="pie animate"
          :style="{
            '--p': cumulative(value.length - i - 1),
            '--c':
              activeItem === item.title
                ? colors[value.length - i - 1]
                : colors[value.length - i - 1],
          }"
        ></div>
      </div>
    </div>
    <div class="dashboard-col">
      <div
        class="dashboard-card-info-item"
        v-for="(item, i) in value"
        :key="'label' + i"
      >
        <div class="dashboard-card-info-top">
          <div class="dashboard-info-dot"></div>
          <p class="dashboard-info-num">
            {{ suffix }}{{ item.value.toLocaleString() }}

            <span class="dashboard-pill">
              {{ Math.round(relative(item.value)) }}%
            </span>
          </p>
        </div>
        <p class="card-gray-txt">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PieChart",
  props: {
    title: String,
    total: Number,
    value: Array,
    suffix: String,
  },

  data() {
    return {
      activeItem: -1,
      colors: [
        "#20377b",
        "#fb5858",
        "#8aa0e0",
        "#f89898",
        "#ddb3b3",
        "#6c84cd",
      ],
    };
  },
  methods: {
    cumulative(count) {
      let sum = 0;
      for (let i = 0; i <= count; i++) {
        sum += this.relative(this.value[i].value);
      }
      return sum;
    },
    relative(val) {
      return (val / this.totalValues) * 100;
    },
  },
  computed: {
    totalValues() {
      return this.value.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      );
    },
  },
};
</script>
