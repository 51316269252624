
      <template >
  <div class="main">
    <div class="top-bg about"></div>
    <div v-if="isheb">
      <div class="about-wrap">
        <div class="about-img-wrap">
          <h1 class="top-head">{{ pages.title }}</h1>
          <img src="../assets/about-back.jpg" alt="about" />
        </div>
        <div class="about-txt">
          <div class="txt20" v-html="pages.text"></div>
        </div>
        <div class="end-pics">
          <img src="../assets/flowers1.jpg" alt="דם המכבים" />
          <img src="../assets/flowers2.jpg" alt="דם המכבים" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="about-wrap">
        <div class="about-img-wrap">
          <h1 class="top-head">{{ pages.titleEnglish }}</h1>
          <img src="../assets/about-back.jpg" alt="about" />
        </div>
        <div class="about-txt">
          <div v-html="pages.textEng" class="txt20"></div>
        </div>
        <div class="end-pics">
          <img src="../assets/flowers1.jpg" alt="דם המכבים" />
          <img src="../assets/flowers2.jpg" alt="דם המכבים" />
        </div>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "@/components/contact.vue";
export default {
  components: {
    Contact,
  },
  data: () => ({
    pages: "",
  }),
  computed: {
    isheb() {
      return this.$lang.locale === "he";
    },
  },
  async beforeMount() {
    let { data: pages } = await this.$db
      .collection("pages")
      .item("622f358cc1c4cd6838f18039")
      .get();
    this.pages = pages;
  },
};
</script>