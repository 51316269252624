<template>
  <div class="main">
    <div class="popup-bg" :class="{ openbigpop: openbigpop }">
      <div class="big-popup">
        <button
          @click="openbigpop = false"
          class="big-popup-close"
        ><img
            src="../assets/black-close.svg"
            alt="close"
          ></button>
        <img
          class="big-popup-img"
          src="../assets/helichrysum-sanguineum.jpg"
        >
        <div class="big-popup-txt">
          <p class="big-popup-title">
            עם ישראל, ריגשתם 💙
          </p>
          <p>
            מדי שנה אנו מכינים כ50,000 סיכות שמופצות לכל הקודם להזמין.
          </p>
          <p>
            השנה הוצפנו בהזמנות, ומלאי מתחיל להיגמר בנקודות שונות בארץ. מוזמנים לסגור חלון זה, לחפש נקודה עם מלאי שקרובה לביתכם, ולאסוף מיד.
          </p>
          <p>
            לא מצאתם נקודה עם מלאי?

          </p>
          <button
            class="big-popup-btn"
            @click="order.nextyearOrder = true; openbigpop = false"
          >

            הזמינו עכשיו סיכות ל2024.
          </button>

          <p> כך לא תפספסו את שנה הבאה,
            וגם תעזרו לנו לצמוח.
          </p>
          <button
            @click="openbigpop = false"
            class="big-popup-btn"
          >
            או המשיכו להזמנת סיכות השנה 2023
          </button>
        </div>
      </div>
    </div>
    <div
      class="side-pop"
      :class="{ sidepopopen: sidepopopen && openbigpop == false && !order.nextyearOrder }"
    >
      <button
        @click="sidepopopen = false"
        class="close-side-pop"
      ><img
          src="../assets/black-close.svg"
          alt="close"
        ></button>
      <button
        class="big-popup-btn smaller"
        style="padding-right:28px"
        @click="order.nextyearOrder = true; openbigpop = false"
      >
        להזמנת סיכות לשנה הבאה
      </button>
    </div>
    <iframe
      class="iframe-payment"
      v-if="paymentURL"
      :src="paymentURL"
    ></iframe>
    <div
      v-else
      class="big-white-box move-top"
    >
      <h1 class="top-head">הזמנת סיכות דם המכבים</h1>
      <!-- <h2 style="color: #af1836" class="sub-head">לשנה הבאה</h2> -->
      <p
        class="nonprofitsub"
        v-if="($route.query.coupon || '').length === 7"
      >
        להזמנת משפחות שכולות יש לבחור מספר סיכות אמיתיות ולבסוף השלמת הזמנה
      </p>
      <div v-if="order.nextyearOrder">
        <br>
        <h3
          style="font-weight: 800; color:#af1836"
          class="order-small-head"
        >
          הזמנה לשנה הבאה
          ({{ new Date().getFullYear() + 1 }})
        </h3>
        <br>

      </div>
      <div v-else>
        <h3    style="font-weight: 800; color:#af1836"
          class="order-small-head">
          הזמנות מהרגע להרגע (2023)

        </h3>

       <p class="nonprofitsub" style="color:#af1836">
        רגע לפני יום הזיכרון, נותרו מספר נקודות עם מלאי של סיכות פרח אמיתי או פרח דמוי. מוזמנים לנסות לאתר נקודה עם מלאי, להזמין, ולאסוף מהנקודה במיידי. המלאי מתעדכן בזמן אמת בכל נקודה, בהתאם לכמות וסוג הסיכה.
<br><br>
אם פספסתם את ההזדמנות, אל תפספסו גם את שנה הבאה.
<span class="nonprofitsub" @click="order.nextyearOrder = true" style="text-decoration: underline; cursor: pointer;">
  בצעו הזמנה מוקדמת ותשריינו סיכות ל2024.
</span>
</p>
</div>
        
      <!-- <p v-else class="nonprofitsub">
        משפחות שכולות - אנא לחצו
        <router-link class="small-link" to="/families">כאן</router-link> לקבלת
        הסיכות ללא עלות.
      </p> -->
      <form
        class="order-form"
        @submit.prevent="submitOrder"
        v-if="orderStatus != 'success'"
      >
        <div class="flower-types-wrap">
          <div class="flower-type">
            <p class="flower-type-head">
              פרח
              <br />דם המכבים
            </p>
            <img
              src="../assets/buy1.jpg"
              alt
            />
            <p class="price">
              <span style="font-size: 14px">מחיר ליחידה ₪{{ priceReal }}</span>
              <br />
              ₪{{ numReal * priceReal }}
            </p>
            <NumToggle
              v-model="numReal"
              :min="0"
            />
          </div>
          <div class="flower-type">
            <p class="flower-type-head">
              פלסטיק
              <br />דמוי פרח דם המכבים
            </p>
            <img
              src="../assets/buy2.jpg"
              alt
            />
            <p class="price">
              <span style="font-size: 14px">מחיר ליחידה ₪{{ pricePlastic }}</span>
              <br />
              ₪{{ numPlastic * 10 }}
            </p>
            <NumToggle
              v-model="numPlastic"
              :min="0"
            />
          </div>
        </div>
        <label
          class="order-input bgl-checkbox-label"
          v-if="!disableOrgChange"
        >
          <input
            class="bgl-checkbox"
            @change="selectedNonprofit = ''"
            type="checkbox"
            v-model="order.distributeEqually"
          />
          <span class="checkbox">✔</span>
          חלוקה שווה בין העמותות
        </label>
        <br />
        <VueSelect
          :options="nonprofits"
          label="name"
          :reduce="(nonprofit) => nonprofit._id"
          placeholder="בחירת עמותה"
          class="order-select-wrap"
          dir="rtl"
          v-model="selectedNonprofit"
          :disabled="order.distributeEqually || disableOrgChange"
        >
          <template v-slot:option="option">
            <div>
              <img
                class="logo"
                :src="option.logo.imageURL"
              />
              {{ option.name }}
            </div>
          </template>
          <template #no-options>אין תוצאות</template>
        </VueSelect>
        <br />
        <label>
          מוזמנים להוסיף סכום נוסף לתרומה
          <input
            v-model="order.openAmount"
            class="order-input"
            placeholder="סכום התרומה"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            min="0"
          />
        </label>
        <div class="inputgroup">
          <input
            v-model="order.firstName"
            class="order-input"
            placeholder="שם פרטי*"
            type="text"
            required
          />
          <input
            v-model="order.lastName"
            class="order-input"
            placeholder="שם משפחה*"
            type="text"
            required
          />
        </div>
        <div class="inputgroup">
          <input
            v-model="order.email"
            class="order-input"
            placeholder="דוא״ל*"
            type="email"
            required
            pattern="^[\w.-]+@[\w]+[\w-]*(?:\.[\w-]+)*(?:\.[\w-]{2,})+$"
          />
          <input
            dir="ltr"
            style="text-align: right"
            v-model="order.phone"
            class="order-input"
            placeholder="* טלפון"
            type="tel"
            required
            inputmode="numeric"
            pattern="\+?(?:0|972)5\d{1}-?\d{3}-?\d{4}"
          />
        </div>
        <label>
          הקדשת הסיכות לזכר נופל
          <textarea
            v-model="order.dedication"
            placeholder="שם הנופל"
            style="margin-top: 6px"
            name
            class="order-input"
          ></textarea>
        </label>
        <div
          class="order-option"
          v-if="!order.delivery && !order.nextyearOrder"
        >
        <div v-if="canChangeLocation">

          <h3 class="order-small-head">אזור נקודת איסוף</h3>
          <a
          href="https://www.google.com/maps/d/u/0/viewer?mid=1WZIQyv5t_9wIp7IBNGIyZmYID91LT4xc"
          target="_blank"
          class="order-small-btn"
          >היעזרו במפה שלנו למציאת נקודת האיסוף הנוחה עבורכם</a>
          <multiselect

            v-model="selectedSpot"
            :options="locations"
            :multiple="false"
            group-values="spots"
            group-label="region"
            openDirection="bottom"
            :showLabels="false"
            :group-select="false"
            placeholder="הקלד/י שם עיר או יישוב"
            track-by="address"
            :customLabel="locationLabel"
            selectLabel
            deselectLabel
            @select="selectSpot"
          >
            <template #placeholder><input
                placeholder="הקלד/י שם עיר או יישוב"
                style="width: 100%; background: transparent; border: none"
                type="text"
                required
              /></template>
            <template #singleLabel="props"><input
                style="width: 100%; background: transparent; border: none"
                type="text"
                required
                :value="`${props.option.name} - ${props.option.address}`"
              /></template>

            <template #option="props">
              <div class="option__desc">
                {{ props.option.$groupLabel }}
                <b class="option__title">{{ props.option.name }}</b>
                <span
                  v-if="props.option.address"
                  class="option__small"
                >- {{ props.option.address }}</span>
                <p
                  style="font-size: smaller; opacity: 0.7"
                  v-if="realSupplyCheck(props.option, numReal)"
                >
                  אין מספיק מלאי של פרח אמיתי בנקודה להזמנה זו
                </p>
                <p
                  style="font-size: smaller; opacity: 0.7"
                  v-if="plasticSupplyCheck(props.option, numPlastic)"
                >
                  אין מספיק מלאי של דמוי פרח בנקודה להזמנה זו
                </p>
              </div>
            </template>
            <span slot="noResult">לא מצאנו את האיזור המבוקש</span>
          </multiselect>
        </div>
          <div v-if="selectedSpot">
            <div
              class="next-year-order"
              v-if="!distributionStatus.canSubmit"
            >
              <p>{{ distributionStatus.error }}</p>
              <bgl-button
                class="btn"
                :loading="false"
                :disabled="false"
                type="button"
                @click="order.nextyearOrder = true"
              >
                לחצו כאן להזמין סיכות לשנה הבאה ({{ new Date().getFullYear() + 1 }}). כך גם תעזרו לנו לצמוח, וגם תוודאו שלא תפספסו את שנה הבאה.
              </bgl-button>
            </div>
            <div v-else>

              <h3>{{ selectedSpot.name }}</h3>
              <p>
                <b>כתובת:</b>
                {{ selectedSpot.address }}
              </p>
              <p>
                <b>איש.ת קשר:</b>
                {{ selectedSpot.contactPerson }}
              </p>
              <p>
                <b>טלפון:</b>
                {{ selectedSpot.phone }}
              </p>
            </div>
          </div>
          <!-- <button
            type="button"
            class="order-small-btn"
            @click.prevent="order.delivery = true"
          >
            לחצו כאן לביצוע משלוח בתוספת 50 ₪
          </button> -->
        </div>

        <div
          v-else-if="order.delivery"
          class="order-option"
        >
          <h3 class="order-small-head">פרטי משלוח</h3>
          <button
            @click.prevent="order.delivery = false"
            class="order-small-btn"
          >
            לחצו כאן לחזרה לנקודות איסוף
          </button>
          <input
            v-model="order.city"
            class="order-input"
            placeholder="עיר*"
            type="text"
            required
          />
          <input
            v-model="order.street"
            class="order-input"
            placeholder="רחוב*"
            type="text"
            required
          />
          <input
            v-model="order.aptNumber"
            class="order-input"
            placeholder="מספר דירה*"
            type="text"
            required
          />
          <input
            v-model="order.zipCode"
            class="order-input"
            placeholder="מיקוד"
            type="number"
            required
          />
        </div>

        <!-- <div
          class="coupon-wrap"
          v-if="couponStatus != 'success' && !order.nextyearOrder"
        >
          <input
            @keydown.enter.prevent="checkCoupon"
            v-model="couponCode"
            @blur="checkCoupon"
            class="order-input"
            placeholder="קוד קופון "
            type="text"
          />
          <bgl-button
            type="button"
            :loading="couponStatus == 'checking'"
            @click.prevent="checkCoupon"
          >אישור</bgl-button>
        </div> -->

        <div
          v-if="couponStatus == 'success'"
          class="coupon-wrap"
          style="text-align: center"
        >
          {{ couponMessage }}
          <br />

          <p>
            <b
              style="color: #af1836"
              v-if="couponNumReal && numReal"
            >
              נוספו {{ Math.min(numReal, couponNumReal) }} סיכות פרח דם
              המכבים
            </b>
          </p>
          <p>
            <b
              style="color: #af1836"
              v-if="couponNumPlastic && numPlastic"
            >
              נוספו {{ Math.min(numPlastic, couponNumPlastic) }} סיכות פרח
              דם המכבים
            </b>
          </p>
          שימו לב - יש ללחוץ על "השלמת הזמנה" לסיום

        </div>
        <p v-if="couponStatus == 'fail'">{{ couponMessage }}</p>
        <p class="order-total">סה״כ: ₪{{ totalPrice.toLocaleString() }}</p>
        <label
          v-if="totalPrice != 0"
          class="order-input bgl-checkbox-label tos"
        >
          <input
            class="bgl-checkbox"
            required
            type="checkbox"
          />
          <span class="checkbox">✔</span>
          אני מסכים
          <a
            target="_blank"
            href="/terms"
          >לתנאי השימוש</a>
        </label>
        <p>{{ distributionStatus.error }}</p>
        <div
          class="order-btns"
          v-if="totalPrice != 0"
        >

          <bgl-button
            :loading="
              formStatus === 'sending' && donateMethod === 'donateCardcom'
            "
            @click="donateMethod = 'donateCardcom'"
            type="submit"
            class="btn"
            :disabled="!distributionStatus.canSubmit"
          >לתשלום באשראי</bgl-button>
          <bgl-button
            type="submit"
            :loading="formStatus === 'sending' && donateMethod === 'donateBit'"
            @click="donateMethod = 'donateBit'"
            class="btn bit"
            :disabled="!distributionStatus.canSubmit"
          >
            לתשלום בביט
            <img
              src="../assets/bit.png"
              alt
            />
          </bgl-button>
        </div>
        <div v-else-if="couponStatus === 'success'">
          <bgl-button
            :disabled="!numPlastic && !numReal || !distributionStatus.canSubmit"
            :loading="formStatus === 'sending'"
            type="submit"
            style="display: block; width: 100%"
            class="btn"
            @click="donateMethod = 'free'"
          >השלמת הזמנה</bgl-button>
        </div>
        <div v-else>
          <bgl-button
            :disabled="!numPlastic && !numReal"
            :loading="formStatus === 'sending'"
            type="submit"
            style="display: block; width: 100%"
            class="btn"
            @click="donateMethod = 'free'"
          >יש לבחור לפחות סיכה אחת</bgl-button>
        </div>
      </form>
      <div
        class="big-white-box move-top"
        v-if="orderStatus == 'success'"
      >
        תודה רבה
      </div>
      <p
        class="center red-txt"
        v-if="!order.nextyearOrder"
      >
        עם קבלת מייל אישור הזמנה במייל, הזמנתכם תקלט במערכת ותוכלו לתאם עם נקודת האיסוף שבחרתם ולאסוף מיד.
      </p>
      <p
        v-else
        style="text-align: center; max-width: 700px; margin:auto"
      >
        בשנה הבאה, אנו ניצור איתכם קשר כחודש לפני יום הזיכרון על מנת לתאם נקודת איסוף לקבלת הסיכות. אם בטעות זה לא קרה - אל תהססו ליצור איתנו קשר.
      </p>
    </div>
    <popup v-model="isPoppedUp">
      <div class="nonprofit-pop">
        <h1 class="top-head">שימו לב</h1>
        בשלב זה כבר לא ניתן לאסוף סיכות לשנה הקרובה. אבל אל תפספסו שריינו סיכות
        לשנה הבאה!
        <br />
        <br />
        <bgl-button
          @click="isPoppedUp = false"
          class="btn"
        >אני מבין</bgl-button>
      </div>
    </popup>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import NumToggle from "@/components/NumToggle.vue";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import BglButton from "../components/bgl-button.vue";
export default {
  components: {
    NumToggle,
    VueSelect,
    BglButton,
    Multiselect,
  },
  async mounted() {
    this.$ga({
      Category: "pageview",
      Action: "addtocart",
      event: "auto_event",
    });

    let { org, preview, coupon, location } = this.$route.query;
    if (!(org && location)) this.$router.push('/donate')
    this.fetchLocations();
    let { data: nonprofits } = await this.$db
      .collection("nonprofits")
      .query("salespage", "=", true)
      .sort("order", "asc")
      .get();
    this.nonprofits = nonprofits;
    
    if (process.env.NODE_ENV == "development") this.finished = false;
if (org){
  this.selectedNonprofit = org || "";
  this.disableOrgChange = !!org;
  if (org === '622f5e70f0c605f747ad51ae'){
    this.selectedNonprofit = '640de48a2f3df2dbb94818a0'
    location = '6441b79ba3c7f90aba6de1e9'
  }
}
    if (preview) this.finished = false;
    if (location) {
      this.order.location = [{itemRefID:location}]
      const {data:selectedSpot} = await this.$db.collection("locations").item(location).get()
      this.selectedSpot = selectedSpot      
      this.canChangeLocation = false;
    }
    if (coupon) {
      this.couponCode = coupon;
      this.checkCoupon();
    }
    this.observeSuccess();
  },
  computed: {
    distributionStatus() {
      const { numReal, numPlastic, selectedSpot, order } = this;
      if (order.delivery && (numReal || numPlastic)) return { canSubmit: true }
      if (order.nextyearOrder) return { canSubmit: true, error: "זוהי הזמנה לשנה הבאה" }
      if (!selectedSpot) return { canSubmit: false }
      if (this.order.delivery) return { canSubmit: true };
      let plasticIssue = this.plasticSupplyCheck(selectedSpot, numPlastic);
      if (plasticIssue) return { canSubmit: false, error: "אין מספיק סיכות פלסטיק בנקודה שנבחרה" };
      let realIssue = this.realSupplyCheck(selectedSpot, numReal);
      if (realIssue) return { canSubmit: false, error: "אין מספיק סיכות פרח אמיתי בנקודה שנבחרה" };
      return { canSubmit: true }
    },
    totalPrice() {
      let total = 0;
      let { numReal, numPlastic } = this;
      if (this.couponStatus == "success") {
        numReal = Math.max(0, numReal - this.couponNumReal);
        numPlastic = Math.max(0, numPlastic - this.couponNumPlastic);
      }
      total += numPlastic * this.pricePlastic;
      total += numReal * this.priceReal;
      total += parseInt(this.order.openAmount || "0");
      if (this.order.delivery) total += 50;
      return total;
    },
  },
  watch: {
    'order.nextyearOrder'(val) {
      if (val) window.scroll({ top: 0, behavior: "smooth" })
    },
    numReal() {
      //   if (!this.order.distribution[0].spots[0].itemRefID) return;
      //   let spot = this.locationSpots.find(
      //     l => l._id == this.order.distribution[0].spots[0].itemRefID
      //   );
      //   if (spot.maxReal < spot.numReal + this.numReal)
      //     this.order.distribution[0].spots[0].itemRefID = "";
    },
    numPlastic() {
      //   if (!this.order.distribution[0].spots[0].itemRefID) return;
      //   let spot = this.locationSpots.find(
      //     l => l._id == this.order.distribution[0].spots[0].itemRefID
      //   );
      //   if (spot.maxPlastic < spot.numPlastic + this.numPlastic)
      //     this.order.distribution[0].spots[0].itemRefID = "";
    },
    selectedNonprofit(nonprofit) {
      if (!nonprofit) this.order.nonprofit = [];
      else this.order.nonprofit = [{ itemRefID: nonprofit }];
    },
  },
  methods: {
    realSupplyCheck(location, addition) {
      return location?.realSupply < (location.sumNumReal + addition)
    },
    plasticSupplyCheck(location, addition) {
      return location?.plasticSupply < (location.sumNumPlastic + addition)
    },
    async fetchLocations() {
      const { data } = await this.$db
        .collection("locations")
        .perPage(99999)
        .query("active", "=", true)
        .get();
      const regions = [
        ...new Set(data.map((l) => l.region?.value || "כל הארץ")),
      ].sort();
      console.log(regions);
      this.locations = regions.map((r) => ({
        region: r,
        spots: data.filter((l) => (l.region?.value || "כל הארץ") === r),
      }));
    },
    selectSpot(e) {
      this.order.location = [{ itemRefID: e._id }];
    },
    locationLabel({ name, address }) {
      return `${name} - ${address}`;
    },
    async submitOrder() {
      if (!this.numPlastic && !this.numReal) return;
      if (this.formStatus === "sending") return;
      this.formStatus = "sending";
      try {
        const order = Object.assign({}, this.order);
        order.totalPrice = this.totalPrice;
        if (!order.location?.[0]?.itemRefID) delete order.location;
        if (
          !this.totalPrice ||
          order.distributeEqually ||
          (order.nonprofit && !order.nonprofit[0].itemRefID)
        ) {
          order.distributeEqually = true;
          delete order.nonprofit;
        }
        if (this.numReal) order.numReal = this.numReal;
        if (this.numPlastic) order.numPlastic = this.numPlastic;
        if (!this.totalPrice) delete order.nonprofit;
        if (
          this.couponStatus === "success" &&
          this.order.delivery &&
          this.totalPrice === 50
        )
          delete order.nonprofit;
        if (order.delivery) delete order.location;
        let { data } = await axios.post(
          `/.netlify/functions/next-${this.donateMethod}`,
          order
        );
        this.paymentURL = data.url;
        this.formStatus = "sent";
        window.scroll(0, 0);
        this.$ga({
          Category: "checkout",
          Action: "SEND",
          event: "auto_event",
          value: order.totalPrice,
        });
        // this.orderStatus = "success";
      } catch (err) {
        this.formStatus = "failed";
        console.log(err);
      }
    },
    observeSuccess() {
      window.addEventListener(
        "message",
        (message) => {
          let { data } = message;
          if (data === "success") {
            let optns = {};
            optns.Category = "purchase";
            optns.Action = "buy";
            optns.currency = "ILS";
            optns.event = "event";
            optns.value = this.totalPrice;
            this.$ga(optns);
          }
        },
        false
      );
    },
    async checkCoupon() {
      if (!this.couponCode) {
        this.couponMessage = "";
        return;
      }
      this.couponStatus = "checking";
      const { data } = await axios.post("/api/checkCoupon", {
        couponCode: this.couponCode,
      });
      this.couponMessage = data.message;
      if (!data.isValid) {
        this.couponStatus = "fail";
        return;
      }
      this.couponStatus = "success";
      const coupon = { itemRefID: data.couponID };
      this.order[data.type].push(coupon);
      this.couponNumReal = data.numReal;
      this.couponNumPlastic = data.numPlastic;
      console.log(data);
    },
  },
  data() {
    return {
      canChangeLocation:true,
      sidepopopen: true,
      openbigpop: false,
      isPoppedUp: false,
      selectedSpot: null,
      couponNumReal: 0,
      couponNumPlastic: 0,
      paymentMethod: "",
      paymentURL: "",
      orderStatus: "",
      order: {
        nextyearOrder: false,
        delivery: false,
        location: [],
        email: "",
        phone: "",
        dedication: "",
        distributeEqually: false,
        family: [],
        coupon: [],
        nonprofit: [],
      },
      locations: [],
      nonprofits: [],
      finished: true,
      numPlastic: 0,
      pricePlastic: 10,
      priceReal: 18,
      numReal: 0,
      couponCode: "",
      couponStatus: "",
      couponMessage: "",
      disableOrgChange: false,
      selectedNonprofit: "",
      formStatus: "",
    };
  },
};
</script>

<style>
.orders-next-year {
  max-width: 500px;
  margin: auto;
  color: white;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  background-image: url('../assets/banner-bg.jpg');
  line-height: 1.7;
  cursor: pointer;
  margin-block: 10px;
  display: inline-block;
}

.next-year-order {
  text-align: center;
  margin-block: 10px;
}

.next-year-order .order-text {
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  color: white;
  background-color: #1d3779;
  line-height: 27px;
  font-weight: 700;
  display: block;
}

.download-docs {
  text-align: center;
}

.download-docs a {
  color: #1d3779;
  margin: 0 10px;
}

.multiselect__content-wrapper {
  overflow-x: hidden;
}

.inputgroup {
  display: flex;
  justify-content: space-between;
}

.inputgroup input {
  width: 49%;
}

@media screen and (max-width: 600px) {
  .inputgroup {
    display: block;
  }

  .inputgroup input {
    width: 100%;
  }
}

.logo {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 6px;
  background: white;
}

input.order-input:not(:placeholder-shown):not(:valid):not(:focus) {
  border: 1px solid salmon;
  background: pink;
}

input {
  font-family: "Assistant";
}

.iframe-payment {
  border: none;
  height: 600px;
  width: 100%;
  max-width: 720px;
  margin: auto;
  display: block;
}
</style>
<style src="../assets/css/vue-multiselect.css"></style>
