<template>
  <div>
    <b-navbar class="bgl-nav">
      <template #brand>
        <b-navbar-item
          tag="h1"
          style="font-size: 21px"
        >{{
          collectionName
        }}</b-navbar-item>

        <b-navbar-item
          tag="span"
          style="opacity: 0.6; font-size: 13px"
        >{{ totalItems.toLocaleString() }} אייטמים</b-navbar-item>
        <b-navbar-item
          v-for="filter in filters"
          :key="filter._id"
        >
          <div
            class="filter-tab"
            :class="{ 'active-tab': activeFilter._id === filter._id }"
            @click="setAtiveFilter(filter)"
          >
            <div
              v-if="filter.default"
              class="default-mark"
            ></div>
            {{ filter.name }}
            <button
              v-if="filter._id"
              class="settings-filter-btn"
              @click.stop="editFilter(filter)"
            >
              <span class="icon-font">settings</span>
            </button>
          </div>
        </b-navbar-item>
        <b-navbar-item>
          <div
            @click="editFilter()"
            class="new-filter"
          >+</div>
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item>
          <BglButton
            class="download-btn"
            @click="downloadData"
            :loading="downloading"
          >
          <!-- :download="`${title}-${activeFilter.name}.xlsx`" -->

            <span class="icon-font">download</span>
            הורדה
        </BglButton></b-navbar-item>
        <b-navbar-item tag="div">
          <b-input
            @input="search"
            placeholder="חיפוש"
            rounded
          ></b-input>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-table
      striped
      class="bagel-table"
      height="100%"
      sticky-header
      :data="data"
      :loading="isLoading"
      :id="'_id'"
      :mobile-cards="false"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.id"
        :field="column.slug"
        :label="column.name.substring(0, 12)"
        sortable
        v-slot="props"
      >
        <div>
          <div
            v-if="isLoading"
            class="loading-box"
          ></div>
          <div
            class="row-action"
            v-if="column.id === 'row-action'"
          >
            <ul class="bagel-table-btns">
              <div
                class="bagel-table-btn"
                @click="
                  openactive =
                  openactive === props.row._id
                    ? (openactive = false)
                    : (openactive = props.row._id)
                "
              >
                more_vert
              </div>
              <div
                class="row-action-drop"
                :class="{ openactive: openactive === props.row._id }"
              >
                <li
                  @click="deleteRow(props.row)"
                  class="bagel-table-btn"
                  title="מחיקת שורה"
                >
                  delete
                </li>
                <li
                  class="bagel-table-btn"
                  title="שכפול"
                >content_copy</li>
              </div>
              <li
                @click="openRow(props.row)"
                class="bagel-table-btn"
                title="פתיחה"
              >
                open_in_new
              </li>
            </ul>
          </div>
          <img
            v-else-if="fieldTypes[column.type] === 'ImageRef'"
            style="height: 30px"
            :src="props.row[column.slug].imageURL"
            :alt="props.row[column.slug].altText"
          />

          <b-select
            v-else-if="column.type === 7"
            size="is-small"
            style="margin-top: -2px; margin-bottom: -4px"
            rounded
            v-model="props.row[column.slug]"
            @input="
              $db
                .collection(collectionID)
                .item(props.row._id)
                .put({ [column.slug]: $event })
            "
          >
            <option
              :value="option"
              v-for="option in column.options"
              :key="option._id"
            >
              {{ option.value }}
            </option>
          </b-select>

          <b-taglist v-else-if="fieldTypes[column.type] === 'ItemRef'">
            <b-tag
              rounded
              small
              v-for="(tag, i) in (props.row[column.slug] || []).slice(0, 2)"
              :key="i"
            >{{ tag.value }}</b-tag>
            <b-tag v-if="(props.row[column.slug] || []).length > 2">+{{ (props.row[column.slug] || []).slice(2).length }}</b-tag>
          </b-taglist>
          <InlineText
            v-else-if="fieldTypes[column.type] === 'PlainText'"
            :value="props.row[column.slug]"
            @input="updateContent({ [column.slug]: $event }, props.row._id)"
            :key="props.row[column.slug] + props.row._id"
          />
          <b-tag
            rounded
            class="is-light"
            v-else-if="column.type === 8"
            :class="{
              'is-success': props.row[column.slug],
              'is-danger': !props.row[column.slug],
            }"
          >{{ props.row[column.slug] }}</b-tag>
          <div
            v-else-if="fieldTypes[column.type] === 'Link'"
            class="link"
          >
            <p>{{ calcText(props.row, column) }}</p>
            <LinkPath
              :to="calcText(props.row, column)"
              title="מעבר לינק"
              class="data-btn toLink"
            >
              <span class="material-symbols-outlined">link</span>
            </LinkPath>
            <button
              @click="copyToClipboard(calcText(props.row, column))"
              class="data-btn"
              title="העתקת לינק"
            >
              <span class="material-symbols-outlined"> content_copy </span>
            </button>
          </div>
          <div v-else-if="fieldTypes[column.type] === 'DateVue'">
            {{
              props.row[column.slug]
              ? new Date(props.row[column.slug]).toLocaleString("he-IL", {
                year: "2-digit",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
              : ""
            }}
          </div>
          <div
            v-else
            @click="props.toggleDetails(props.row)"
          >
            {{ props.row[column.slug] }}
          </div>
        </div>
      </b-table-column>
    </b-table>

    <b-pagination
      :total="totalItems"
      v-model="currentPage"
      rounded
      :per-page="perPage"
      aria-current-label="Current page"
    ></b-pagination>
    <div
      class="new-card"
      @click="newItem = false"
      :class="{ open: newItem }"
    >
      <div
        class="card-slide"
        @click.stop
      >
        <div class="card-slide-top">
          <button
            class="data-btn"
            type="button"
            @click="newItem = false"
          >
            <icon icon="close" />
          </button>
          {{ newItemData._id ? "עדכון" : "חדש" }}
        </div>
        <form @submit.prevent="saveNewItem()">
          <div
            v-for="column in columns"
            :key="column.id"
          >
            <component
              :is="fieldTypes[column.type]"
              :field="column"
              v-model="newItemData[column.slug]"
              @input="$set(newItemData, column.slug, $event)"
            />
          </div>
          <button type="submit">שמירה</button>
        </form>
      </div>
    </div>
    <button
      v-if="allowNew"
      @click="newItem = true"
      rounded
      class="new-item"
      type="is-link"
      size="is-large"
    >
      <icon icon="add" />
      <span class="text">חדש</span>
    </button>
    <!--  -->
    <div
      class="filter-pop-wrap"
      :class="{ open: editingFilter }"
    >
      <div
        class="filter-pop"
        :class="{ filteropen: editingFilter }"
        v-if="editingFilter"
      >
        <button
          class="data-btn"
          type="button"
          @click="editingFilter = null"
        >
          <icon icon="close" />
        </button>
        <h3>
          {{ editingFilter.name || "פילטר חדש" }}
        </h3>
        <div class="filter-pop-top">
          <b-input
            v-model="editingFilter.name"
            type="text"
          ></b-input>
          <b-checkbox
            v-model="editingFilter.default"
            class="filter-pop-checkbox"
          >דיפולט</b-checkbox>
        </div>
        <div
          class="filter-row"
          v-for="(filter, index) in editingFilter.filterParams"
          :key="index"
        >
          <b-select
            v-model="editingFilter.filterParams[index].field"
            class="filter-item bigger"
            name=""
          >
            <option
              value=""
              hidden
            >בחירת שדה</option>
            <option
              :value="field.slug"
              v-for="field in columns"
              :key="field._id"
            >
              {{ field.name }}
            </option>
          </b-select>
          <div v-if="filter.field">
            <b-select
              class="filter-item"
              v-model="editingFilter.filterParams[index].action"
              name=""
              v-if="
                ['DateVue', 'Number'].includes(
                  fieldTypes[columns.find((c) => c.slug === filter.field).type]
                )
              "
            >
              <option value=">=">גדול מ</option>
              <option value="<=">קטן מ</option>
              <option value="!=">שונה מ</option>
              <option value="==">שווה ל</option>
            </b-select>
            <b-select
              class="filter-item"
              v-model="editingFilter.filterParams[index].action"
              name=""
              v-if="
                ['Link', 'PlainText', 'RichText'].includes(
                  fieldTypes[columns.find((c) => c.slug === filter.field).type]
                )
              "
            >
              <option value="regex">מכיל את המילה</option>
              <option value="!=">שונה מ</option>
              <option value="=">שווה בדיוק</option>
            </b-select>
          </div>
          <div v-if="filter.field">
            <multiselect
              label="value"
              style="width: 220px; height: 38px"
              v-if="
                fieldTypes[
                columns.find((c) => c.slug === filter.field).type
                ] === 'Option'
              "
              :options="columns.find((c) => c.slug === filter.field).options"
              track-by="value"
              value=""
              :multiple="false"
              v-model="editingFilter.filterParams[index].value"
            ></multiselect>
            <input
              type="date"
              v-else-if="
                fieldTypes[
                columns.find((c) => c.slug === filter.field).type
                ] === 'DateVue'
              "
              v-model="editingFilter.filterParams[index].value"
            />
            <b-switch
              dir="ltr"
              v-else-if="
                fieldTypes[
                columns.find((c) => c.slug === filter.field).type
                ] === 'Bool'
              "
              v-model="editingFilter.filterParams[index].value"
            ></b-switch>
            <input
              style="margin-top: 4px"
              class="input"
              v-model="editingFilter.filterParams[index].value"
              type="text"
              v-else
            />
          </div>
          <button
            @click="editingFilter.filterParams.splice(index, 1)"
            class="filter-delete-row"
          >
            delete
          </button>
        </div>

        <button
          @click="
            editingFilter.filterParams.push({
              field: '',
              action: '',
              value: '',
            })
          "
          class="filter-new-row"
        >
          + שורה חדשה
        </button>
        <div class="filter-btns">
          <button
            @click="deleteFilter"
            class="filter-delete"
          >
            מחיקת פילטר
          </button>
          <button
            @click="saveEditFilter"
            class="filter-save"
          >שמירה</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fieldTypes from "../fieldTypes.json";
import Number from "./fieldTypes/Number.vue";
import RichText from "./fieldTypes/RichText.vue";
import ItemRef from "./fieldTypes/ItemRef.vue";
import PlainText from "./fieldTypes/PlainText.vue";
import InlineText from "./InlineText.vue";
import MultiRef from "./fieldTypes/MultiRef.vue";
import ImageRef from "./fieldTypes/ImageRef.vue";
import Option from "./fieldTypes/Option.vue";
import Icon from "./icon.vue";
import TextSearch from "../textSearch";
import DateVue from "./fieldTypes/Date";
import Bool from "./fieldTypes/Bool.vue";
import LinkPath from "./LinkPath.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import BglButton from "../../../components/bgl-button.vue";

/* eslint-disable */
export default {
  name: "dataView",
  props: {
    title: String,
    collectionID: String,
    nestedCollection: String,
    itemID: String,
    dependant:{type:Array,default:()=>([])},
    allowNew: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  components: {
    Multiselect,
    DateVue,
    ItemRef,
    RichText,
    PlainText,
    Bool,
    Icon,
    Number,
    MultiRef,
    ImageRef,
    InlineText,
    Option,
    LinkPath,
    BglButton
},
  computed: {
    columns() {
      return [
        {
          label: "",
          id: "row-action",
          slug: "row-action",
          name: "",
        },
        {
          label: "",
          type: 10,
          id: "_createdDate",
          slug: "_createdDate",
          name: "תאריך הזמנה",
        },
        ...this.schema,
      ];
    },
    fieldTypes() {
      return fieldTypes;
    },
  },
  async mounted() {
    await this.getFilters();
    let { data: schema } = await this.$db.schema(this.collectionID).get();
    if (this.nestedCollection) {
      schema = schema.find((s) => s.slug === this.nestedCollection);
      this.collectionName = schema.name;
      schema = schema.nestedCollectionSchema;
    }
    this.schema = schema
      .filter((f) => f.type !== 14)
      .filter((f) => f.slug != "_metadata");
    this.getItems();
    this.getDependants();
  },
  methods: {
    async downloadData(){
      this.downloading = true;
      const {data:{id}} = await this.$db.collection("dataDownload").post({});
      console.log(id)
      const {activeFilter, collectionID} = this;
      let url = `/.netlify/functions/downloadData-background?collection=${collectionID}&downloadID=${id}`
      if (activeFilter.filterParams) url+= `&filterParams=${JSON.stringify(activeFilter.filterParams)}`;
      if (activeFilter.filter) url += `&filter=${JSON.stringify(activeFilter.filter)}`
      axios.post(url)
      this.$db.collection("dataDownload").item(id).listen((e) => {
        let { item } = JSON.parse(e.data);
        if (item._id === id) {
          let a = document.createElement('a')
          // a.download=`${this.title}-${activeFilter.name}.xlsx`
          a.href = item.file.imageURL+'&download=true';
          // console.log(`${this.title}-${activeFilter.name}.xlsx`, item.file.imageURL)
          document.body.append(a)
          a.click();
          setTimeout(()=>{
            a.remove()
            this.downloading = false;
          }, 500)
        }
      });
    },
    async getDependants(){
      let getArray = this.dependant.map(c=>this.$db.collection(c).get())
      const resArray = await Promise.all(getArray)
      for (let i in this.dependant){
        const collection = this.dependant[i]
        this.dependantData[collection] = resArray[i].data
      }
    },
    calcText(item, field) {
      if (item[field.slug]) return item[field.slug];
      const options = JSON.parse(field.helpText || "{}");
      const regex = /{{([\w.]*)}}/gi;

      if (options.default)
        return options.default.replace(regex, (RegExp, key) => item[key]);
    },
    copyToClipboard(txt) {
      this.$copyText(txt);
      this.$toast("הועתק בהצלחה");
    },
    setAtiveFilter(filter) {
      this.activeFilter = filter;
      this.getItems();
    },
    async getFilters() {
      this.filters = [];
      const { data } = await this.$db
        .collection("filters")
        .query("table", "==", this.collectionID)
        .get();
      data.unshift({ name: "הכל", filter: {} });
      this.activeFilter = data.find((d) => d.default) || data[0];
      this.filters = data;
    },
    async deleteRow(row) {
      const conf = window.confirm("זה ימחוק את השורה ולא ניתן יהיה לשחזר אותה");
      if (conf) {
        await this.$db.collection(this.collectionID).item(row._id).delete();
      }
      this.getItems();
    },
    async openRow(row) {
      this.newItem = true;
      this.newItemData = row;
    },
    search(searchText) {
      bounce(async () => {
        const search = new TextSearch({
          schema: this.schema,
          fields: this.schema,
          searchText,
        });
        if (searchText) this.activeFilter = this.filters[0];
        else
          this.activeFilter =
            this.filters.find((f) => f.default) || this.filters[0];
        const { data, headers } = await this.$db
          .collection(this.collectionID)
          .find(search.toQueryObj())
          .get();
        this.totalItems = parseInt(headers["item-count"]);
        this.data = data;
      });
    },
    newItemDataUpdate() {
      // console.log(this.newItemData);
    },
    async saveNewItem() {
      const { newItemData } = this;
      if (newItemData._id)
        this.$db
          .collection(this.collectionID)
          .item(newItemData._id)
          .put(newItemData);
      else this.$db.collection(this.collectionID).post(this.newItemData);
      this.newItem = false;
    },
    async getItems() {
      this.data = [{}, {}, {}];
      const { filterParams, filter } = this.activeFilter;
      // console.log(this.activeFilter.filter);
      this.isLoading = true;
      const { collectionID, nestedCollection, itemID } = this;
      const call = this.$db
        .collection(collectionID)
        .pageNumber(this.currentPage)
        .perPage(this.perPage);
        if (filter) {
          const today = new Date().toISOString().split('T').shift()
        const proccessedFilter = JSON.stringify(filter).replace(/TODAY/,today)
        call.find(JSON.parse(proccessedFilter || '{}'));
      }
      else if (filterParams && filterParams.length)
        for (const filter of filterParams) {
          const q = [filter.field];
          q.push(filter.action || "=");
          // console.log(filter);
          let field = this.columns.find((c) => c.slug === filter.field);
          // console.log(this.columns);
          const fieldType = fieldTypes?.[field.type];
          if (field && fieldType) {
            let val = filter.value;
            if (fieldType === "DateVue")
              val = Math.round(new Date(filter.value) / 1000);
            else if (fieldType === "Option") {
              val =
                typeof filter.value === "string"
                  ? filter.value
                  : filter.value._id;
              q[0] += "._id";
            }
            q.push(val);
            // console.log(q);
            call.query(...q);
          }
        }
      // console.log(call);
      if (nestedCollection) {
        call.item(itemID).collection(nestedCollection);
      }
      // call.listen((e) => {
      //   this.loading = true;
      //   let { item, trigger, itemID } = JSON.parse(e.data);
      //   let index = this.data.findIndex((o) => o._id === itemID);
      //   console.log(trigger, index);
      //   if (trigger === "delete") {
      //     this.data.splice(index, 1);
      //     this.totalItems--;
      //   }
      //   if (trigger === "update") this.data.splice(index, 1, item);
      //   if (trigger === "create") {
      //     this.data.unshift(item);
      //     this.totalItems++;
      //   }
      //   this.loading = false;
      // });
      const { headers, data } = await call.get();
      this.data = data;
      console.log(headers);
      this.totalItems = parseInt(headers["item-count"]);
      this.isLoading = false;
    },
    startEditField(column, row) {
      this.editingField = {
        slug: column.slug,
        _id: row._id,
      };
    },
    async updateContent(update, itemID) {
      const { collectionID, nestedCollection } = this;
      const call = this.$db.collection(collectionID);
      if (nestedCollection) {
        call.item(nestedCollection).collection(nestedCollection);
      }
      await call.item(itemID).put(update);
    },
    editFilter(filter) {
      this.editingFilter = filter || {
        name: "",
        filter: {},
        filterParams: [],
        default: false,
      };
    },
    deleteFilter() {
      const conf = window.confirm("רק לוודא שלא לחצת בטעות, תאשר ונמשיך");
      if (!conf) return;
      if (this.editingFilter._id)
        this.$db.collection("filters").item(this.editingFilter._id).delete();
      this.editingFilter = null;
      this.getFilters();
    },
    async saveEditFilter() {
      this.editingFilter.table = this.collectionID;
      if (this.editingFilter.default) {
        await Promise.all(
          this.filters
            .filter((f) => f._id)
            .map((f) =>
              this.$db.collection("filters").item(f._id).put({ default: false })
            )
        );
      }
      if (this.editingFilter._id)
        this.$db
          .collection("filters")
          .item(this.editingFilter._id)
          .put(this.editingFilter);
      else this.$db.collection("filters").post(this.editingFilter);
      this.editingFilter = null;
      this.getFilters();
    },
  },
  watch: {
    currentPage() {
      this.getItems();
    },
  },
  data() {
    return {
      downloading:false,
      openactive: false,
      multiSelectHelper: [],
      editingFilter: null,
      activeFilter: {},
      filters: [],
      newItem: false,
      newItemData: {},
      collectionName: this.title,
      isLoading: false,
      perPage: 100,
      currentPage: 1,
      totalItems: 0,
      editingField: {
        slug: "",
        _id: "",
      },
      selected: null,
      data: [],
      dependantData:{},
      schema: [],
      filter: null,
    };
  },
};
let timer;
function bounce(func, timeout = 300) {
  clearTimeout(timer);
  timer = setTimeout(() => {
    func();
  }, timeout);
}
</script>

<style>

.icon-font {
  font-family: "Material Symbols Outlined";
  display: inline-block;
  opacity: 0.5;
}

.table-wrapper {
  height: 100vh;
}

.editable-input {
  cursor: pointer;
  max-height: 100px;
  overflow: auto;
}

.select:not(.is-multiple):not(.is-loading)::after {
  z-index: 1 !important;
}

.data-btn {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 0;
  border-radius: 100%;
  transition: all 200ms ease;
  /* border: 1px solid gainsboro; */
  margin-left: 1px;
  margin-right: 1px;
  background: lightgray;
  cursor: pointer;
  /* box-shadow: 2px 2px 3px rgba(145, 145, 145, 0.15); */
}

.data-btn:hover {
  background: rgb(234, 234, 234);
}

.loading-box {
  background: rgba(159, 159, 159, 0.1);
  height: 30px;
}

.new-item {
  position: fixed;
  bottom: 80px;
  left: 40px;
  right: unset;
  background-color: #1d3779;
  border-color: transparent;
  color: #fff;
  border-radius: 100px;
  height: 60px;
  line-height: 1;
  padding: 0;
  display: flex;
  width: 60px;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  z-index: 999;
  font-family: inherit;
}

.new-item:hover {
  width: 130px;
}

.new-item:active {
  filter: brightness(90%);
}

.new-item i:before {
  margin-top: -3px;
  display: inline-block;
  line-height: 60px;
}

.new-item .text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: width ease 1.5s;
  transition: opacity ease 0.5s;
  transition-delay: 0;
  font-weight: 300;
  padding: 0 4px;
  margin-bottom: 2px;
  position: absolute;
  white-space: nowrap;
}

.new-item:hover .text {
  opacity: 1;
  position: relative;
  transition-delay: 200ms;
}

.new-card {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  transition: all ease;
}

.new-card.open {
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: all;
}

.card-slide {
  position: fixed;
  top: 0;
  left: -600px;
  bottom: 0;
  background: white;
  box-shadow: -4px 3px 10px #0000001a;
  max-width: 600px;
  width: 90%;
  padding: 20px;
  transition: all ease 0.2s;
}

.card-slide .data-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
}

.card-slide-top {
  /* display: flex; */
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.open .card-slide {
  left: 0;
}

.card-slide form {
  padding: 0 50px;
  overflow-y: auto;
  height: calc(100vh - 120px);
}

.card-slide form button {
  border-radius: 10px;
  padding: 9px 50px;
  transition: all 200ms ease;
  color: white;
  font-family: inherit;
  background: #1d3779;
  margin: -10px auto 0;
  text-align: center;
  display: block;
  margin-top: 20px;
  /* width: 100%; */
}

.card-slide form button:hover {
  filter: brightness(90%);
  box-shadow: 0 0 0 0.125em #1d377940;
}

.card-slide form button:active {
  filter: brightness(80%);
}

.card-slide .field {
  margin-bottom: 10px;
}

.card-slide .b-numberinput.field.is-grouped {
  gap: 10px;
}

.card-slide .field.has-numberinput * {
  margin: 0 !important;
}

.card-slide .b-numberinput .button {
  background-color: #1d3779 !important;
}

.bgl-nav .navbar-menu {
  display: inline-block;
  text-align: end;
  margin-inline-end: 1rem;
}

.pagination {
  padding: 10px 30px 0;
}

.pagination-link.is-current {
  background-color: #1d3779 !important;
  border-color: #1d3779 !important;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #1d3779 !important;
}

.mdi-chevron-left::before {
  content: "\F0142" !important;
}

.mdi-chevron-right::before {
  content: "\F0141" !important;
}

.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #1d3779 !important;
  box-shadow: 0 0 0 0.125em #1d377940 !important;
}

.select select {
  font-family: inherit;
  margin-top: 4px;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #1d3779 !important;
  margin-top: 0px !important;
}

.bagel-table-btns {
  font-family: "Material Symbols Outlined";
  position: absolute;
  display: flex;
  position: relative;
  z-index: 2;
}

.bagel-table-btn {
  text-align: center;
  color: #363636;
  transition: all 200ms ease;
  border-radius: 100%;
  font-size: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
}

.bagel-table-btn:hover {
  color: white;
  background: #1d3779;
}

.bagel-table-btn:active {
  filter: brightness(90%);
}

.row-action {
  /* opacity: 0; */
  margin-top: 6px;
  transition: all 200ms 349ms ease;
  /* position: absolute; */
  float: left;
  width: 70px;
  margin-left: -20px;
}

.row-action-drop {
  position: absolute;
  opacity: 0;
  top: 0px;
  right: 30px;
  margin-left: -30px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.2);
  max-height: 0;
  overflow: hidden;
  transition: all 200ms ease;
  background: white;
  z-index: 99;
}

.row-action-drop li {
  pointer-events: none;
  display: inline-block;
}

.row-action-drop.openactive {
  opacity: 1;
  max-height: 100vh;
  padding: 10px;
}

.row-action-drop.openactive li {
  pointer-events: all;
}

.bagel-table td {
  transition: all 200ms ease;
}

.bagel-table td:not(:first-child) {
  min-width: 160px;
  width: min-content;
  white-space: nowrap;
}

.bagel-table td:nth-child(2) {
  min-width: 200px;
}

.bagel-table tr:hover td:nth-child(2) .editable-window {
  margin-right: 50px;
}

.bagel-table tr td:nth-child(2) div {
  transition: all 200ms 349ms ease;
}

.bagel-table tr:hover td:nth-child(2) div {
  /* margin-right: 40px; */
}

.bagel-table td:nth-child(2) div div {
  padding: 5px 0;
}

.bagel-table tr:hover .row-action {
  opacity: 1;
}

.a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  color: #1d3779 !important;
}

.new-filter {
  border-radius: 100%;
  width: 26px;
  height: 26px;
  background: white;
  color: #1d3779;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
  outline: 1px solid #1d3779;
}

.new-filter:hover {
  color: white;
  background: #1d3779;
}

.new-filter:active {
  filter: brightness(80%);
}

.default-mark {
  background: #253877;
  height: 6px;
  width: 6px;
  margin: 2px;
  border-radius: 100%;
  right: 4px;
  display: inline-block;
}

.active-tab .default-mark {
  background: white;
}

.filter-tab {
  background: #f4f6fa;
  border-radius: 30px;
  padding: 1px 10px 3px;
  transition: all 200ms ease;
  margin-left: -14px;
  transition: all 200ms 100ms ease;
}

.filter-tab:hover {
  filter: brightness(90%);
  padding-left: 30px;
}

.navbar-item:nth-child(3) .filter-tab:hover {
  filter: brightness(90%);
  padding-left: 10px;
}

.settings-filter-btn {
  background: transparent;
  padding: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  left: 4px;
  top: 4px;
  border-radius: 100px;
  position: absolute;
  pointer-events: none;
  transition: all 200ms 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-filter-btn:hover {
  background: white;
  color: #1d3779;
}

.filter-tab:hover .settings-filter-btn {
  opacity: 1;
  pointer-events: all;
}

.filter-tab:active {
  filter: brightness(80%);
}

.filter-tab.active-tab {
  background: #1d3779;
  color: white;
}

.filter-pop {
  position: fixed;
  z-index: 99;
  padding: 30px 40px 20px;
  background: white;
  left: 0;
  right: 0;
  top: 10vh;
  max-width: 700px;
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.2);
  border-radius: 15px;
  margin: 0 auto;
  background: white;
  opacity: 0;
  transition: all 200ms ease;
  pointer-events: none;
}

.filter-pop-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #36363660;
  pointer-events: none;
  z-index: 999;
  opacity: 0;
}

.filter-pop-wrap.open {
  opacity: 1;
  pointer-events: all;
}

.filter-pop.filteropen {
  opacity: 1;
  pointer-events: all;
}

.filter-pop h3 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}

.filter-pop .data-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -40px -40px 10px 0;
}

.filter-pop-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
}

.filter-pop-top .control.is-clearfix {
  flex-grow: 1;
}

.filter-pop-checkbox .check {
  border-radius: 100% !important;
  margin-left: 4px;
}

.filter-pop-checkbox input[type="checkbox"]:checked+.check {
  background: #1d3779 !important;
  border-color: #1d3779 !important;
  position: relative;
}

.filter-pop-checkbox input[type="checkbox"]:checked+.check::after {
  content: "";
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
  z-index: 2;
  border-radius: 100%;
  border: 2px solid white;
  position: absolute;
}

.filter-row {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-item {
  flex-grow: 1;
}

.filter-item.bigger {
  flex-grow: 4;
}

.filter-item .select,
.filter-item select {
  width: 100%;
}

.filter-item .select::after {
  width: 100%;
}

.filter-delete-row {
  font-family: "Material Symbols Outlined";
  background: transparent;
  transition: all 200ms ease;
  opacity: 0.6;
  color: #4a4a4a;
  margin-top: 5px;
}

.filter-delete-row:hover {
  opacity: 1;
}

.filter-delete-row:active {
  filter: brightness(10%);
}

.filter-new-row {
  background: transparent;
  color: #4a4a4a;
  opacity: 0.6;
  transition: all 200ms ease;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  font-family: inherit;
}

.filter-new-row:hover {
  opacity: 1;
}

.filter-new-row:active {
  filter: brightness(10%);
}

.filter-save {
  border-radius: 10px;
  padding: 9px 50px;
  transition: all 200ms ease;
  color: white;
  font-family: inherit;
  background: #1d3779;
  /* margin: -10px auto 0; */
  text-align: center;
  display: block;
  margin-top: 20px;
}

.filter-save:hover {
  filter: brightness(90%);
}

.filter-save:active {
  filter: brightness(80%);
}

.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
  border-width: 1px !important;
  height: 0.5em !important;
  width: 0.5em !important;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: transparent !important;
}

.download-btn {
  color: #1d3779;
  user-select: none;
  margin-top: 4px;
  border-radius: 20px;
  padding: 1px 10px 3px;
  display: flex;
  transition: all 200ms ease;
}

.download-btn .loader-2 {
  border: 1px solid #1d377950;
  border-right: 1px solid #1d3779;
}

.download-btn span {
  margin-left: 2px;
}

.download-btn:hover {
  background: #f4f6fa;
}

.download-btn:active {
  filter: brightness(80%);
}

input.input {
  font-family: inherit;
}

.navbar-brand {
  margin-right: 2rem;
}

.link {
  display: flex;
  align-items: center;
  transition: all 200ms ease;
  border-radius: 30px;
  padding-right: 5px;
}

.link p {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.link:hover {
  background: #f3f3f3;
}

.link .data-btn {
  transition: all 200ms ease;
  background: white;
  outline: 1px solid #b5b5b5;
  margin: 2px;
  color: #1d3779;
}

.link .data-btn:hover {
  outline: 1px solid #1d3779;
  box-shadow: 0 0 0 0.125em #1d377940;
}

.link .data-btn:active {
  filter: brightness(90%);
}

.link .data-btn .material-symbols-outlined {
  font-size: 15px;
  margin-top: 6px;
}

.link .data-btn.toLink .material-symbols-outlined {
  font-size: 15px;
  margin-top: 8px;
}

input[type="date"] {
  font-family: inherit;
  margin-top: 4px;
  background-color: white;
  border-radius: 4px;
  color: #363636;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  border: #dbdbdb 1px solid;
  height: 40px;
  padding: 10px;
}

input[type="date"]:focus {
  border-color: #1d3779;
  box-shadow: 0 0 0 0.125em #1d377940;
  outline: none;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled)+.check {
  border-color: #1d3779 !important;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked+.check {
  box-shadow: 0 0 0.5em #1d377940 !important;
}

.filter-btns {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.filter-delete {
  background: transparent;
  transition: all 200ms ease;
  font-family: inherit;
}

.filter-delete:hover {
  color: firebrick;
}

input[placeholder="חיפוש"] {
  height: 28px;
  margin-top: 4px;
}

.th-wrap {
  white-space: nowrap;
}

.filter-row .multiselect {
  background: white;
  min-height: 38px;
}

.filter-row .multiselect .multiselect__tags {
  min-height: 40px;
  margin-top: 4px;
  line-height: 1;
  display: block;
  padding: 7px 10px 0 3px;
  border-radius: 5px;
  border: 1px solid #a9b1c2;
  background: white;
  font-size: 14px;
}

.filter-row .multiselect .multiselect__placeholder {
  font-size: 16px;
  margin-top: 2px;
}

.filter-row .multiselect .multiselect__select:before {
  content: "";
}

.filter-row .multiselect .multiselect__input:focus+.multiselect__tags {
  border-color: #1d3779 !important;
  box-shadow: 0 0 0 0.125em #1d377940 !important;
}

@media screen and (max-width: 767px) {
  .navbar-burger.burger {
    display: none;
  }

  .bagel-table {
    padding: 0 0 0;
    box-shadow: none;
    border-radius: 0;
    margin: 0 0rem;
    height: calc(100vh - 185px);
  }

  .navbar {
    display: flex !important;
    width: 100%;
    overflow-x: auto;
  }

  .navbar-end {
    display: flex;
  }

  .pagination {
    padding: 10px 30px 0;
    margin: 0 !important;
  }

  .navbar-menu {
    box-shadow: none !important;
  }

  .navbar-brand {
    margin-right: 0rem;
  }

  .new-item {
    bottom: 130px;
    left: 20px;
  }

  .card-slide {
    position: fixed;
    top: 0;
    left: -600px;
    bottom: 0;
    background: white;
    box-shadow: -4px 3px 10px #0000001a;
    max-width: 600px;
    width: 100%;
    padding: 10px;
    transition: all ease 0.2s;
  }

  .card-slide form {
    padding: 0 10px;
    overflow-y: auto;
    height: calc(100vh - 80px);
  }

  .card-slide form button {
    padding: 9px 20px;
    margin-top: 10px;
  }
}
</style>
