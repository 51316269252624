import { render, staticRenderFns } from "./botfooter.vue?vue&type=template&id=a1720444"
import script from "./botfooter.vue?vue&type=script&lang=js"
export * from "./botfooter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports