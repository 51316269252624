<template>
<div class="point-page">
  <div class="w1200">
    <h1 class="top-head">
      רשימת נקודות המכירה
    </h1>
    <div class="w770">
      <p class="txt20 center mb-0">
        להלן רשימת הנקודות בארץ מהם ניתן לרכוש את סיכות דם המכבים בשנת 2024.
      </p>
      <p class="txt20 center mt-0">סיכת הפרח האמיתי: 18 ש"ח. סיכת דמוי הפרח: 10 ש"ח.</p>
      <p class="txt20 center mb-0">נא שימת ליבכם:
      </p>
      <ul class="txt20 mt-0">
        <li> 
          <b>
            משפחות שכולות מוזמנות לקבל סיכות ללא עלות אצל המתנדבים שלנו
            <span class="underline">
              בבית או בקניוני עזריאלי
            </span>
            
            [אך לא בסניפי ויקטורי]
          </b>
        </li>

        <li> רכישת הסיכות בכלל הנקודות תתאפשר החל מיום השואה ועד לערב יום הזיכרון.</li>
        <li> הדוכנים בקניוני עזריאלי יפעלו רק בתאריכים 9-10 לחודש מאי.</li>
        <li>בסניפי ויקטורי יהיה מלאי של סיכות הפרח האמיתי בלבד.</li>
      </ul>
    </div>
  </div>
  <!-- <div class="points-table-wrap">
    <table class="points-table-list blue">
      <thead>
        <tr>
          <th>עיר</th>
          <th>שם הנקודה</th>
          <th>כתובת</th>
          <th>שם איש קשר</th>
          <th>פלאפון</th>
          <th>שעות פתיחה</th>
          <th>הערות</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="point in distributionLocations"
          :key="point._id"
        >
          <td>{{ point.city }}</td>
          <td>{{ point.pointName }}</td>
          <td>{{ point.address }}</td>
          <td>{{ point.contactName }}</td>
          <td>{{ point.phone }}</td>
          <td>{{ point.openingHours }}</td>
          <td>{{ point.comment }}</td>
        </tr>
      </tbody>
    </table>
  </div> -->

  <div
    class="points-table-wrap"
    id="csvTableContainer"
  ></div>
<p class="view-more-list">
  →
  ניתן לגלול הצידה לראות עוד פרטים 
  ←
</p>
</div>
</template>

<script>
export default {
  data: () => ({
    distributionLocations: [],
  }),
  async beforeMount() {
    let { data } = await this.$db
      .collection("distributionLocations")
      .get();


    this.distributionLocations = data?.sort((a, z) => a.city < z.city ? -1 : 1);
    this.loadCSV()
  },
  // var url = document.getElementById('csvUrlInput').value;

  methods: {
    async loadCSV() {
      let { data } = await this.$db
        .collection("distributionLocationsData")
        .get();

      fetch(data[0].cSV.imageURL)
        .then(response => response.text())
        .then(csv => {
          var rows = csv.split('\n');
          var table = '<table>';

          rows.forEach(function (row) {
            table += '<tr>';
            var cells = row.split(',');
            cells.forEach(function (cell) {
              table += '<td>' + cell + '</td>';
            });
            table += '</tr>';
          });

          table += '</table>';
          document.getElementById('csvTableContainer').innerHTML = table;
        })
        .catch(error => {
          console.error('Error fetching CSV:', error);
        });
    }
  }
};

</script>

<style>
.underline{
  text-decoration: underline;
}
.points-table-wrap {
  max-width: 100vw;
  overflow: auto;
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
  margin-top: 4rem;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  color: #1d3779;
  position: sticky;
  top: 240px;
    max-height: calc(100vh - 120px);
  }

  .points-table-wrap table {
    width: 100%;
  }

  .points-table-wrap table th {
    background-color: #f1f1f1;
    padding: 8px;
    text-align: start;
  }

  .points-table-wrap table tr:first-child {
    position: sticky;
    top: 0;
  }

  .points-table-wrap table tr:nth-child(even) {
    background-color: #f1f1f1;
  }

  #csvTableContainer table tr:nth-child(odd) {
    background-color: #f1f1f1;
  }

  #csvTableContainer table tr:nth-child(even) {
    background-color: unset;

  }


  .points-table-wrap table td {
    padding: 1rem;
    min-width: 120px;
    vertical-align: top;
  }

  .points-table-wrap table tr {
    transition: all 200ms ease;
  }

  .points-table-wrap table tr:nth-child(1) {
    font-weight: 700;
    background-color: #f1f1f1;
  }

  .points-table-wrap table tr:hover {
    background-color: #ddd;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .point-page {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }
  .view-more-list{
    display: none;
  }

  @media screen and (max-width: 991px) {

    .points-table-wrap {
      max-height: calc(100vh - 80px);
      /* font-size: 14px; */
      margin-top: 1rem;
    }

    .points-table-wrap table td {
      padding: 4px;
      min-width: 0;
      vertical-align: top;
      white-space: nowrap;
    }

    .point-page {
      width: auto;
      margin: 0 auto;
    }
    .view-more-list{
    display: block;
    position: fixed;
    z-index: 3;
    bottom: 1rem;
    background: black;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    opacity: 0.8;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: fit-content;
    font-size: 14px;

  }
}


</style>

<style>
.point-page~ .footer{
  display: none;
}
</style>