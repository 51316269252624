<template>
<div class="gray-bg">
  <div class="w900">
    <div
      class="center "
      v-if="$lang.locale == 'he'"
    >
      <h1 class="home-title blue m-0">
        במיוחד השנה, זוכרים וצומחים.
        ביחד.
      </h1>
      <p class="home-subtitle m-0">מוזמנים להשאיר לנו הודעה, ואנו נחזור אליכם.</p>
    </div>



    <div
      class="center "
      v-if="$lang.locale == 'en'"
    >
      <h1 class="home-title blue m-0">
        Especially this year, remember and grow.
        together.
      </h1>
      <p class="home-subtitle m-0">Feel free to leave us a message, and we will get back to you.</p>
    </div>




    <div class="contact-box">
      <img
        class="contact-img"
        src="../assets/bg-3.jpg"
        alt="דם המכבים"
      >
      <div class="contact-form-wrap">
        <div class="form">
          <form
            @submit.prevent="sendForm"
            v-if="formStatus != 'success'"
          >
            <select
              class="txt-field"
              v-model="form.subject"
              name="subject"
              id="subject"
            >
              <option
                value=""
                selected
                disabled
                hidden
              >
                {{ $l("Subject") }}
              </option>
              <option value="organization">{{ $l("organization") }}</option>
              <option value="educationalInstitution">{{ $l("educationalInstitution") }}</option>
              <option value="volunteering">{{ $l("volunteering") }}</option>
              <option value="wholeFamily">{{ $l("wholeFamily") }}</option>
              <option value="general">{{ $l("general") }}</option>
            </select>
            <input
              v-model="form.name"
              :placeholder="$l('Name')"
              type="text"
              name="name"
              class="txt-field"
              required
            /><input
              v-model="form.phone"
              :placeholder="$l('Phone')"
              type="tel"
              name="phone"
              required
              class="txt-field phone"
              style="text-align: start;"
            /><input
              v-model="form.email"
              :placeholder="$l('mail')"
              type="email"
              name="email"
              required
              class="txt-field"
            /><textarea
              v-model="form.message"
              :placeholder="$l('message')"
              name="message"
              class="txt-field big"
            ></textarea>



            <div>
              <input
                class="btn cont-page-btn"
                type="submit"
                id=""
                :value="$l('Submit')"
              />
            </div>
          </form>

          <div
            v-if="formStatus == 'fail'"
            class="form-fail"
          >
            <p>{{ $l("error") }}</p>
          </div>
          <div
            v-if="formStatus == 'success'"
            class="form-success"
          >
            <p>{{ $l("tnx") }}</p>
          </div>
        </div>
        <div class="contact-extra">
          <p class="contact-txt">
            {{ $l("Email") }}
            <a href="mailto:info@redeverlasting.com">info@redeverlasting.com</a>
          </p>
          <div class="contact-soc">
            <a
              class="soc-link"
              href="https://www.facebook.com/damhamacabim/"
              target="_blank"
            >
              <img
                src="../assets/facebook-white.svg"
                alt="facebook"
              />
            </a>
            <a
              class="soc-link"
              href="https://www.instagram.com/damhamaccabim/"
              target="_blank"
            >
              <img
                src="../assets/instagram-white.svg"
                alt="instagram"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      openPopup: null,
      openSideBar: false,
      formStatus: "",
      form: {
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
        emailTo: "info@redeverlasting.com"
      },
    };
  },
  methods: {
    async sendForm() {
      try {
        if (
          ['organization', 'educationalInstitution'].includes(this.form.subject)
        ) this.form.emailTo = 'mickey@redeverlasting.com';

        await this.$db.collection("contact").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  name: "botfooter",
};
</script>


<style>
.gray-bg {
  background: #f1f1f1;
  padding: 3rem 0;

}

.w900 {
  max-width: 1000px;
  margin: 0 auto;
  width: 90%;
}

.contact-box {
  display: grid;
  grid-template-columns: 500px 1fr;
}

.contact-img {
  object-fit: cover;
  z-index: 0;
  margin-top: 1rem;
}

.contact-form-wrap {
  position: relative;
  z-index: 3;
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  padding: 2rem;
}

.contact-extra {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.contact-extra .contact-soc {
  justify-content: center;
}

.contact-form-wrap .txt-field {
  background: #f1f1f1;
  border-radius: 0.25rem;
}


.cont-page-btn {
  padding-inline: 70px;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 991px) {
  .contact-box {
    grid-template-columns: 1fr;
  }

  .contact-form-wrap {
    padding: 1rem;
  }

  .cont-page-btn {
    width: 100%;
  }
}
</style>