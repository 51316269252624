<template>
<div class="footer">
  <router-link
    to="/"
    class="footer-logo"
  >
    <img
      src="../assets/dam.png"
      alt="דם המכבים"
    />
  </router-link>
  <div class="footer-link-wrap">
    <router-link
      to="/about"
      class="footer-link"
    >
      {{ $l("About") }}
    </router-link>
    <!-- <router-link
        to="/nonprofits"
        class="footer-link"
      >
        {{ $l("nonprofit") }}
      </router-link> -->
    <router-link
      to="/story"
      class="footer-link"
    >
      {{ $l("Story") }}
    </router-link>
    <router-link
      class="footer-link"
      to="/contact"
    >{{ $l("Contact") }}</router-link>
    <router-link
      to="/donations"
      class="footer-link"
    >
      {{ $l("Donations") }}
    </router-link>
    <!-- רשימת הנקודות -->
    <router-link
      class="footer-link"
      to="/points"
    >{{ $l("Points") }}</router-link>

    <router-link
      to="/gallery"
      class="footer-link"
    >
      {{ $l("Gallery") }}
    </router-link>
    <router-link
      class="footer-link"
      to="/terms"
    >{{ $l("TermsOfUse") }}</router-link>
    <router-link
      class="footer-link"
      to="/accessibility"
    >
    <img width="20px" src="../assets/accessibility.png" alt="Accessibility">
    {{ $l("Accessibility") }}</router-link>
    <button
      @click="$lang.locale = $lang.locale == 'en' ? 'he' : 'en'"
      class="footer-link"
    >
      English
    </button>
    <!-- <router-link to="/order" class="footer-link"
        >{{ $l("getYourPin") }}
      </router-link> -->
    <div class="soc-wrap">
      <a
        class="footer-link"
        href="https://www.facebook.com/damhamacabim/"
        target="_blank"
      >
        <img
          src="../assets/facebook.svg"
          alt="facebook"
        />
      </a>
      <a
        class="footer-link"
        href="https://www.instagram.com/damhamaccabim/"
        target="_blank"
      >
        <img
          src="../assets/instagram.svg"
          alt="instagram"
        />
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "botfooter",
};
</script>
