<template>
  <div><DataView title="עמותות" collectionID="nonprofits"></DataView></div>
</template>

<script>
import DataView from "./components/dataView.vue";
export default {
  components: {
    DataView,
  },
};
</script>
