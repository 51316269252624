const CONSTANTS = {}
import fieldTypes from "./fieldTypes.json";
CONSTANTS.DATA_TYPES = fieldTypes


class TextSearch {
  fields = []

  searchText = ""

  textFieldTypes = []

  nestedID = ''

  parentItemID = ''

  nestedSplit = []

  activeColsArr = []

  activeCols = ''

  schema = []

  constructor({
    searchText = '', fields, nestedID, parentItemID, schema,
  }) {
    this.fields = fields;
    this.searchText = (searchText || '').split(/\s*\|\s*/).join('|');
    this.textFieldTypes = ['PlainText', 'RichText', 'Phone', 'Link'];
    this.nestedID = nestedID;
    this.parentItemID = parentItemID;
    this.schema = schema;
    if (nestedID) {
      this.nestedSplit = nestedID.split('.');
      this.activeColsArr = this.nestedSplit?.filter((_, i) => i % 2 === 0);
      this.activeCols = this.activeColsArr?.join('.');
    }
  }

  toQueryObj = () => {
    if (!this.searchText) return;
    const {
      nestedID,
      parentItemID,
      nestedSplit,
      activeColsArr,
      activeCols, schema,
    } = this;
    let { searchText } = this;

    if (!Array.isArray(searchText)) {
      searchText = `${searchText}`;
    }
    console.log(this)
    const textFields = this.fields.filter((f) => this.textFieldTypes.includes(CONSTANTS.DATA_TYPES[f.type]));

    const textFieldSlugs = textFields.map((t) => {
      const slug = nestedID ? `${activeCols}.${t.slug}` : t.slug;
      return (
        { [slug]: { $regex: searchText, $options: 'i' } }
      );
    });

    const numberFields = this.fields.filter((f) => CONSTANTS.DATA_TYPES[f.type] === 'Number');
    const numberFieldSlugs = !Number.isNaN(parseFloat(`${searchText}`)) ? numberFields.map((t) => {
      const slug = nestedID ? `${activeCols}.${t.slug}` : t.slug;
      return ({ [slug]: parseFloat(`${searchText}`) });
    }) : [];

    //
    const refFields = this.fields.filter((f) => CONSTANTS.DATA_TYPES[f.type] === 'ItemRef');
    const refFieldSlugs = refFields.map((t) => {
      const slug = nestedID ? `${activeCols}.${t.slug}` : t.slug;
      return (
        { [`${slug}.value`]: { $regex: searchText } }
      );
    });


    const idSearch = [{ _id: searchText }];

    const optionFields = this.fields.filter((f) => CONSTANTS.DATA_TYPES[f.type] === 'Option');
    const optionFieldSlugs = optionFields.map((t) => {
      const slug = nestedID ? `${activeCols}.${t.slug}` : t.slug;
      return (
        { [`${slug}.value`]: { $regex: searchText } }
      );
    });

    let findObj = {
      $or: [
        ...idSearch,
        ...textFieldSlugs,
        ...numberFieldSlugs,
        ...refFieldSlugs,
        ...optionFieldSlugs,
      ],
    };

    if (nestedID && activeColsArr && schema) {
      const res = [{
        $match: {
          _id: parentItemID,
        },
      }];

      const unwound = activeColsArr.map((col, i, arr) => ({
        $unwind: `$${arr.slice(0, i + 1).join('.')}`,
      }));
      res.push(...unwound);
      // console.log(JSON.stringify({ unwound, nestedSplit, res }, null, 2));
      const isOneLevelDeep = nestedSplit?.length === 1;
      if (isOneLevelDeep) {
        res.push({
          $match: {
            ...findObj,
          },
        });
      } else {
        res.push({
          $match: {
            [`${res.at(-2)?.$unwind.slice(1)}._id`]: nestedSplit?.at(-2),
            ...findObj,
          },
        });
      }
      res.push({
        $replaceRoot: {
          newRoot: res.at(-2)?.$unwind,
        },
      });
      const $project = {
        _id: '$_id',
        _lastUpdatedDate: '$_lastUpdatedDate',
        _createdDate: '$_createdDate',
      };
      schema.filter((f) => CONSTANTS.DATA_TYPES[f.type] !== 'NestedCollection').map((f) => f.slug).forEach((slug) => {
        Object.assign($project, { [slug]: 1 });
      });
      res.push({
        $project,
      });
      findObj = res;
    }

    return findObj;
  };
}

export default TextSearch;
