<template>
  <section class="field">
    <label class="label">
      {{ field.name }}
    </label>
    <b-dropdown
      :scrollable="isScrollable"
      :max-height="maxHeight"
      v-model="val"
      @input="emitVal"
      aria-role="list"
    >
      <template #trigger>
        <b-button
          :label="val ? val.value : field.name"
          :type="val ? 'is-primary' : ''"
          icon-right="menu-down"
        />
      </template>
      <b-dropdown-item
        v-for="option in field.options"
        :key="option._id"
        :value="option"

        aria-role="listitem"
      >
        <div class="media">
          <div class="media-content">
            <h3>{{ (option || {}).value }}</h3>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </section>
</template>

<script>
export default {
  name: "Options",
  props: { field: Object, value: Object },
  data() {
    return {
      isScrollable: false,
      maxHeight: 200,
      val: this.value,
      currentMenu: { icon: "account-group", text: "People" },
    };
  },
  watch: {
    value(val) {
      if (val != this.val) this.val = val;
    }
  },
  methods: {
    emitVal() {
      console.log("hi")
      this.$emit("input", this.val);
    },
  },
};
</script>

<style >
.card-slide .dropdown {
  width: 100% !important;
}

.card-slide .dropdown-trigger {
  width: 100% !important;

}

.card-slide .dropdown button {
  background: white !important;
  margin-top: 0 !important;
  color: #363636 !important;
  text-align: start !important;
  width: 100% !important;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  /* box-shadow: 0 0.0625em 0.125em rgba(10, 10, 10, 0.05); */
  border-color: #dbdbdb;
}

.is-clipped-touch .dropdown.dropdown-menu-animation.is-mobile-modal button {
  box-shadow: 0 0 0 0.125em #20377b40 !important;
}

.card-slide .dropdown-menu {
  width: 100%;
}


.card-slide a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: #20377b !important;
  color: #fff;
}



.card-slide .dropdown-content {
  max-height: 160px;
  overflow: auto;
  padding: 0;
}

.card-slide .dropdown-item {
  padding: 9px;
}
</style>