<template>
  <div>
    <DataView
      title="הזמנת חברות"
      :allowNew="false"
      collectionID="companies"
    ></DataView>
  </div>
</template>

<script>
import DataView from "./components/dataView.vue";
export default {
  components: {
    DataView,
  },
};
</script>
