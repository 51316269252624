<template>
  <b-field :label="field.name">
    <b-numberinput @input="emitValue" v-model="val"></b-numberinput>
  </b-field>
</template>

<script>
export default {
  name: "Number",
  props: { field: Object, value: String },
  data() {
    return {
      val: this.value,
    };
  },
  watch: {
    value(value) {
      if (value !== this.val) this.val = value;
    },
  },
  methods: {
    emitValue() {
      this.$emit("input", this.val);
    },
  },
};
</script>
