<template>
<div class="main">
  <div class="top-bg">
    <img
      class="don-bg"
      src="../assets/donations-back.jpg"
    />
  </div>
  <div class="big-white-box donation">
    <div class="donation-wrap">
      <h1 class="top-head">טופס הזמנת סיכות דם המכבים לבתי ספר</h1>
      <p
        v-if="formStatus != 'success'"
        class="txt24 center"
      >
        על מנת להנגיש את סיכת דם המכבים לכמה שיותר מתלמידי ישראל, אנו מציעים
        מחיר מיוחד, לבתי הספר בלבד, עבור סיכות הפלסטיק.
      </p>
    </div>
    <div class="form">
      <form
        @submit.prevent="sendForm"
        v-if="formStatus != 'success'"
      >
        <div class="plastic-wrap">
          <img
            src="../assets/buy3.jpg"
            alt="פלסטיק"
          />
          <div>
            <p class="txt24">פרח דמוי דם המכבים</p>
            <p>{{ price }} ש״ח לסיכה (מחיר מיוחד לבתי ספר)</p>
            <label>
              כמה סיכות תרצו להזמין

              <input
                v-model="form.flowerNum"
                placeholder="0"
                type="number"
                required
                class="small-txt-field"
              />
            </label>
          </div>
        </div>
        <div class="side-by">
          <input
            v-model="form.name"
            placeholder="שם בית הספר"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.id"
            placeholder="מספר מוסד"
            type="number"
            required
            class="small-txt-field"
          />
        </div>
        <div class="side-by">
          <input
            v-model="form.contactName"
            placeholder="שם מלא של איש הקשר"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.contactPhone"
            placeholder="טלפון איש הקשר"
            type="tel"
            required
            class="small-txt-field"
          />

          <input
            v-model="form.contactMail"
            placeholder="מייל איש הקשר"
            type="email"
            required
            pattern="^[\w.-]+@[\w]+[\w-]*(?:\.[\w-]+)*(?:\.[\w-]{2,})+$"
            class="small-txt-field"
          />
        </div>
        <p class="small-form-head">כתובת למשלוח</p>

        <div class="side-by">
          <input
            v-model="form.city"
            placeholder="עיר"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.street"
            placeholder="רחוב"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.locationNum"
            placeholder="מספר"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.zip"
            placeholder="מיקוד"
            type="number"
            required
            class="small-txt-field"
          />
        </div>

        <div class="side-by">
          <select
            @change="updatePaymentMethod"
            class="small-txt-field"
            v-model="form.paymentMethod"
            required
          >
            <option
              value=""
              selected
              disabled
              hidden
            >צורת תשלום</option>
            <option value="credit">כרטיס אשראי</option>
            <option value="bank">העברה בנקאית</option>
            <option value="check">צ'ק לפקודת דם המכבים</option>
          </select>

          <label class="checkbox-input">
            <input
              v-model="form.delivery"
              class="bgl-checkbox"
              type="checkbox"
              required
            />
            <span class="checkbox">✔</span>
            מאשר שליח עד הארגון (תוספת 50 ש״ח)
          </label>
        </div>
        <div class="side-by">
          <input
            v-model="form.formName"
            placeholder="שם ממלא טופס ההזמנה"
            type="text"
            required
            class="small-txt-field"
          />
          <label class="checkbox-input">
            <input
              v-model="form.approval"
              class="bgl-checkbox"
              type="checkbox"
            />
            <span class="checkbox">✔</span>
            אני מאשר/ת את פרטי הזמנה זו.
          </label>

        </div>
        <textarea
          v-model="form.message"
          class="small-txt-field"
          placeholder="הערות נוספות שתרצו לרשום לנו?"
        ></textarea>
        <label class="checkbox-input logo-use">
          <input
            v-model="form.logoUse"
            class="bgl-checkbox"
            type="checkbox"
          />
          <span class="checkbox">✔</span>
          האם אתם מאפשרים שימוש בלוגו שלכם לפרסום השתתפותכם במיזם?
        </label>
        <div class="school-end">
          <p>
            <span>{{ form.flowerNum }}</span>
            סיכות
          </p>
          <p>
            מחיר כולל משלוח ₪<span>{{ totalPrice }}</span>
          </p>
        </div>

        <input
          class="btn cont"
          type="submit"
          :value="actionText ? actionText : $l('Submit')"
        />
      </form>
      <iframe
        class="iframe-payment"
        v-if="paymentURL && formStatus === 'payment'"
        :src="paymentURL"
      ></iframe>
      <div
        v-if="formStatus == 'fail'"
        class="form-fail"
      >
        <p>{{ $l("error") }}</p>
      </div>

      <div
        v-if="formStatus == 'success'"
        class="form-success"
      >
        <p class="red-txt">תודה שבחרתם לקחת חלק במיזם.</p>
        <div class="end-txt">
          <p class="txt20">
            הזמנתכם התקבלה ותישלח אליכם בימים הקרובים. (ניתן להוריד מעמוד זה
            מסמכים רלוונטים להנהלת חשבונות בקישורים מטה) לכל שאלה אנחנו זמינים
            במייל
            <a
              class="small-link"
              href="mailto:info@redeverlasting.com"
            >info@redeverlasting.com</a>
          </p>
        </div>
      </div>
    </div>
    <p
      v-if="formStatus != 'success'"
      class="end-txt"
    >
      תודה שבחרתם לקח חלק במיזם, (ניתן להוריד מעמוד זה מסמכים רלוונטים להנהלת
      חשבונות בקישורים מטה) לכל שאלה אנחנו זמינים במייל
      <a href="mailto:info@redeverlasting.com">info@redeverlasting.com</a>
    </p>
    <div class="download-docs">
      <a
        href="/docs/Confirmation-of-account-ownership-2024.pdf"
        download
      >אישור בעלות חשבון 2024</a>
      <a
        href="/docs/Proper-management-2024.pdf"
        download
      >ניהול תקין 2024</a>
      <a
        href="/docs/Tax-deduction-+-book-management-2024.pdf"
        download
      >
        ניכוי מס+ניהול ספרים 2024</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      actionText: "שליחה",
      price: 5,
      formStatus: "",
      form: {
        name: "",
        id: "",
        contactName: "",
        contactPhone: "",
        contactMail: "",
        flowerNum: 0,
        city: "",
        street: "",
        locationNum: "",
        zip: "",
        paymentMethod: "",
        delivery: false,
        formName: "",
        approval: "",
        message: "",
        totalPrice: "",
        logoUse: "",
        emailTo: "mickey@redeverlasting.com"
      },
    };
  },
  computed: {
    totalPrice() {
      let total = this.price * this.form.flowerNum;
      total += 50;
      return total;
    },
  },
  methods: {
    updatePaymentMethod() {
      if (this.form.paymentMethod === "credit") this.actionText = "המשך לתשלום";
      else this.actionText = "שליחה";
    },
    getPaymentURL() {
      // axios
    },
    async sendForm() {
      if (this.actionText === "שולח") return;
      try {
        this.actionText = "שולח";
        this.form.totalPrice = this.totalPrice;
        await this.$db.collection("schools").post(this.form);
        this.actionText = "נשלח";
        if (this.form.paymentMethod === "credit") {
          await this.getPaymentURL();
          this.formStatus = "payment";
        } else this.formStatus = "success";
      } catch (err) {
        this.actionText = "שליחה";
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
};
</script>

<style scoped>
.small-form-head {
  margin: 10px;
}

.school-end {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

.school-end p {
  margin: 0 0 5px 0;
}

.school-end span {
  font-weight: 600;
}

.plastic-wrap {
  text-align: center;
  border: 1px solid #bbc3d7;
  padding: 10px;
  margin: 0 10px 20px;
  width: 100%;
}

.plastic-wrap img {
  max-height: 200px;
}

.plastic-wrap .small-txt-field {
  margin: 0;
  max-width: 300px;
}

.plastic-wrap p {
  margin: 0 0 10px 0;
}

.plastic-wrap .txt24 {
  margin-top: -20px;
}

.checkbox-input {
  position: relative;
  padding-right: 40px;
  cursor: pointer;
  transition: all ease 200ms;
  min-width: 50%;
}

.checkbox-input .checkbox {
  width: 16px;
  height: 16px;
  background: rgb(255, 255, 255);
  border: #1d3779 1px solid;
  border-radius: 2px;
  position: absolute;
  top: 5px;
  right: 15px;
  color: transparent;
}

.checkbox-input .bgl-checkbox:checked+.checkbox {
  background: #1d3779;
  color: white;
  font-size: 12px;
  text-align: center;
}

.small-txt-field {
  background: #f1f1f1;
  margin: 0 10px;
  font-family: inherit;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border: none;
  resize: vertical;
}

.small-txt-field:focus-visible {
  outline: #1d3779 1px solid;
}

.form form {
  max-width: 760px;
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto;
  width: 90%;
}

.side-by {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.small-form-txt {
  margin: -20px 10px 20px;
  font-size: 14px;
}

.btn.cont {
  margin: 10px auto;
}

.main {
  min-height: calc(100vh - 130px);
}

.checkbox-input {
  padding-top: 3px;
}

.logo-use {
  margin: 1rem auto;
}

@media screen and (max-width: 991px) {
  .plastic-wrap {
    margin: 0 0px 20px;
  }

  .side-by {
    display: block;
    margin-bottom: 0px;
  }

  .small-txt-field {
    margin: 0 0px 10px;
  }

  .checkbox-input {
    margin: 5px -10px 20px;
    display: block;
  }
}
</style>
