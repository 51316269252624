
<template >
  <div class="main">
    <div class="big-white-box move-top">
      <h1 class="top-head">סיכום הזמנה</h1>
      <div class="sum-wrap">
        <div class="sum-row">
          <p>פרח דם המכבים</p>
          <p>12 סיכות</p>
        </div>
        <div class="sum-row">
          <p>דמוי פרח דם המכבים</p>
          <p>12 סיכות</p>
        </div>
        <div class="sum-row">
          <p>תרומה חופשית</p>
          <p>₪200</p>
        </div>
        <div class="sum-line"></div>
        <div class="sum-row bold">
          <p>סה״כ סיכות</p>
          <p>24 סיכות</p>
        </div>
        <div class="sum-row bold">
          <p>מחיר סופיר</p>
          <p>₪300</p>
        </div>
      </div>
    </div>
    <div class="sum-gray">
      <h3 class="top-head">תודה על תרומתכם</h3>

      <img src="../assets/about.jpg" alt="דם המכבים" />
    </div>
  </div>
</template>

      <script>
export default {};
</script>