<template>
<div>
  <DataView
    title="הזמנות בתי ספר"
    :allowNew="false"
    collectionID="schools"
  ></DataView>
</div>
</template>

<script>
import DataView from "./components/dataView.vue";
export default {
  components: {
    DataView,
  },
};
</script>
