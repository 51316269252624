<template>
  <canvas ref="chart"></canvas>
</template>

<script>
export default {
  name: "Charts",
  props: {
    type: String,
    options: { type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      chart: null,
      defaultOptions: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            backgroundColor(context) {
              return context.dataset.borderColor;
            },
            borderRadius: 100,
            color: "white",
            font: {
              weight: "bold",
            },
            textAlign: "center",
            formatter(value) {
              return value;
            },
          },
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    data() {
      const { data } = this;
      this.chart.data = data;
      this.chart.update();
    },
  },
  methods: {
    init() {
      const ctx = this.$refs.chart;
      const { Chart, ChartDataLabels } = window;
      Chart.register(ChartDataLabels);
      const { type, data, options } = this;
      this.chart = new Chart(ctx, {
        type,
        data,
        options: { ...this.defaultOptions, ...options },
      });
    },
  },
};
</script>

<!-- <script src="https://cdn.jsdelivr.net/npm/chart.js"></script> -->
