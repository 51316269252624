var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-card side-info"},[_c('div',{staticClass:"dashboard-col"},[_c('p',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"card-num"},[_vm._v(_vm._s(_vm.suffix)+_vm._s(_vm.total.toLocaleString()))]),_c('div',{staticClass:"piechart"},_vm._l((_vm.value),function(item,i){return _c('div',{key:i,staticClass:"pie animate",style:({
          '--p': _vm.cumulative(_vm.value.length - i - 1),
          '--c':
            _vm.activeItem === item.title
              ? _vm.colors[_vm.value.length - i - 1]
              : _vm.colors[_vm.value.length - i - 1],
        })})}),0)]),_c('div',{staticClass:"dashboard-col"},_vm._l((_vm.value),function(item,i){return _c('div',{key:'label' + i,staticClass:"dashboard-card-info-item"},[_c('div',{staticClass:"dashboard-card-info-top"},[_c('div',{staticClass:"dashboard-info-dot"}),_c('p',{staticClass:"dashboard-info-num"},[_vm._v(" "+_vm._s(_vm.suffix)+_vm._s(item.value.toLocaleString())+" "),_c('span',{staticClass:"dashboard-pill"},[_vm._v(" "+_vm._s(Math.round(_vm.relative(item.value)))+"% ")])])]),_c('p',{staticClass:"card-gray-txt"},[_vm._v(_vm._s(item.title))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }