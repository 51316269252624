<template>
  <div>
    <dataView
      title="הזמנות"
      collectionID="ordersNextYear"
      :dependant="['locations']"
      :allowNew="false"
    ></dataView>
  </div>
</template>

<script>
import dataView from "./components/dataView.vue";
export default {
  components: {
    dataView,
  },
  data() {
    return {
      orders: [],
      columns: [],
      isLoading: true,
    };
  },
  mounted() {
    this.fetchData();
    this.fetchColumns();
  },
  methods: {
    async fetchData() {
      const { data } = await this.$db.collection("ordersNextYear").get();
      this.orders = data;
      this.loading = false;
    },
    async fetchColumns() {
      const { data } = await this.$db.schema("ordersNextYear").get();
      this.columns = data.map((d) => ({ ...d, label: d.name, field: d.slug }));
    },
  },
};
</script>
