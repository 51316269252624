
      <template >
  <div class="main">
    <div v-if="isheb">
      <div class="story-top">
        <div class="big-white-box">
          <h1 class="top-head">{{ pages.title }}</h1>
          <div v-html="pages.subtitle" class="txt20"></div>
        </div>

        <img src="../assets/story-back.jpg" alt="story" />
      </div>
      <div class="about-wrap">
        <div class="small-side-imgs">
          <img class="stamp1" src="../assets/stamp1.png" alt="stamp" />
          <img class="stamp2" src="../assets/stamp2.png" alt="stamp" />
        </div>
        <div class="about-txt">
          <div class="txt20" v-html="pages.text"></div>
          <div class="end-pics">
            <img src="../assets/home1.jpg" alt="דם המכבים" />
            <img src="../assets/pick.jpg" alt="דם המכבים" />
            <img src="../assets/story-small-1.jpg" alt="דם המכבים" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="story-top">
        <div class="big-white-box">
          <h1 class="top-head">{{ pages.titleEnglish }}</h1>
          <div v-html="pages.subTitleEng" class="txt20"></div>
        </div>

        <img src="../assets/story-back.jpg" alt="story" />
      </div>
      <div class="about-wrap">
        <div class="small-side-imgs">
          <img class="stamp1" src="../assets/stamp1.png" alt="stamp" />
          <img class="stamp2" src="../assets/stamp2.png" alt="stamp" />
        </div>
        <div class="about-txt">
          <div v-html="pages.textEng" class="txt20"></div>
          <div class="end-pics">
            <img src="../assets/home1.jpg" alt="דם המכבים" />
            <img src="../assets/pick.jpg" alt="דם המכבים" />
            <img src="../assets/story-small-1.jpg" alt="דם המכבים" />
          </div>
        </div>
      </div>
    </div>
    <contact></contact>
  </div>
</template>


<script>
import Contact from "@/components/contact.vue";
export default {
  components: {
    Contact,
  },
  data: () => ({
    pages: "",
  }),
  computed: {
    isheb() {
      return this.$lang.locale === "he";
    },
  },
  async beforeMount() {
    let { data: pages } = await this.$db
      .collection("pages")
      .item("622f3518c1c4cd6838f18034")
      .get();
    this.pages = pages;
  },
};
</script>