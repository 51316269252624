<template>
  <div>
    <b-field :label="field.name">
      <b-input @input="emitValue" v-model="val"></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "PlainText",
  props: { field: Object, value: String },
  data() {
    return {
      val: "",
    };
  },
  watch: {
    value(value) {
      if (value !== this.val) this.val = value;
    },
  },
  methods: {
    emitValue() {
      this.$emit("input", this.val);
    },
  },
};
</script>
