<template>
  <div class="main">
    <popup v-model="imgGallery">
      <img :src="imgGallery" />
    </popup>
    <div class="gallery-back">
      <h1 class="top-head">{{ $l("Gallery") }}</h1>
      <div class="gallery-wrap" v-if="gallery.length">
        <!-- {{ gallery[0] }} -->
        <div
          @click="imgGallery = pic.imageURL"
          v-for="pic in gallery[0].gallery"
          :key="pic._id"
          class="pic-wrap"
        >
          <img :src="pic.imageURL" />
        </div>
      </div>
    </div>
    <div id="news" class="news-sec">
      <h2 class="top-head">{{ $l("news") }}</h2>
      <div class="news-wrap">
        <a
          v-for="article in news"
          :key="article._id"
          class="news-link"
          :href="article.link"
          target="_blank"
        >
          <p>{{ article.title }}</p>
          <img class="news-logo" :src="article.logo.imageURL" alt="דם המכבים" />
        </a>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "@/components/contact.vue";
export default {
  components: {
    Contact,
  },
  data: () => ({
    news: [],
    gallery: [],
    imgGallery: "",
  }),
  async beforeMount() {
    let { data: gallery } = await this.$db.collection("gallery").get();
    this.gallery = gallery;
    let { data: news } = await this.$db
      .collection("news")
      .query("english", "!=", "true")
      .get();
    this.news = news;
  },
};
</script>
