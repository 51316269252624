<template>
  <div></div>
</template>

<script>
export default {
  name: "ImageRef",
  props: { field: Object, value: String },
  data() {
    return {
      val: this.value,
    };
  },
};
</script>
