<template>
  <div>
    <b-navbar class="bgl-nav">
      <template #brand>
        <b-navbar-item v-if="location" tag="h1" style="font-size: 21px">
          הזמנות - {{ location.address }}, {{ location.name }}
        </b-navbar-item>

        <b-navbar-item tag="span" style="opacity: 0.6; font-size: 13px"
          >{{ orders.length.toLocaleString() }} הזמנות</b-navbar-item
        >
        <!-- <b-navbar-item v-for="filter in filters" :key="filter._id">
          <div
            class="filter-tab"
            :class="{ 'active-tab': activeFilter._id === filter._id }"
            @click="setAtiveFilter(filter)"
          >
            <div v-if="filter.default" class="default-mark"></div>
            {{ filter.name }}
            <button
              v-if="filter._id"
              class="settings-filter-btn"
              @click.stop="editFilter(filter)"
            >
              <span class="icon-font">settings</span>
            </button>
          </div>
        </b-navbar-item>
        <b-navbar-item>
          <div @click="editFilter()" class="new-filter">+</div>
        </b-navbar-item> -->
      </template>
      <template #end>
        <!-- <b-navbar-item
          ><a
            class="download-btn"
            :href="`/.netlify/functions/downloadData?collection=${collectionID}&filter=${JSON.stringify(
              activeFilter.filterParams || '[]'
            )}`"
            :download="`${title}-${activeFilter.name}.xlsx`">
            <span class="icon-font">download</span>
            הורדה
          </a></b-navbar-item
        > -->
        <b-navbar-item tag="div">
          <b-input @input="search" placeholder="חיפוש" rounded></b-input>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-table
      class="table-location"
      mobile-mode="null"
      sticky-header
      :data="orders"
      :loading="isLoading"
    >
      <b-table-column
        v-for="(column, id) in projectOn"
        :key="id"
        :field="column.field"
        :label="column.label"
        v-slot="props"
      >
        <div
          v-if="column.field === 'delivered'"
          style="direction: ltr; text-align: right"
        >
          <b-switch
            @input="updateDelivered(props.row)"
            v-model="props.row[column.field]"
          >
          </b-switch>
        </div>
        <div v-else>
          {{ props.row[column.field] }}
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import TextSearch from "./textSearch";
export default {
  name: "location",
  data() {
    return {
      isLoading: true,
      orders: [],
      checkedRows: [],
      location: null,
      projectOn: [
        { label: "שם פרטי", field: "firstName" },
        { label: "שם משפחה", field: "lastName" },
        { label: "מספר פלסטיק", field: "numPlastic" },
        { label: "מספר אמיתי", field: "numReal" },
        { label: "טלפון", field: "phone" },
        { label: "אימייל", field: "email" },
        { label: "נמסר ללקוח", field: "delivered" },
      ],
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    console.log(id);
    const { data: location } = await this.$db
      .collection("locations")
      .item(id)
      .get();
    this.location = location;
    this.fetchData();
    // this.listen();
    // this.setAllLocationLinks();
  },
  methods: {
    search(searchText) {
      bounce(async () => {
        const search = new TextSearch({
          schema: this.schema,
          fields: this.schema,
          searchText,
        });
        if (searchText) this.activeFilter = this.filters[0];
        else
          this.activeFilter =
            this.filters.find((f) => f.default) || this.filters[0];
        const { data, headers } = await this.$db
          .collection(this.collectionID)
          .find({...search.toQueryObj(), nextyearOrder: {$ne:true}})
          .get();
        this.totalItems = parseInt(headers["item-count"]);
        this.orders = data;
      });
    },
    // async setAllLocationLinks() {
    //   const { data: dists } = await this.$db
    //     .collection("distribution")
    //     .projectOn("spots")
    //     // .item(locationID)
    //     // .collection("spots")
    //     // .item(spotID)
    //     .get();
    //   for (let dist of dists) {
    //     for (let spot of dist.spots) {
    //       const link = `https://damhamaccabim.org/location?l=${dist._id}&s=${spot._id}`;
    //       if (!spot.link) {
    //         await this.$db
    //           .collection("distribution")
    //           .item(dist._id)
    //           .collection("spots")
    //           .item(spot._id)
    //           .put({ link });
    //         console.log(link);
    //       }
    //     }
    //   }
    // },
    listen() {
      const { location } = this;
      this.$db
        .collection("ordersNextYear")
        .find({
          "statusNext._id": "c91lf1q23akg00alnafg",
          "location.itemRefID": location._id,
          nextyearOrder: {$ne:true}
        })
        .everything()
        .listen(({ data }) => {
          let { item: order } = JSON.parse(data);
          if (order.location?.[0].itemRefID == location._id) {
            let index = this.orders.findIndex((o) => o._id === order._id);
            if (index > -1) this.orders.splice(index, 1, order);
            else this.orders.push(order);
          }
        });
    },

    async fetchData() {
      console.log(this.location._id);
      const { data } = await this.$db
        .collection("ordersNextYear")
        .query("location.itemRefID", "=", this.location._id)
        .find({
          "statusNext._id": "c91lf1q23akg00alnafg",
          "location.itemRefID": this.location._id,
          nextyearOrder: {$ne:true}
        })
        .projectOn(
          this.projectOn.map((f) => f.field).join(",") + ",distribution"
        )
        .get();
      this.orders = data;
      console.log(data);
      this.isLoading = false;
    },
    async updateDelivered(item) {
      const { delivered, _id } = item;
      await this.$db.collection("ordersNextYear").item(_id).put({ delivered });
    },
  },
};
let timer;
function bounce(func, timeout = 300) {
  clearTimeout(timer);
  timer = setTimeout(() => {
    func();
  }, timeout);
}
</script>

<style scoped>
.table-button {
  height: 35px;
  line-height: 35px;
  padding: 0px 30px;
  background: #1d3679;
  color: white;
  border-radius: 5px;
}
/* .b-table .table-wrapper.has-sticky-header {
  height: auto !important;
} */
.page-wrap {
  overflow: auto;
  max-width: 1600px;
  width: 95%;
  margin: auto;
  box-shadow: 5px 5px 15px #bfbfbf;
  padding: 10px 20px;
}
</style>
