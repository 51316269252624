<template>
  <div class="success">
    <fail-animation />
    <div class="payment-suc">
      <p dir="rtl">עסקה בוטלה</p>
    </div>
  </div>
</template>

<script>
import FailAnimation from "../components/failAnimation.vue";
export default {
  components: { FailAnimation },
};
</script>

<style scoped>
.payment-suc p {
  margin-bottom: 10px;
  line-height: 1;
  font-size: 20px;
}
.payment-suc {
  font-size: 20px;
}
.success {
  text-align: center;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
