import terms from '../views/Terms.vue';
import families from '../views/Families.vue';
import nonprofits from '../views/Nonprofits.vue';
import gallery from '../views/Gallery.vue';
import Donations from '../views/Donations.vue';
import summary from '../views/Summary.vue';
import story from '../views/Story.vue';
import about from '../views/About.vue';
import order from '../views/Order.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Success from '../views/Success.vue';
import Cancel from '../views/Cancel.vue';
import Data from '../views/Data.vue';
import Nextyear from '../views/Nextyear.vue';
import Companies from '../views/Companies.vue';
import Schools from '../views/Schools.vue';
import Whatsapp from '../views/Whatsapp.vue';
import Onlocation from '../views/Onlocation.vue';
import PickPickupPoint from '../views/PickPickupPoint.vue';
import LocationReport from '../views/admin/LocationReport.vue';
import Email from '../views/admin/Email.vue';
import NonprofitList from '../views/admin/NonprofitList.vue';
import Families from '../views/admin/Families.vue';
import Coupons from '../views/admin/Coupons.vue';
import CompanyOrders from '../views/admin/Companies.vue';
import SchoolsOrders from '../views/admin/Schools.vue';
import Locations from '../views/admin/Locations.vue';
import Dashboard from '../views/admin/Dashboard.vue';
import Orders from '../views/admin/Orders.vue';
import contact from '../views/ContactPage.vue';
import Points from '../views/Points.vue';
import Accessibility from '../views/Accessibility.vue';

// import Donate from '../views/Donate.vue';

Dashboard;
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/admin/orders',
        name: 'Orders',
        component: Orders,
      },
      {
        path: '/admin/locations',
        name: 'Location',
        component: Locations,
      },
      {
        path: '/admin/companies',
        name: 'Companies',
        component: CompanyOrders,
      },
      {
        path: '/admin/schools',
        name: 'Schools',
        component: SchoolsOrders,
      },
      {
        path: '/admin/whatsapp',
        name: 'Whatsapp',
        component: Whatsapp,
      },
      {
        path: '/admin/coupons',
        name: 'Coupons',
        component: Coupons,
      },
      {
        path: '/admin/nonprofits',
        name: 'Nonprofits',
        component: NonprofitList,
      },
      {
        path: '/admin/families',
        name: 'Families',
        component: Families,
      },
      {
        path: '/admin/email',
        name: 'Email',
        component: Email,
      },
      {
        path: '/admin/location/:id',
        name: 'SingleLocation',
        component: LocationReport,
      },
    ],
  },
  {
    path: '/prev-year',
    name: 'Order',
    component: order,
  },
  {
    path: '/order',
    name: 'Order',
    component: Nextyear,
  },
  {
    path: '/about',
    name: 'About',
    component: about,
  },

  {
    path: '/cancel',
    name: 'Cancel',
    component: Cancel,
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/story',
    name: 'Story',
    component: story,
  },
  {
    path: '/summary',
    name: 'Summary',
    component: summary,
  },
  {
    path: '/donations',
    name: 'Donations',
    component: Donations,
  },
  {
    path: '/donate',
    name: 'Donate',
    component: Home,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: gallery,
  },
  {
    path: '/nonprofits',
    name: 'Nonprofits',
    component: nonprofits,
  },
  {
    path: '/families',
    name: 'Families',
    component: families,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: terms,
  },

  {
    path: '/data',
    name: 'Data',
    component: Data,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
  },
  {
    path: '/schools',
    name: 'Schools',
    component: Schools,
  },
  {
    path: '/onlocation',
    name: 'Onlocation',
    component: Onlocation,
  },
  {
    path: '/pick-pickup-point',
    name: 'PickPickupPoint',
    component: PickPickupPoint,
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
  },
  {
    path: '/points',
    name: 'Points',
    component: Points,
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: Accessibility,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        behavior: 'smooth',
        el: to.hash,
        offset: { top: 0, left: 0 },
      };
    }
    return { top: 0, left: 0 };
  },
});

export default router;
