<template>
  <div class="side-details">
    <div class="selects">
      <div
        @click="selected = email"
        class="list-item"
        :class="{ selectedEmail: selected === email }"
        v-for="email in emails"
        :key="email._id"
      >{{ email.title }}</div>
    </div>
    <div
      class="email-detail"
      v-if="selected"
    >
      <div v-html="selected.html"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "email",
  data() {
    return {
      emails: [],
      selected: {}
    };
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { data: emails } = await this.$db
        .collection("automatedEmails")
        .get();
      this.emails = emails;
    },
  },
};
</script>

<style scoped>
.list-item {
  padding: 10px 15px;
  /* border-bottom: 1px solid gray; */
  transition: all 200ms ease;
  cursor: pointer;
  border-radius: 30px;
  margin: 10px 0;
}

.list-item:hover {
  background: #f3f3f3;
}

.list-item:active {
  filter: brightness(90%);
}

.selectedEmail,
.selectedEmail:hover {
  background: #20377b;
  color: white;
}

.side-details {
  display: flex;
}

.selects {
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.2);
  border-radius: 15px;
  padding: 1rem 2rem;
  margin: 2rem;
  background: white;
  overflow: auto;
}

.email-detail {
  margin: 2rem;
  background: rgb(211, 211, 239);
  width: calc(100% - 400px);
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.2);
  border-radius: 15px;
  padding: 0rem 2rem;
  margin: 2rem 0 2rem 2rem;
  background: white;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-detail>* {
  max-height: 100%;
  word-break: break-all;
}

@media screen and (max-width: 767px) {




  .side-details {
    display: flex;
    flex-direction: column;
  }

  .selects {
    padding: 0;
    margin: 0rem;
    height: 60px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 0;
  }

  .selects .list-item {
    white-space: nowrap;
    padding: 6px;
    height: 40px;
    display: inline-block;
  }

  .email-detail {
    margin: 0rem;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 0rem 2rem;
    margin: 1rem 0 0 0;
    background: transparent;
  }
}
</style>
