<template>
  <div v-if="dashboard" class="dashboard-wrap">
    <div class="dashboard-row">
      <div class="dashboard-card">
        <ScrollingNumbers
          class="card-num"
          :value="dashboard.averageDealSize"
          prefix="₪"
        />
        <p class="card-gray-txt">ממוצע לעסקה</p>
      </div>
      <div class="dashboard-card">
        <ScrollingNumbers class="card-num" :value="dashboard.averageProduct" />
        <p class="card-gray-txt">ממוצע סיכות לעסקה</p>
      </div>
      <div class="dashboard-card">
        <ScrollingNumbers
          class="card-num"
          :value="dashboard.percentMakingDonations"
          suffix="%"
        />
        <p class="card-gray-txt">אחוז התורמים</p>
      </div>
      <div class="dashboard-card">
        <ScrollingNumbers
          class="card-num"
          :value="dashboard.averageDonationAmount"
          prefix="₪"
        />
        <p class="card-gray-txt">גודל תרומה ממוצעת</p>
      </div>
    </div>
    <div class="dashboard-row">
      <pie-card
        :title="'עסקאות'"
        :total="dashboard.totalDeals"
        :value="dashboard.dealClassify"
      ></pie-card>
      <!-- <div class="dashboard-card side-info">
        <div class="dashboard-col">
          <p class="card-title">עסקאות</p>
          <p class="card-num">{{ dashboard.totalDeals }}</p>
          <div class="piechart">
            <div class="pie animate" style="--p: 100; --c: #20377b"></div>
            <div class="pie animate" style="--p: 60; --c: #fb5858"></div>
            <div class="pie animate" style="--p: 40; --c: #8aa0e0"></div>
            <div class="pie animate" style="--p: 20; --c: #f89898"></div>
            <div class="pie animate" style="--p: 10; --c: #ddb3b3"></div>
            <div class="pie animate" style="--p: 5; --c: #6c84cd"></div>
          </div>
        </div>
        <div class="dashboard-col">
          <div class="dashboard-card-info-item">
            <div class="dashboard-card-info-top">
              <div class="dashboard-info-dot"></div>
              <p class="dashboard-info-num">
                123
                <span class="dashboard-pill"> 23% </span>
              </p>
            </div>
            <p class="card-gray-txt">הזמנות פרח</p>
          </div>
          <div class="dashboard-card-info-item">
            <div class="dashboard-card-info-top">
              <div class="dashboard-info-dot"></div>
              <p class="dashboard-info-num">
                123
                <span class="dashboard-pill"> 23% </span>
              </p>
            </div>
            <p class="card-gray-txt">הזמנות דמוי</p>
          </div>
          <div class="dashboard-card-info-item">
            <div class="dashboard-card-info-top">
              <div class="dashboard-info-dot"></div>
              <p class="dashboard-info-num">
                123
                <span class="dashboard-pill"> 23% </span>
              </p>
            </div>
            <p class="card-gray-txt">משנה שעברה (2022)</p>
          </div>
          <div class="dashboard-card-info-item">
            <div class="dashboard-card-info-top">
              <div class="dashboard-info-dot"></div>
              <p class="dashboard-info-num">
                123

                <span class="dashboard-pill"> 23% </span>
              </p>
            </div>
            <p class="card-gray-txt">בתשלום</p>
          </div>
          <div class="dashboard-card-info-item">
            <div class="dashboard-card-info-top">
              <div class="dashboard-info-dot"></div>
              <p class="dashboard-info-num">
                123

                <span class="dashboard-pill"> 23% </span>
              </p>
            </div>
            <p class="card-gray-txt">הזמנות ״שכול״</p>
          </div>
          <div class="dashboard-card-info-item">
            <div class="dashboard-card-info-top">
              <div class="dashboard-info-dot"></div>
              <p class="dashboard-info-num">
                123

                <span class="dashboard-pill"> 23% </span>
              </p>
            </div>
            <p class="card-gray-txt">הוסיפו משלוח בתוספת 50 ש״ח</p>
          </div>
        </div>
      </div> -->
      <pie-card
        :title="'סיכות'"
        :total="dashboard.totalPins"
        :value="dashboard.pins"
      ></pie-card>

      <pie-card
        suffix="₪"
        :title="'כספים'"
        :total="dashboard.totalRevenue"
        :value="dashboard.financialDist"
      ></pie-card>
    </div>
    <div class="dashboard-row">
      <div class="dashboard-card">
        <ScrollingNumbers
          class="card-num"
          :value="dashboard.percentOfDam"
          suffix="%"
        />
        <p class="card-gray-txt">מהעסקאות שבוחר בדם המכבים</p>
      </div>
      <div class="dashboard-card">
        <ScrollingNumbers
          class="card-num"
          :value="dashboard.percentDedicated"
          suffix="%"
        />
        <p class="card-gray-txt">
          מהעסקאות שמקדישות את הרכישה לנופל (בטקסט חופשי)
        </p>
      </div>
      <div class="dashboard-card">
        <ScrollingNumbers
          class="card-num"
          :value="dashboard.distributeEqually"
          suffix="%"
        />
        <p class="card-gray-txt">מהעסקאות שבוחר חלוקה שווה</p>
      </div>
    </div>

    <div class="dashboard-row">
      <div class="dashboard-card map-wrap">
        <div>
          <p class="card-title">נקודות איסוף</p>

          <div class="dashboard-col">
            <div
              class="dashboard-card-info-item"
              v-for="region of dashboard.byRegion"
              :key="region._id"
            >
              <div class="dashboard-card-info-top">
                <div class="dashboard-info-dot"></div>
                <p class="dashboard-info-num">
                  {{ region.value }}
                  <span class="dashboard-pill">
                    {{
                      Math.round((region.value / dashboard.totalDeals) * 100)
                    }}%
                  </span>
                </p>
              </div>
              <p class="card-gray-txt">{{ region.title }}</p>
            </div>
          </div>
        </div>
        <div class="dashboard-col">
          <div class="map">
            <!-- <div class="map-dot" :style="{transform:`scale(${region.value})`}"></div> -->
            <div
              v-for="regionDot in dashboard.byRegion.filter((d) => d.location)"
              :key="'location' + regionDot._id"
              class="map-dot"
              :title="regionDot.title + ' | ' + regionDot.value"
              :style="{
                transform: `scale(${percentRegion(regionDot.value) * 20})`,
                right: regionDot.location.right,
                top: regionDot.location.top,
              }"
            ></div>
            <img class="map-bg" src="../../assets/map-israel.svg" />
          </div>
        </div>
      </div>
      <div class="dashboard-card graph-wrap">
        <div class="graph-top">
          <p class="card-title">תנועת קונים</p>
          <div class="graph-btns">
            <button>‹</button>
            <p>מרץ</p>
            <!-- <button>›</button> -->
          </div>
          <div class="graph-select">
            <button
              @click="trendBy = 'newSales'"
              :class="{ selected: trendBy === 'newSales' }"
            >
              מכירות
            </button>
            <button
              @click="trendBy = 'totalSales'"
              :class="{ selected: trendBy === 'totalSales' }"
            >
              מצטבר
            </button>
          </div>
        </div>
        <div class="graph">
          <charts type="line" :data="trend" :options="{}"></charts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Charts from "./VanillaCharts/Charts.vue";
import PieCard from "./VanillaCharts/PieCard.vue";
import ScrollingNumbers from "./VanillaCharts/ScrollingNumbers.vue";

export default {
  data() {
    return {
      trendData: [],
      trendBy: "newSales",
      dashboard: null,
    };
  },
  mounted() {
    this.fetchData();
    this.fetchTrend();
  },
  computed: {
    trend() {
      const trend = {
        labels: [""],
        datasets: [
          {
            label: "מספר רכישות",
            data: [0],
            borderWidth: 3,
            fill: false,
            borderColor: "#fb5858",
            tension: 0.3,
          },
        ],
      };
      const trendByDate = [];
      for (const point of this.trendData) {
        const p = {};
        p.day = new Date(point._createdDate).getDate();
        p.totalSales = point.totalSales || 0;
        p.newSales = point.newSales || 0;
        let existing = trendByDate.find((t) => t.day === p.day);
        if (existing) {
          existing.totalSales = p.totalSales;
          existing.newSales += p.newSales;
        } else trendByDate.push(p);
      }
      console.log(trendByDate);
      // var dateOptions = { day: "numeric", hour: "numeric", minute: "2-digit" };
      const tData = trendByDate.map((d) => d[this.trendBy] || 0).reverse();
      trend.datasets[0].data = tData;
      trend.labels = trendByDate.map((d) => d.day).reverse();
      return trend;
    },
  },
  methods: {
    percentRegion(val) {
      if (!this.dashboard) return 0;
      const filteredRegion = this.dashboard.byRegion.filter((d) => d.location);
      const sumRegion = filteredRegion.reduce(
        (accumulator, item) => accumulator + (item.value || 0),
        0
      );
      return val / sumRegion;
    },
    async fetchTrend() {
      const firstOfTheMonth = Math.round(new Date(`01-MAR-2023`) / 1000);
      const now = Math.round(new Date() / 1000);
      const { data } = await this.$db
        .collection("dashboard")
        .query("_createdDate", "<=", firstOfTheMonth)
        .query("_createdDate", "<=", now)
        .projectOn("newSales,totalSales")
        .get();
      this.trendData = data;
    },
    async fetchData() {
      const {
        data: [dashboard],
      } = await this.$db.collection("dashboard").everything().get();
      this.dashboard = dashboard;
    },
  },
  components: { ScrollingNumbers, PieCard, Charts },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@400;500;600;700&display=swap");

button.selected {
  background-color: #0d193f;
  color: white;
}

.dashboard-wrap {
  font-family: "Noto Sans Hebrew", sans-serif;
  padding: 2rem;
  overflow-y: auto;
}

.dashboard-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.dashboard-card {
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.2);
  border-radius: 15px;
  padding: 1rem 2rem;
  flex: 1 1;
  background: white;
  min-width: 240px;
}

.dashboard-card.side-info {
  min-width: 320px;
}

.card-num {
  font-size: 24px;
  font-weight: 700;
}

.card-num span {
  font-size: 20px;
}

.card-gray-txt {
  color: #b5b5c2;
}

.card-title {
  font-size: 20px;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 36px;
  --w: 150px;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.animate {
  animation: p 1s 0.5s both;
}

@keyframes p {
  from {
    --p: 0;
  }
}

.piechart {
  position: relative;
  height: 150px;
  width: 150px;
  margin-top: 20px;
}

.piechart > div {
  position: absolute;
}

.dashboard-card.side-info {
  display: flex;
  gap: 30px;
  /* justify-content: space-between; */
  align-items: flex-end;
}

.map-wrap {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  min-width: 460px;
}

.dashboard-card-info-item {
  margin-top: 10px;
}

.dashboard-card-info-top {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: -4px;
}

.dashboard-info-dot {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #20377b;
  flex-wrap: wrap;
}

.dashboard-col .dashboard-card-info-item:nth-child(1) .dashboard-info-dot {
  background: #20377b;
}

.dashboard-col .dashboard-card-info-item:nth-child(2) .dashboard-info-dot {
  background: #fb5858;
}

.dashboard-col .dashboard-card-info-item:nth-child(3) .dashboard-info-dot {
  background: #8aa0e0;
}

.dashboard-col .dashboard-card-info-item:nth-child(4) .dashboard-info-dot {
  background: #f89898;
}

.dashboard-col .dashboard-card-info-item:nth-child(5) .dashboard-info-dot {
  background: #ddb3b3;
}

.dashboard-col .dashboard-card-info-item:nth-child(6) .dashboard-info-dot {
  background: #6c84cd;
}

.dashboard-col .dashboard-card-info-item:nth-child(7) .dashboard-info-dot {
  background: #0d193f;
}

.dashboard-col .dashboard-card-info-item:nth-child(8) .dashboard-info-dot {
  background: #02050d;
}

.dashboard-col .dashboard-card-info-item:nth-child(1) .dashboard-pill {
  background: #20377b4d;
  color: #20377b;
}

.dashboard-col .dashboard-card-info-item:nth-child(2) .dashboard-pill {
  background: #fb58584d;
  color: #fb5858;
}

.dashboard-col .dashboard-card-info-item:nth-child(3) .dashboard-pill {
  background: #8aa0e04d;
  color: #8aa0e0;
}

.dashboard-col .dashboard-card-info-item:nth-child(4) .dashboard-pill {
  background: #f898984d;
  color: #f89898;
}

.dashboard-col .dashboard-card-info-item:nth-child(5) .dashboard-pill {
  background: #ddb3b34d;
  color: #ddb3b3;
}

.dashboard-col .dashboard-card-info-item:nth-child(6) .dashboard-pill {
  background: #6c84cd4d;
  color: #6c84cd;
}

.dashboard-col .dashboard-card-info-item:nth-child(7) .dashboard-pill {
  background: #0d193f4d;
  color: #0d193f;
}

.dashboard-col .dashboard-card-info-item:nth-child(8) .dashboard-pill {
  background: #02050d4d;
  color: #02050d;
}

.dashboard-info-num {
  font-size: 20px;
  font-weight: 700;
}

.dashboard-pill {
  font-size: 12px;
  color: #20377b;
  background: #20377b4d;
  border-radius: 5px;
  padding: 1px 3px;
}

.card-gray-txt {
  flex-basis: 100%;
}

.map {
  height: 100%;
  position: relative;
  width: 220px;
}

.map img {
  height: 100%;
}

.map-dot {
  position: absolute;
  border-radius: 100%;
  background: #20377b;
  height: 2%;
  aspect-ratio: 1;
  /* opacity: 0.7; */
}

.map-dot:nth-child(1) {
  background: #20377b;
}

.map-dot:nth-child(2) {
  background: #fb5858;
}

.map-dot:nth-child(3) {
  background: #8aa0e0;
}

.map-dot:nth-child(4) {
  background: #f89898;
}

.map-dot:nth-child(5) {
  background: #ddb3b3;
}

.map-dot:nth-child(6) {
  background: #6c84cd;
}

.map-dot:nth-child(7) {
  background: #0d193f;
}

.graph-wrap {
  flex-grow: 1.7;
}

.graph-top {
  display: flex;
  justify-content: space-between;
}

.graph-btns {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  align-items: center;
}

.graph-btns button {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 24px;
  width: 24px;
  transition: all 200ms ease;
  background: white;
}

.graph-btns button:hover {
  filter: brightness(90%);
}

.graph-btns button:active {
  filter: brightness(80%);
}

.graph-select {
  /* min-width: 50px; */
  /* background: #f4f6fa; */
  /* border-rsadius: 10px; */
  /* border: none; */
  /* font-family: inherit; */
  font-size: 12px;
}

.graph-select > * {
  margin-right: 5px;
  border-radius: 10px;
  padding: 2px 10px 3px;
}

.graph {
  height: 100%;
  margin-top: 20px;
  max-height: 470px;
}

.graph img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .pie {
    --b: 26px;
    --w: 100px;
  }

  .piechart {
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .dashboard-wrap {
    padding: 1rem;
  }

  .dashboard-row {
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .dashboard-card {
    min-width: 100%;
  }

  .dashboard-card.side-info {
    display: flex;
    justify-content: space-between;
  }

  .pie {
    --b: 26px;
    --w: 100px;
  }

  .piechart {
    height: 100px;
    width: 100px;
  }

  .map {
    width: 120px;
    height: 320px;
  }

  .card-num {
    font-size: 18px;
  }
}
</style>
