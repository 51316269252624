<template>
  <div class="success">
    <success-animation />
    <div class="payment-suc">
      <p dir="rtl" style="font-weight: 700">תודה רבה על תמיכתך!</p>
      <social-buttons :title="shareMessage" />
      <p>שתפו את ההנצחה שלכם</p>
    </div>
  </div>
</template>

<script>
import SocialButtons from "../components/SocialButtons.vue";
import SuccessAnimation from "../components/successAnimation.vue";
export default {
  layout: "empty",
  components: { SuccessAnimation, SocialButtons },
  mounted() {
    const { value } = this.$route.query;
    window.parent.postMessage("success", value);
    window.fbq("track", "Purchase", {
      value: parseFloat(value || 0),
      currency: "ILS",
    });
  },
  computed: {
    nameMemory() {
      return `${this.$route.query.dedication}` != "undefined"
        ? this.$route.query.dedication
        : "הנופלים";
    },
    shareMessage() {
      return `השנה אענוד את סיכת דם המכבים לזכר ${this.nameMemory}.
זיכרון שמאחד. זיכרון שמצמיח.
הצטרפו למיזם.`;
    },
  },
};
</script>

<style scoped>
.payment-suc p {
  margin-bottom: 10px;
  line-height: 1;
  font-size: 20px;
}
.payment-suc {
  font-size: 20px;
}
.success {
  text-align: center;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow {
  transform: rotate(230deg);
  height: 60px;
}
</style>
