<template>
  <div class="main">
    <div class="top-bg">
      <img
        class="don-bg"
        src="../assets/donations-back.jpg"
      />
    </div>
    <div class="big-white-box donation">
      <div class="donation-wrap">
        <h1 class="top-head  pb-1">
          {{ $l("homeTitle") }}
        </h1>
        <p class="txt24 center expresivi no-marg">
          {{ $l("homeOpen4") }}
        </p>
        <p class="center blue no-marg txt20"> {{ $l("donPin") }}</p>

        <!-- <div class="DonationsSmallTxt">
          <p>{{ $l("DonationsSmallTxt") }}</p>
          <router-link to="/order"> {{ $l("DonationsPress") }} </router-link>
        </div> -->
      </div>
      <p style="text-align: center; padding: 10px;">למוסד אישור מס הכנסה לעניין תרומות לפי סעיף 46 לפקודת מס הכנסה</p>

      <iframe
        class="donation-iframe"
        :src="link"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 0,
    };
  },
  computed: {
    link() {
      if (this.$lang.locale == "en")
        return "https://secure.cardcom.solutions/e/U0H7";
      else return "https://secure.cardcom.solutions/e/U0HH";
    },
  },
};
</script>
