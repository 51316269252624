<template>
  <div class="main">
    <div class="big-white-box move-top">
      <form
        class="order-form"
        @submit.prevent="submitOrder"
        v-if="orderStatus != 'success'"
      >
        <h3 class="order-small-head">בחירה/שינוי אזור נקודת איסוף</h3>
        <b> {{ order.firstName }} {{ order.lastName }} </b>
        <br />
        פלסטיק: {{ order.numPlastic || 0 }}
        <br />
        אמיתי: {{ order.numReal || 0 }}
        <a
          href="https://www.google.com/maps/d/u/0/viewer?mid=1WZIQyv5t_9wIp7IBNGIyZmYID91LT4xc"
          target="_blank"
          class="order-small-btn"
          >היעזרו במפה שלנו למציאת נקודת האיסוף הנוחה עבורכם</a
        >
        <multiselect
          v-model="selectedSpot"
          :options="locations"
          :multiple="false"
          group-values="spots"
          group-label="region"
          openDirection="bottom"
          :showLabels="false"
          :group-select="false"
          placeholder="הקלד/י שם עיר או יישוב"
          track-by="address"
          :customLabel="locationLabel"
          selectLabel
          deselectLabel
          @select="selectSpot"
        >
          <template #placeholder
            ><input
              placeholder="הקלד/י שם עיר או יישוב"
              style="width: 100%; background: transparent; border: none"
              type="text"
              required
          /></template>
          <template #singleLabel="props"
            ><input
              style="width: 100%; background: transparent; border: none"
              type="text"
              required
              :value="`${props.option.name} - ${props.option.address}`"
          /></template>
          <!--
            <template #option="props">
              <div class="option__desc">
                {{ props.option.$groupLabel }}
                <b class="option__title">{{ props.option.name }}</b>
                <span v-if="props.option.address" class="option__small"
                  >- {{ props.option.address }}</span
                >
                <p
                  style="font-size: smaller; opacity: 0.7"
                  v-if="
                    !!numReal &&
                    props.option.maxReal < props.option.numReal + numReal
                  "
                >
                  אין מספיק מלאי של פרח אמיתי בנקודה להזמנה זו
                </p>
                <p
                  style="font-size: smaller; opacity: 0.7"
                  v-if="
                    !!numPlastic &&
                    props.option.maxPlastic <
                      props.option.numPlastic + numPlastic
                  "
                >
                  אין מספיק מלאי של דמוי פרח בנקודה להזמנה זו
                </p>
              </div>
            </template> -->
          <span slot="noResult">לא מצאנו את האיזור המבוקש</span>
        </multiselect>

        <div v-if="selectedSpot">
          <h3>{{ selectedSpot.name }}</h3>
          <p>
            <b>כתובת:</b>
            {{ selectedSpot.address }}
          </p>
          <p>
            <b>איש.ת קשר:</b>
            {{ selectedSpot.contactPerson }}
          </p>
          <p>
            <b>טלפון:</b>
            {{ selectedSpot.phone }}
          </p>
        </div>
        <div style="color:red" v-if="!distributionStatus.canSubmit">
          {{ distributionStatus.error }}
        </div>
        <div class="order-btns" style="margin-top: 30px">
          <bgl-button
            :loading="formStatus === 'sending'"
            type="submit"
            :disabled="!distributionStatus.canSubmit"
            :class="{ success: saveStatus === 'נשמר!' }"
            class="btn"
          >
            {{ saveStatus }}
          </bgl-button>
        </div>
        <p style="color: green" v-if="formStatus === 'success'">נשמר</p>
      </form>
      <div class="big-white-box move-top" v-if="orderStatus == 'success'">
        נשמר
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-select/dist/vue-select.css";
import "vue-select/dist/vue-select.css";
import bglButton from "../components/bgl-button.vue";
import axios from "axios";
export default {
  components: {
    Multiselect,
    bglButton,
  },
async mounted() {
    const { order } = this.$route.query;
    this.orderID = order;
    this.fetchOrder();
    this.fetchLocations();
  },
  computed:{
    distributionStatus() {
      const { order, selectedSpot } = this;
      if (!order) return { canSubmit: false }
      const {numPlastic, numReal} = order;
      if (!selectedSpot) return {canSubmit: false }
      if (this.order.delivery) return { canSubmit: true };
      let plasticIssue =
        !!numPlastic &&
        selectedSpot?.plasticSupply < selectedSpot.sumNumPlastic + numPlastic;
      if (plasticIssue)
        return {
          canSubmit: false,
          error: "אין מספיק סיכות פלסטיק בנקודה שנבחרה"
        };
      let realIssue =
        !!numReal &&
        selectedSpot?.realSupply < selectedSpot?.sumNumReal + numReal;
      if (realIssue)
        return {
          canSubmit: false,
          error: "אין מספיק סיכות פרח אמיתי בנקודה שנבחרה"
        };
        return {
          canSubmit: true,
        }
    },
  },
  methods: {
    async fetchOrder() {
      const { data: order } = await this.$db
        .collection("ordersNextYear")
        .everything()
        .item(this.orderID)
        .get();
      this.order = order;
      this.selectedSpot = this.order.location[0]?.item;
    },
    async fetchLocations() {
      const { data } = await this.$db
        .collection("locations")
        .query("active", "=", true)
        .perPage(99999)
        .get();
      const regions = [
        ...new Set(data.map((l) => l.region?.value || "כל הארץ")),
      ].sort();
      console.log(regions);
      this.locations = regions.map((r) => ({
        region: r,
        spots: data.filter((l) => (l.region?.value || "כל הארץ") === r),
      }));
    },
    selectSpot(e) {
      this.order.location = [{ itemRefID: e._id }];
    },
    locationLabel({ name, address }) {
      return `${name} - ${address}`;
    },
    async submitOrder() {
      this.formStatus = "sending";
      const { orderID, selectedSpot } = this;
      const { data } = await axios.post("/api/updateOrderLocation", {
        orderID,
        locationID: selectedSpot._id,
      });
      this.formStatus = data;
      this.saveStatus = "נשמר!";
      
      setTimeout(() => (this.saveStatus = "שמירה"), 5000);
      axios.post("/api/auditspots-background");
      // const location = [{ itemRefID: this.selectedSpot._id }];
      // this.$db
      //   .collection("ordersNextYear")
      //   .item(this.orderID)
      //   .put({ location });
      // //  this.order.location
      // this.formStatus = "success";
    },
  },
  data() {
    return {
      saveStatus: "שמירה",
      orderID: "",
      isPoppedUp: false,
      selectedSpot: null,
      paymentMethod: "",
      paymentURL: "",
      orderStatus: "",
      order: {
        delivery: false,
        location: [{ itemRefID: "" }],
        email: "",
        phone: "",
        dedication: "",
        distributeEqually: false,
        family: [],
        nonprofit: [],
      },
      locations: [],
      nonprofits: [],
      finished: true,
      numPlastic: 0,
      pricePlastic: 10,
      priceReal: 18,
      numReal: 0,
      disableOrgChange: false,
      selectedNonprofit: "",
      formStatus: "",
    };
  },
};
</script>

<style>
.btn.success {
  background-color: #41b841;
  border-color: #41b841;
}

.btn.success::after {
  border-color: #41b841;
}
.download-docs {
  text-align: center;
}

.download-docs a {
  color: #1d3779;
  margin: 0 10px;
}

.multiselect__content-wrapper {
  overflow-x: hidden;
}

.inputgroup {
  display: flex;
  justify-content: space-between;
}

.inputgroup input {
  width: 49%;
}

@media screen and (max-width: 600px) {
  .inputgroup {
    display: block;
  }

  .inputgroup input {
    width: 100%;
  }
}

.logo {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 6px;
  background: white;
}

input.order-input:not(:placeholder-shown):not(:valid):not(:focus) {
  border: 1px solid salmon;
  background: pink;
}

input {
  font-family: "Assistant";
}

.iframe-payment {
  border: none;
  height: 600px;
  width: 100%;
  max-width: 720px;
  margin: auto;
  display: block;
}
</style>
<style src="../assets/css/vue-multiselect.css"></style>
