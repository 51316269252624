<template>
  <div><DataView title="נקודות חלוקה" collectionID="locations"></DataView></div>
</template>

<script>
import DataView from "./components/dataView.vue";
export default {
  components: {
    DataView,
  },
};
</script>
