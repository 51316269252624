<template>
<div class="main fam">
  <div class="top-bg fam">
    <!-- <img class="don-bg" src="../assets/family.jpg" /> -->
    <div class="family-box-wrap">
      <!-- <div class="big-white-box move-top for-now">
          <h3>{{ $l("thankYou") }}</h3>
          <p>
            {{ $l("JustAround") }}<br />
            {{ $l("start") }}
            <br />
            <br />
            {{ $l("Sincerely") }}
            <br />
            {{ $l("orgName") }}
            <br />
          </p>
          <a
            class="footer-link"
            href="https://www.facebook.com/damhamacabim/"
            target="_blank"
          >
            <img src="../assets/facebook.svg" alt="facebook" />
          </a>
        </div> -->
      <div
        class="family-box"
        v-if="$lang.locale == 'he'"
      >
        <h1 class="family-head">משפחות שכולות יקרות</h1>
        <p class="family-bigtxt">את הפרוייקט הזה הקמנו למענכם ולמען יקיריכם.
        </p>
        <p>
          בהצדעה אליכם, יהיה לנו את הכבוד להעניק לכם את סיכות דם המכבים ליום הזיכרון- כסמל לכוח החיים, ההמשכיות והתקומה עבור כולנו.
        </p>
        <p>לקבלת הסיכה
          <b>
            ללא עלות
          </b>
          תוכלו לגשת לכל אחד מהדוכנים והעמדות שיפתחו במקומות מרכזיים בכל רחבי הארץ בימים הסמוכים ליום הזיכרון.
        </p>
        <p>רשימת הדוכנים המלאה לשנה זו (תשפ"ד - 2024) תתפרסם בהקדם.
        </p>
        <p class="dark-link-small">בהערכה ובאהבה, </p>
        <p class="dark-link-small">
          עמותת דם המכבים
        </p>

      </div>
      <div
        class="family-box"
        v-if="$lang.locale == 'en'"
      >
        <h1 class="family-head">Dear Famalies</h1>
        <p class="family-bigtxt">This initiative was created for you and your loved ones.We salute you. It would be our honor to grant you Dam Hamaccabim pins for Yom Hazikaron.
        </p>
        <p>
          Please contact us below to receive our pins
          <b>
            without cost.
          </b>
        </p>
        <p class="dark-link-small">With appreciation and love,
        </p>
        <p class="dark-link-small">
          Dam Hamaccabim Org
        </p>
      </div>

      <!-- <div class="family-box">
        <h1 class="family-head">{{ $l("tnxFamily") }}</h1>
        <p class="family-bigtxt">{{ $l("bigtxtFamily") }}</p>
        <p>
          {{ $l("txtFamily") }}
          <b> {{ $l("costFamily") }} </b>
          <br />
          <br />
          {{ $l("txtFamily2") }}
          <br />
          {{ $l("txtFamily3") }}
        </p>
        <div class="form">
          <form
            @submit.prevent="sendForm"
            v-if="formStatus != 'success'"
          >
            <input
              v-model="form.name"
              :placeholder="$l('Name')"
              type="text"
              name="name"
              required
              class="txt-field"
            /><input
              v-model="form.phone"
              :placeholder="$l('Phone')"
              type="tel"
              required
              name="phone"
              class="txt-field"
            /><input
              v-model="form.email"
              :placeholder="$l('mail')"
              type="email"
              required
              pattern="^[\w.-]+@[\w]+[\w-]*(?:\.[\w-]+)*(?:\.[\w-]{2,})+$"
              name="email"
              class="txt-field"
            />
            <input
              v-model="form.fallenName"
              :placeholder="$l('fallenName')"
              type="text"
              name="fallenName"
              required
              class="txt-field"
            />

            <input
              class="btn cont"
              type="submit"
              :value="actionText ? actionText : $l('Submit')"
            />
          </form>

          <div
            v-if="formStatus == 'fail'"
            class="form-fail"
          >
            <p>{{ $l("error") }}</p>
          </div>
          <div
            v-if="formStatus == 'success'"
            class="form-success"
          >
            <p>{{ $l("emailWillBeSent") }}</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      actionText: "",
      formStatus: "",
      form: {
        name: "",
        phone: "",
        email: "",
        fallenName: "",
      },
    };
  },
  methods: {
    async sendForm() {
      if (this.actionText === "שולח") return;
      try {
        this.actionText = "שולח";
        await this.$db
          .collection("families")
          .item("b" + Math.random().toString(36).substr(2, 6))
          .set(this.form);
        this.actionText = "נשלח";
        this.formStatus = "success";
      } catch (err) {
        this.actionText = "שליחה";
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
};
</script>

<style scoped>
.top-bg {
  overflow: hidden;
}
</style>
