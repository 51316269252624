<template>
  <div class="big-white-box move-top downloadData">
    <popup v-model="popup" :forced="true">
      <div class="big-white-box" color="white">
        <label>
          סיסמא
          <input
            class="order-input"
            type="password"
            @input="checkPassword"
            v-model="password"
          />
        </label>
      </div>
    </popup>
    <div style="display: flex; justify-content: space-between">
      <p>הורדת דאתה כל הרכישות</p>
      <div class="json-to-csv">
        <bgl-button
          type="is-info is-light"
          :loading="downloadingData"
          @click="downloadData()"
          v-if="!approved"
          >הכן דאתה</bgl-button
        >
        <vue-json-to-csv
          class="btn"
          v-if="approved && data.length > 1"
          :json-data="data"
          csv-title="דם המכבים"
          >הורדה</vue-json-to-csv
        >
      </div>
    </div>
    <br />
    <hr />
    <div>
      <p>שחזור שליחת מייל הזמנה</p>

      <form @submit.prevent="sendEmail" v-if="!sent" class="coupon-wrap">
        <input
          class="order-input"
          type="text"
          v-model="orderID"
          placeholder="מספר הזמנה"
          required
        />
        <bgl-button :loading="sending" :disabled="!orderID" type="submit"
          >שליחה</bgl-button
        >
      </form>
      <p v-if="error" style="color: red">{{ emailError }}</p>
      <div v-if="sent">
        <p>שלחתי כפרה</p>
        <button
          @click="
            orderID = '';
            sent = false;
          "
          type="button"
        >
          עוד אחד?
        </button>
      </div>
    </div>
    <hr />
    <div style="display: flex; justify-content: space-between">
      <p>אחרי עדכון נקודת איסוף ניתן להריץ מחדש את הספירה</p>
      <bgl-button :loading="auditPosting" @click="runAudit()" class="btn"
        >הרצת ספירה</bgl-button
      >
    </div>
    <hr />
    <div style="display: flex; justify-content: space-between">
      <p>הורדת כל נקודות האיסוף</p>
      <div class="json-to-csv">
        <bgl-button
          :loading="downloadingSpots"
          type="is-info is-light"
          v-if="!spots.length"
          @click="spotsDownload"
          >משוך את כל הדאתה</bgl-button
        >
        <vue-json-to-csv class="btn" v-else :json-data="spots"
          >הורדה</vue-json-to-csv
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import bglButton from "../components/bgl-button.vue";
import BglButton from "../components/bgl-button.vue";
export default {
  components: { bglButton, VueJsonToCsv, BglButton },
  data() {
    return {
      emailError: "",
      auditPosting: false,
      sending: false,
      error: false,
      sent: false,
      orderID: "",
      popup: true,
      password: "",
      approved: false,
      downloadingData: false,
      data: [],
      downloadingSpots: false,
      spots: [],
      schema: [],
      fields: [],
      distribution: [],
      total: 1000,
    };
  },
  mounted() {
    let password = this.$cookies.get("password");
    if (password) {
      this.popup = false;
    }
  },
  methods: {
    async spotsDownload() {
      this.downloadingSpots = true;
      let { data: distribution } = await this.$db
        .collection("distribution")
        .everything()
        .get();
      let spots = [];
      for (const dist of distribution) {
        const distSpots = dist.spots.map((s) => {
          s.area = dist.name;
          return s;
        });
        spots = [...spots, ...distSpots];
      }
      this.spots = spots;
      this.downloadingSpots = false;
    },
    async runAudit() {
      this.auditPosting = true;
      await axios.post(
        "https://damhamaccabim.org/.netlify/functions/audit-background"
      );
      this.auditPosting = false;
    },
    checkPassword() {
      if (this.password == "dama") {
        this.popup = false;
        this.$cookies.set("password", true);
      }
    },
    async downloadData() {
      this.downloadingData = true;

      let { data: distribution } = await this.$db
        .collection("distribution")
        .everything()
        .get();
      this.distribution = distribution;
      // const { data: schema } = await this.$db.schema("orders").get();
      // this.schema = schema;

      this.fields = [
        "_id",
        "status",
        "_lastUpdatedDate",
        "_createdDate",
        "firstName",
        "lastName",
        "numReal",
        "numPlastic",
        "openAmount",
        "totalPrice",
        "coupon",
        "family",
        "distribution",
        "delivery",
        "city",
        "street",
        "aptNumber",
        "zipCode",
        "dedication",
        "distributeEqually",
        "nonprofit",
        "email",
        "phone",
        "delivered",
      ];
      const { headers } = await this.$db.collection("orders").perPage(1).get();

      this.total = parseInt(headers["item-count"]);

      let perPage = 100;
      let got = 0;
      let page = 1;
      while (got < this.total) {
        this.$db
          .collection("orders")
          .perPage(perPage)
          .pageNumber(page)
          .everything()
          .get()
          .then((res) => {
            this.processPayload(res.data);
          });
        got += perPage;
        page++;
      }
    },
    processPayload(data) {
      for (let order of data) {
        for (let field of this.fields) order[field] = order[field] || "";
        if (!order.delivery) {
          let distItemID = order?.distribution?.[0]?.itemRefID;
          let dist = this.distribution.find((d) => d._id === distItemID);
          if (!dist) {
            order.distribution = "";
          } else {
            let itemID = order?.distribution?.[0]?.spots?.[0]?.itemRefID;
            let spot = dist.spots.find((s) => s._id === itemID);
            order.distribution = `${order.distribution[0].value}: ${spot?.name} - ${spot?.address}`;
          }
        } else {
          order.distribution = " ";
        }
        order._createdDate = order._createdDate || order._lastUpdatedDate;
        order.numPlastic = order.numPlastic || 0;
        order.numReal = order.numReal || 0;

        for (let [k, v] of Object.entries(order)) {
          if (Array.isArray(v)) {
            order[k] = order[k].map((n) => n.value).join(",");
          }
          if (typeof order[k] === "object") order[k] = order[k].value;
          if (typeof order[k] === "string")
            order[k] = order[k].replace(/"/g, "“");
          order[k] = order[k] || "";
        }
        let obj = {};
        for (let f of this.fields) obj[f] = order[f] || "";
        this.data.push(obj);
        if (this.data.length === this.total) {
          this.approved = true;
          this.downloadingData = false;
        }
      }
    },
    async sendEmail() {
      this.emailError = "";
      this.error = false;
      this.sending = true;
      try {
        if (!this.orderID) return;
        // this.orderID = this.orderID.trim()
        const { data: order } = await this.$db
          .collection("orders")
          .query("_id", "=", this.orderID)
          .get();

        console.log(order);
        let item;
        if (!order.length) {
          const { data: nextYear } = await this.$db
            .collection("ordersNextYear")
            .query("_id", "=", this.orderID)
            .get();
          if (!nextYear.length) {
            this.sending = false;
            this.error = true;
            this.emailError = "לא מכיר את מספר ההזמנה";
            return;
          }
          item = Object.assign({}, nextYear[0]);
        } else {
          item = Object.assign({}, order[0]);
        }

        const emailURL = "/api/send-email?template=624197b0c98f2aeb930122c4";
        const orderDetailsHtml = [];
        if (item.numReal)
          orderDetailsHtml.push(`סיכות פרח דם המכבים: ${item.numReal}`);
        if (item.numPlastic)
          orderDetailsHtml.push(
            `סיכות פלסטיק דמוי פרח דם המכבים: ${item.numPlastic}`
          );

        // this.sending = false;
        // this.error = true;
        // this.emailError = "לא מוזנת נקודת איסוף בהזמנה";
        // return;

        if (item?.distribution?.[0]?.spots?.[0]?.itemRefID) {
          let { data: spot } = await this.$db
            .collection("distribution")
            .item(item.distribution[0].itemRefID)
            .collection("spots")
            .item(item.distribution[0].spots[0].itemRefID)
            .get();
          orderDetailsHtml.push(`<br> פרטי נקודת איסוף: <br>
    ${item.distribution[0].value} - ${spot.name}<br>
    כתובת: ${spot.address}<br>
    טלפון: ${spot.phone}<br>
    שם איש קשר: ${spot.contactPerson}<br>
    `);
        }
        item.orderDetailsHtml = orderDetailsHtml.join("<br>");
        await axios.post(emailURL, { item });
        this.sent = true;
        this.sending = false;
      } catch (err) {
        this.sending = false;
        this.error = true;
        // this.emailError = err;
        this.emailError = "לא מכיר את מספר ההזמנה";
        console.log(err);
      }
    },
  },
};
</script>

<style>
.json-to-csv button {
  background: #1d3679;
  color: white;
  min-width: 100px;
  border-radius: 5px;
  padding: 0 20px;
  height: 48px;
}

.downloadData .show.bglfull.bglpopup {
  background-color: white;
}
hr {
  opacity: 0.7;
}
</style>
