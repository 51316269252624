<template>
  <div class="editable-window">
    <div
      class="input-box"
      @keydown.enter="save"
      @keydown.esc="cancel"
      :contenteditable="editing"
      ref="editable"
      @blur="save"
      :tabindex="editing ? 1 : false"
      @dblclick="edit()"
    >
      {{ val }}
      <!-- <div v-if="editing" class="left-buttons">
      <button class="btn" @click="cancel">
        <bf-icon icon="close" size="10" />
      </button>
      <button class="btn" @click="save">
        <bf-icon icon="check" size="10" />
      </button>
      </div>-->
    </div>
    <div
      class="left-buttons"
      :class="{ editing: editing }"
    >
      <button
        @click="edit()"
        class="edit data-btn"
        type="button"
      >
        <bf-icon
          icon="edit"
          size="10"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InlineText",
  props: { value: String },
  data() {
    return {
      val: this.value,
      editing: false
    };
  },
  methods: {
    edit() {
      this.editing = true;

      setTimeout(() => {
        this.$refs.editable.focus();
      });
    },
    save() {
      this.editing = false;
      if (this.val === this.$refs.editable.innerText) return;
      this.val = this.$refs.editable.innerText;
      this.$emit("input", this.val);
    },
    cancel() {
      if (this.editing) {
        let b = this.value;
        this.val = "";
        setTimeout(() => {
          this.val = b;
        });

        this.editing = false;
      }
    }
  }
};
</script>

<style scoped>
.editable-window {
  position: relative;
  transition: all 200ms ease;

}

.left-buttons {
  position: absolute;
  top: 0px;
  left: 0px;
}

.edit,
.editing {
  transition: all 200ms ease;
  opacity: 0;
  background: white;
  outline: 1px solid #b5b5b5 !important;
  margin: 2px;

}

.editable-window:hover .edit {
  opacity: 1;

}

.editable-window:hover .edit:hover {
  color: #20377b !important;
  /* transform: scale(1.2); */
  outline: 1px solid #20377b !important;
  box-shadow: 0 0 0 0.125em #20377b40 !important;
}

.editable-window:hover .edit:active {
  filter: brightness(80%);
}



.input-box {
  padding: 5px 15px;
  /* max-width: 250px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 200ms ease;
  border-radius: 30px;
  cursor: pointer;
  min-height: 34px;
}

.input-box:hover {
  background: #f3f3f3;
}

.input-box[contenteditable="true"] {
  /* border: 1px solid rgb(211, 211, 211); */
  /* box-shadow: 3px 3px 10px rgba(210, 210, 210, 0.647); */
  background: white;
  white-space: normal;
  position: absolute;
  z-index: 1;
  background: #f3f3f3;
  cursor: text;
  width: 100%;
}

.input-box[contenteditable="true"]:focus {
  /* color: #20377b;
  outline: none;
  background: #f3f3f3;
  background: lightgray; */
  background: white;
  outline: 1px solid #20377b !important;
  box-shadow: 0 0 0 0.125em #20377b40 !important;
}
</style>
