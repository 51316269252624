<template>
<div class="nav-bar">
  <router-link
    to="/"
    class="top-logo"
  >
    <img
      src="../assets/dam.png"
      height=""
      width=""
    /></router-link>
  <div
    class="nav-links-wrap"
    :class="{ 'open-nav': opennavbar }"
  >
    <button
      class="close-menu"
      @click="opennavbar = false"
    >
      <img src="../assets/close.svg" />
    </button>
    <router-link
      class="top-link"
      to="/about"
    >{{ $l("About") }}</router-link>
    <router-link
      class="top-link"
      to="/contact"
    >{{ $l("Contact") }}</router-link>
    <!-- רשימת הנקודות -->
    <router-link
      class="top-link"
      to="/points"
    >{{ $l("Points") }}</router-link>


    <!-- <router-link
      class="top-link"
      to="/nonprofits"
    >{{
      $l("nonprofit")
    }}</router-link> -->
    <router-link
      class="top-link"
      to="/story"
    >{{ $l("Story") }}</router-link>
    <!-- <router-link class="top-link" to="/order ">{{
        $l("Donations")
      }}</router-link> -->
    <router-link
      class="top-link"
      to="/gallery"
    >{{
      $l("Gallery")
    }}</router-link>
    <button
      class="top-link"
      @click="$lang.locale = $lang.locale == 'en' ? 'he' : 'en'"
    >
      {{ $l("switchLanguage") }}
    </button>
    <router-link
      to="/donations"
      class="top-link big"
    >{{

      $l("donate")
    }}</router-link>
  </div>
  <div
    class="manu-background"
    @click="opennavbar = false"
  />
  <button
    class="menu"
    @click="opennavbar = true"
  >
    <img src="../assets/Menu.svg" />
  </button>
</div>
</template>

<script>
export default {
  name: "topnav",
  data: () => ({
    opennavbar: false,
  }),
  watch: {
    "$route.path"() {
      if (this.opennavbar) this.opennavbar = false;
    },
  },
};
</script>

<style scoped>
.manu-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all ease 0.2s;
}

.open-nav+.manu-background {
  display: block;
  animation: fadein 0.2s linear;
  background-color: rgba(0, 0, 0, 0.7);
}

@keyframes fadein {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
</style>
