<template>
<div
  id="contact"
  class="contact"
>
  <div class="contact-wrap">
    <div>
      <h1 class="contact-head">{{ $l("Contact") }}</h1>
      <!-- <p class="contact-txt">{{ $l("cell") }} : 0547-259035</p> -->
      <p class="contact-txt">
        {{ $l("Email") }}
        <a href="mailto:info@redeverlasting.com">info@redeverlasting.com</a>
      </p>
      <div class="contact-soc">
        <a
          class="soc-link"
          href="https://www.facebook.com/damhamacabim/"
          target="_blank"
        >
          <img
            src="../assets/facebook-white.svg"
            alt="facebook"
          />
        </a>
        <a
          class="soc-link"
          href="https://www.instagram.com/damhamaccabim/"
          target="_blank"
        >
          <img
            src="../assets/instagram-white.svg"
            alt="instagram"
          />
        </a>
      </div>
    </div>

    <div class="form">
      <form
        @submit.prevent="sendForm"
        v-if="formStatus != 'success'"
      >

        <input
          v-model="form.name"
          :placeholder="$l('Name')"
          type="text"
          name="name"
          class="txt-field"
          required
        /><input
          v-model="form.phone"
          :placeholder="$l('Phone')"
          type="tel"
          name="phone"
          required
          class="txt-field"
          style="direction: rtl;"
        /><input
          v-model="form.email"
          :placeholder="$l('mail')"
          type="email"
          name="email"
          required
          class="txt-field"
        /><textarea
          v-model="form.message"
          :placeholder="$l('message')"
          name="message"
          class="txt-field big"
        ></textarea>
        <select
          class="txt-field"
          v-model="form.subject"
          name="subject"
          id="subject"
        >
          <option
            value=""
            selected
            disabled
            hidden
          >
            {{ $l("Subject") }}
          </option>
          <option value="organization">{{ $l("organization") }}</option>
              <option value="educationalInstitution">{{ $l("educationalInstitution") }}</option>
              <option value="volunteering">{{ $l("volunteering") }}</option>
              <option value="wholeFamily">{{ $l("wholeFamily") }}</option>
              <option value="general">{{ $l("general") }}</option>
        </select>


        <input
          class="btn cont"
          type="submit"
          id=""
          :value="$l('Submit')"
        />
      </form>

      <div
        v-if="formStatus == 'fail'"
        class="form-fail"
      >
        <p>{{ $l("error") }}</p>
      </div>
      <div
        v-if="formStatus == 'success'"
        class="form-success"
      >
        <p>{{ $l("tnx") }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      openPopup: null,
      openSideBar: false,
      formStatus: "",
      form: {
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
        emailTo: "info@redeverlasting.com"

      },
    };
  },
  methods: {
    async sendForm() {
      try {
        if (
          ['organization', 'educationalInstitution'].includes(this.form.subject)
        ) this.form.emailTo = 'mickey@redeverlasting.com';

        await this.$db.collection("contact").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  name: "botfooter",
};
</script>
