<template>
  <div class="main">
    <popup v-model="isPoppedUp">
      <div class="nonprofit-pop">
        <img
          class="popup-logo"
          v-if="focused.logo"
          :src="focused.logo.imageURL"
          :alt="focused.name"
        />
        <div v-html="focused.about" style="text-align: right" v-if="isheb" />
        <div v-html="focused.aboutEn" style="text-align: left" v-if="!isheb" />
        <div class="soc-wrap">
          <a
            v-if="focused.website"
            :href="focused.website"
            target="_blank"
            class="footer-link"
          >
            <img src="../assets/website.svg" alt="website" />
          </a>
          <a
            v-if="focused.facebook"
            :href="focused.facebook"
            target="_blank"
            class="footer-link"
            ><img src="/img/facebook.028fc082.svg" alt="facebook" /></a
          ><a
            v-if="focused.instagram"
            :href="focused.instagram"
            target="_blank"
            class="footer-link"
            ><img src="/img/instagram.15ba9e56.svg" alt="instagram"
          /></a>
        </div>
      </div>
    </popup>
    <div v-if="isheb">
      <div class="gallery-back">
        <h1 class="top-head">{{ $l("nonprofit") }}</h1>
        <p class="nonprofitsub">{{ $l("nonprofitsub1") }}</p>
        <p class="nonprofitsub">{{ $l("nonprofitsub2") }}</p>
        <p class="nonprofitsub">{{ $l("nonprofitsub3") }}</p>
        <div class="nonprofit-wrap">
          <div
            @click="openPopup(nonprofit)"
            class="nonprofit-logo"
            v-for="nonprofit in nonprofits"
            :key="nonprofit._id"
          >
            <img :src="nonprofit.logo.imageURL" :alt="nonprofit.name" />
            <p>{{ nonprofit.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="gallery-back">
        <h1 class="top-head">{{ $l("nonprofit") }}</h1>
        <p class="nonprofitsub">{{ $l("nonprofitsub1") }}</p>
        <p class="nonprofitsub">{{ $l("nonprofitsub2") }}</p>
        <p class="nonprofitsub">{{ $l("nonprofitsub3") }}</p>

        <div class="nonprofit-wrap">
          <div
            @click="openPopup(nonprofit)"
            class="nonprofit-logo"
            v-for="nonprofit in nonprofits"
            :key="nonprofit._id"
          >
            <img :src="nonprofit.logo.imageURL" :alt="nonprofit.nameEn" />
            <p>{{ nonprofit.nameEn }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    nonprofits: "",
    focused: {},
    isPoppedUp: false,
  }),
  methods: {
    openPopup(nonprofit) {
      this.focused = nonprofit;
      this.isPoppedUp = true;
    },
  },
  async beforeMount() {
    let { data: nonprofits } = await this.$db
      .collection("nonprofits")
      .query("show", "=", true)
      .query("id", "!=", "624363c4ca81d216d453cc83")
      .sort("order", "asc")
      .get();
    this.nonprofits = nonprofits;
  },
  computed: {
    isheb() {
      return this.$lang.locale === "he";
    },
  },
};
</script>
