export default {
  orgName: 'Dam Hamaccabim',
  switchLanguage: 'עברית',
  explainerVideo: 'Explanatory video',
  getYourPin: 'Order a pin',
  Project: ' ',
  Sub: 'The fundraiser initiative for supporting bereaved families and commemorating our fallen',
  silverPlatter1: 'We are the silver platter on which',
  silverPlatter2: 'the Jews’ state was presented today',
  link1: 'Memories that unite. Memories that grow.',
  smallLink1: 'The Story of the Flower.',
  link2: 'Hearts that beat as one',
  smallLink2: 'Help us grow',
  link3: 'From our field on the Carmel Mountain, and all the way to you...',
  smallLink3: 'Our Process',
  link4: 'Pins for Bereaved Families',
  smallLink4: 'Click for more',
  link5: 'Stronger together',
  smallLink5: 'Distribution Locations',
  link6: 'In the media',
  smallLink6: 'In the media',
  pop: 'Order pins for next year',
  About: 'About Us',
  Story: 'Our Story',
  Donations: 'Donate',
  Gallery: 'Gallery',
  Contact: 'Contact Us',
  cell: 'Cell',
  Email: 'Email',
  Submit: 'Submit',
  Subject: 'Pick a Subject',
  volunteering: 'Volunteering',
  largeOrder: 'Request a quote for a large order',
  Points: 'Distribution Locations',
  TermsOfUse:'Terms of Use',
  Accessibility:'Accessibility',
  harvest: 'Harvest',
  CollectingPoint: 'Pick-up Point',
  general: 'General',
  Name: 'Name',
  Phone: 'Phone',
  mail: 'Email',
  message: 'Message',
  error: 'An error occurred while sending',
  tnx: 'Thank You for the Submition',
  DonationsHead: 'Donations',
  DonationsTxt:
    'Thank you. Thank you that our initiative moved you, as it it moves us. Your donation will allow to continue remembering and growing for future years. Together.',
  nonprofit: 'Partner Organization',
  news: 'In the media',
  thankYou: 'Thank you for coming!',
  JustAround: 'Just around the corner..',
  start:
    'The ordering of 2022 Memorial pins for Yom Hazikaron has yet to open. Follow us on Facebook here and you will know the moment orders have begun.',
  Sincerely: 'Sincerely,',
  selectdonate: 'Donations',
  selectpartner: 'Partner Organization',

  tnxFamily: 'Dear Famalies',
  familyHead: 'Dear Bereaved Famalies,',
  bigtxtFamily:
    'This initiative was created for you and your loved ones.We salute you. It would be our honor to grant you Dam Hamaccabim pins for Yom Hazikaron. ',
  txtFamily: 'Please contact us below to receive our pins',
  costFamily: 'without cost.',
  txtFamily2: 'With appreciation and love,',
  txtFamily3: 'Dam Hamaccabim Org',
  DonationsSmallTxt:
    'Please notice: You are currently on the donation page for Amutat Dam Hamaccabim. If you wish to order pins for Yom Hazikaron,',
  DonationsPress: 'Click here.',
  nonprofitsub1: 'Dam Hamaccabim is Fundraiser initiative for Yom HaZikaron',
  nonprofitsub2:
    'The revenue from our pins go to nonprofit organizations. These organizations each support bereaved families or commemorate the fallen in an inspiring way.',
  nonprofitsub3:
    'Below is a list of our partner organizations for the year 2023. You are welcome to read up about each of their unique activities.',
  emailWillBeSent: 'You should receive an email soon',
  fallenName: 'Name of the Fallen',
  donate: 'Donate',
  homeTitle: 'Memory, growth and resurrection.',
  homeSubTitle: 'Participate in the memory of the fallen and the resurrection of the country.',
  homeOpen1:
    'In a year in which the soil of the country was soaked with the blood of heroes and heroines of the 7th of October and the war of iron swords, we remember their will: growth and resurrection.',
  homeOpen2:
    "These days, the association's employees are all recruited for the war effort and we are forced to focus our activities.",
  homeOpen3:
    'Until the website opens for orders, we will be happy for your contribution to the distribution of flowers to the bereaved families.',
  homeOpen4:
    'In addition to ordering pins, we would apricate your donation for the continuation of our work for commemorating our IDF fallen soldiers and victims of terror, and supporting the families they left behind.',
  donPin: 'We would be happy for your donation to give the pins to the bereaved families',

  newHomeOpen1:
    'In a year that was marked by the great sacrifice of our nation’s heroes, whose blood leaves its marks on our land for eternity, we honor their legacy of strength and devotion.',
  newHomeOpen2:
    'This Yom HaZikaron we will all wear the Dam HaMaccabim flower pin, symbolizing our strength, perseverance and resilience in the wake of great tragedy and loss.',
  newHomeOpen3:
    'The Dam HaMaccabim flower pin will be available for purchase at exclusive stalls in malls and central locations across Israel.',
  newHomeOpen4:
    'Proceeds will be dedicated to supporting bereaved families and commemorating the memories of our fallen heroes. * A full list of stall locations will be published soon.',
  newHomeOpen5: 'To order pins in bulk for groups, companies and organizations, please contact, Mickey:',
  newHomeOpen6: 'Bereaved families are invited to collect pins free of charge from all stall locations.',
  organization: 'quote for an organization',
  educationalInstitution: 'A quote for an educational institution',
  wholeFamily: 'A whole family',
};
