<template>
  <div
    id="app"
    :dir="$lang.locale == 'en' ? 'ltr' : 'rtl'"
    :class="{ eng: $lang.locale == 'en' }"
  >
    <topnav v-if="!emptyTemplates" />
    <router-view />
    <botfooter v-if="!emptyTemplates" />
  </div>
</template>

<script>
import topnav from "./components/topnav.vue";
import botfooter from "./components/botfooter.vue";

export default {
  name: "App",
  components: { topnav, botfooter },
  computed: {
    emptyTemplates() {
      return ["/cancel", "/success", "/admin"].find((p) =>
        this.$route.path.match(p)
      );
    },
  },
};
</script>

<style src="./main.css"></style>
