<template>
  <div class="soc-wrap">
    <img src="/og.jpg" width="280" />
    <textarea class="message" v-model="message"></textarea>
    <a
      @click="analyze('email')"
      :href="email"
      target="_blank"
      :class="classList"
      class="soc-link w-inline-block email"
    >
      <img src="@/assets/email.svg" loading="lazy" alt="Email" />
    </a>
    <a
      @click="analyze('facebook')"
      :href="facebook"
      target="_blank"
      :class="classList"
      class="soc-link w-inline-block facebook"
    >
      <img src="@/assets/facebook-share.svg" loading="lazy" alt="Share on Facebook" />
    </a>
    <a
      @click="analyze('twitter')"
      :href="twitter"
      target="_blank"
      :class="classList"
      class="soc-link w-inline-block twitter"
    >
      <img src="@/assets/twitter.svg" loading="lazy" alt="Share on Twitter" />
    </a>
    <a
      @click="analyze('whatsapp')"
      :href="whatsapp"
      target="_blank"
      :class="classList"
      class="soc-link w-inline-block whatsapp"
    >
      <img src="@/assets/whatsapp.svg" loading="lazy" alt="Share on Whatsapp" />
    </a>
  </div>
</template>

<script>
export default {
  props: { title: String, classList: String },
  data() {
    return {
      message: this.title
    };
  },
  methods: {
    analyze(network) {
      let options = {};
      options.Category = "socialbutton";
      options.Action = network;
      options.event = "auto_event";
      this.$ga(options);
    }
  },
  computed: {
    path() {
      return `https://damhamaccabim.org/order`;
    },
    whatsapp() {
      let str = `https://api.whatsapp.com/send?text=${this.message}\n${this.path}`;
      return encodeURI(str);
    },
    facebook() {
      let str = `https://www.facebook.com/sharer.php?u=${this.path}&t=${this.message}`;
      return encodeURI(str);
    },
    twitter() {
      let str = `https://twitter.com/intent/tweet?url=${this.path}&text=${this.message}`;
      return encodeURI(str);
    },
    email() {
      let str = `mailto:?subject=הזמנת סיכות זיכרון דם המכבים&body=${this.message}\n${this.path}`;
      return encodeURI(str);
    }
  }
};
</script>

<style scoped>
.soc-wrap {
  border-radius: 10px;
  overflow: hidden;
}
.message {
  display: block;
  width: 100%;
  border: none;
  height: 100px;
  background: rgb(228, 228, 228);
  max-width: 280px;
  padding: 10px 20px;
  resize: none;
  margin-bottom: 0;
}
.message:focus {
  outline: none;
}
.soc-link {
  height: 40px;
  background: gray;
  width: 70px;
  padding: 5px;
  /* border-radius: 100px; */
  /* margin: 5px; */
}

.soc-link img {
  max-height: 22px;
  max-width: 22px;
}

.whatsapp {
  background: #52ea68;
}
.twitter {
  background: #52c2ea;
}
.facebook {
  background: #2d51bd;
}
</style>
