<template>
  <div class="main">

    <div class="big-white-box move-top">
      <h1 class="top-head">עמדת מכירת סיכות
        <br>
        דם המכבים
      </h1>
      <br>

      <form
        class="order-form"
        @submit.prevent="submitOrder"
        v-if="orderStatus != 'success'"
      >
        <label for="">אזור נקודת איסוף</label>
        <select
          class="order-input"
          name="Distribution"
          required
        >
          <option
            disabled
            value
          >אזור *</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>

        </select>


        <input
          v-model="order.pinAmount"
          class="order-input"
          placeholder="כמות סיכות "
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          min="0"
        />







        <p class="order-total">סה״כ: ₪{{ totalPrice.toLocaleString() }}</p>


        <div class="order-btns">
          <bgl-button
            :loading="
              formStatus === 'sending' && donateMethod === 'donateCardcom'
            "
            @click="donateMethod = 'donateCardcom'"
            type="submit"
            class="btn"
          >לתשלום באשראי</bgl-button>
          <bgl-button
            type="submit"
            :loading="formStatus === 'sending' && donateMethod === 'donateBit'"
            @click="donateMethod = 'donateBit'"
            class="btn bit"
          >
            לתשלום בביט
            <img
              src="../assets/bit.png"
              alt
            />
          </bgl-button>
        </div>


      </form>
      <div
        class="big-white-box move-top"
        v-if="orderStatus == 'success'"
      >
        תודה רבה
      </div>

    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import BglButton from "../components/bgl-button.vue";
export default {
  components: {

    BglButton,

  },
  async mounted() {
    this.$ga({
      Category: "pageview",
      Action: "addtocart",
      event: "auto_event",
    });

    const { org, preview, coupon } = this.$route.query;
    this.fetchLocations();
    let { data: nonprofits } = await this.$db
      .collection("nonprofits")
      .sort("order", "asc")
      .get();
    this.nonprofits = nonprofits;

    if (process.env.NODE_ENV == "development") this.finished = false;

    this.selectedNonprofit = org || "";
    this.disableOrgChange = !!org;
    if (preview) this.finished = false;
    if (coupon) {
      this.couponCode = coupon;
      this.checkCoupon();
    }
    this.observeSuccess();
  },
  computed: {


    totalPrice() {
      let total = 0;
      let { numReal, numPlastic } = this;
      if (this.couponStatus == "success") {
        numReal = Math.max(0, numReal - this.couponNumReal);
        numPlastic = Math.max(0, numPlastic - this.couponNumPlastic);
      }
      total += numPlastic * this.pricePlastic;
      total += numReal * this.priceReal;
      total += parseInt(this.order.openAmount || "0");
      if (this.order.delivery) total += 50;
      return total;
    },
  },
  watch: {
    selectedNonprofit(nonprofit) {
      if (!nonprofit) this.order.nonprofit = [];
      else this.order.nonprofit = [{ itemRefID: nonprofit }];
    },
  },
  methods: {
    async fetchLocations() {
      const { data } = await this.$db
        .collection("locations")
        .perPage(99999)
        .get();
      const regions = [...new Set(data.map((l) => l.region?.value || 'כל הארץ'))].sort();
      console.log(regions)
      this.locations = regions.map((r) => ({
        region: r,
        spots: data.filter((l) => (l.region?.value || 'כל הארץ') === r),
      }));
    },
    selectSpot(e) {
      this.order.location = [{ itemRefID: e._id }];
    },
    locationLabel({ name, address }) {
      return `${name} - ${address}`;
    },
    async submitOrder() {
      if (!this.numPlastic && !this.numReal) return;
      if (this.formStatus === "sending") return;
      this.formStatus = "sending";
      try {
        const order = Object.assign({}, this.order);
        order.totalPrice = this.totalPrice;
        if (!order.location[0].itemRefID) delete order.location;
        if (
          !this.totalPrice ||
          order.distributeEqually ||
          (order.nonprofit && !order.nonprofit[0].itemRefID)
        ) {
          order.distributeEqually = true;
          delete order.nonprofit;
        }
        if (this.numReal) order.numReal = this.numReal;
        if (this.numPlastic) order.numPlastic = this.numPlastic;
        if (!this.totalPrice) delete order.nonprofit;
        if (
          this.couponStatus === "success" &&
          this.order.delivery &&
          this.totalPrice === 50
        )
          delete order.nonprofit;
        if (order.delivery) delete order.location;
        let { data } = await axios.post(
          `/.netlify/functions/next-${this.donateMethod}`,
          order
        );
        this.paymentURL = data.url;
        this.formStatus = "sent";
        window.scroll(0, 0);
        this.$ga({
          Category: "checkout",
          Action: "SEND",
          event: "auto_event",
          value: order.totalPrice,
        });
        // this.orderStatus = "success";
      } catch (err) {
        this.formStatus = "failed";
        console.log(err);
      }
    },
    observeSuccess() {
      window.addEventListener(
        "message",
        (message) => {
          let { data } = message;
          if (data === "success") {
            let optns = {};
            optns.Category = "purchase";
            optns.Action = "buy";
            optns.event = "auto_event";
            optns.value = this.totalPrice;
            this.$ga(optns);
          }
        },
        false
      );
    },
    async checkCoupon() {
      if (!this.couponCode) {
        this.couponMessage = "";
        return;
      }
      this.couponStatus = "checking";
      const { data } = await axios.post("/api/checkCoupon", {
        couponCode: this.couponCode,
      });
      this.couponMessage = data.message;
      if (!data.isValid) {
        this.couponStatus = "fail";
        return;
      }
      this.couponStatus = "success";
      const coupon = { itemRefID: data.couponID };
      this.order[data.type].push(coupon);
      this.couponNumReal = data.numReal;
      this.couponNumPlastic = data.numPlastic;
      console.log(data);
    },
  },
  data() {
    return {
      isPoppedUp: false,
      selectedSpot: null,
      couponNumReal: 0,
      couponNumPlastic: 0,
      paymentMethod: "",
      paymentURL: "",
      orderStatus: "",
      order: {
        delivery: false,
        location: [{ itemRefID: "" }],
        email: "",
        phone: "",
        dedication: "",
        distributeEqually: false,
        family: [],
        coupon: [],
        nonprofit: [],
      },
      locations: [],
      nonprofits: [],
      finished: true,
      numPlastic: 0,
      pricePlastic: 10,
      priceReal: 18,
      numReal: 0,
      couponCode: "",
      couponStatus: "",
      couponMessage: "",
      disableOrgChange: false,
      selectedNonprofit: "",
      formStatus: "",
    };
  },
};
</script>
