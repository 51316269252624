<template>
<div>
  <div class="main" v-if="$lang.locale == 'he'">
    <h1 class="top-head">הצהרת נגישות</h1>
    <div class="terms-wrap">
      <p dir="rtl">
        באתר זה הושקעו מאמצים רבים על מנת להנגיש מידע ופעולות עבור אנשים עם
        מוגבליות וזאת מתוך דעת ורצון בעלי האתר וכן הוראות הדין והפסיקה הקיימות,
        לרבות חוקי היסוד והזכות הבסיסית לכבוד ושיויון עבור כלל האזרחים.
      </p>
      <p dir="rtl">
        האתר
        מותאם לכלל הדפדפנים הפופלאריים, כאשר ההמלצה העיקרית להתאמת הנגישות הנה
        בעיקר בדפדפן כרום ו-EDGE, כאשר בשאר הדפדפנים עלולות להיות בעיות
        תאימות.
      </p>
      <p dir="rtl">
        <b>
          בשלב זה קיימות באתר בין היתר הפעולות הבאות אשר הותאמו
          במיוחד עבור בעלי מוגבליות:
        </b>
      <ul>
        <li>
          <p dir="rtl">
            אפשרות לנווט באמצעות המקלדת.
          </p>
        </li>
        <li>
          <p dir="rtl">
            התאמת

            צבעים הכוללים ניגודיות גבוהה בכפתורים.</p>
        </li>
        <li>
          <p dir="rtl">
            התאמת פונטים לגדלים המתאימים
            לליקויי ראיה. </p>
        </li>
        <li>
          <p dir="rtl">
            שימוש תקין בטקסט תיאור- המהווה התאמה לקוראי
            מסך. </p>
        </li>
      </ul>

      <p dir="rtl">
        נוסיף ונציין כי בשלב זה ולמרות מאמצים רבים אשר הושקעו, ייתכנו
        עדיין באתר דפים אשר טרם הנוגשו וגם/או הותאמו מבחינה טכנולוגית עבור כלל
        המשתמשים, לרבות לקויי שמיעה, ובכוונתנו להמשיך לעשות כל אשר ביכולתנו על
        מנת להמשיך ולהנגיש ככל הניתן את האתר עבור כלל המשתמשים ובכללם - בעלי
        מוגבליות.
      </p>
      <p dir="rtl">
        בכל אופן, נודה ונשמח על כל הערה וגם/או תובנה וגם/או
        משוב מכל סוג שהוא מצד כל משתמשי האתר באמצעות שליחת הודעה בדף "יצירת
        קשר".
      </p>
      <p dir="rtl">
        מועד ההצהרה: אפריל 2024
      </p>
    </div>
  </div>
  <div v-if="$lang.locale == 'en'">
    <h1 class="top-head">Accessibility Statement</h1>
    <div class="terms-wrap">
      <p>
        A lot of effort has been put into this website to make information and actions accessible for people with
        Disabilities, and this is based on the opinion and will of the site owners, as well as the provisions of the existing law and rulings,
        including the basic laws and the basic right to respect and equality for all citizens.
      </p>
      <p>
        the site
        Adapted to all popular browsers, where the main recommendation for adjusting accessibility is here
        Mainly in Chrome and EDGE browser, while the other browsers may have problems
        compatibility.
      </p>
      <p>
        <b>
          At this point, the website includes, among other things, the following actions that have been adjusted
          Especially for those with disabilities:
        </b>
      <ul>
        <li>
          <p>
            Ability to navigate using the keyboard.
          </p>
        </li>
        <li>
          <p>
            adjustment

            Colors that include high contrast in the buttons.</p>
        </li>
        <li>
          <p>
            Adjusting fonts to the appropriate sizes
            for visual impairments. </p>
        </li>
        <li>
          <p>
            Proper use of description text - which is appropriate for readers
            screen. </p>
        </li>
      </ul>
      <p>
        We will add and note that at this stage and despite many efforts that have been invested, it is possible
        There are still pages on the site that have not yet been accessed and/or technologically adapted for Clal
        the users, including the hearing impaired, and we intend to continue doing everything in our power
        In order to continue to make the website as accessible as possible for all users, including - my husband
        disability.
      </p>
      <p>
        In any case, we will be grateful and happy for any comment and/or insight and/or
        Feedback of any kind from all site users by sending a message on the "Creation
        link".
      </p>
      <p>
        Declaration date: April 2024
      </p>
    </div>
  </div>
</div>
</template>

<script>
import lang from "../lang/en";
export default {
  computed: {
    lang() {
      return lang;
    },
  },
};
</script>
