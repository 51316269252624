<template>
  <router-link :to="to.split(baseURL).pop()" v-if="internal">
    <slot></slot>
  </router-link>
  <a :href="to" target="_blank" v-else>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "link-path",
  props: {
    to: String,
  },
  computed: {
    baseURL() {
      // return window.location.origin.split("//").pop();
      return "damhamaccabim.org";
    },
    internal() {
      return !!this.to.match(this.baseURL);
    },
  },
};
</script>
