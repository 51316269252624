<template>
  <div class="number-roll-wrap">
    <span class="prefix">{{ prefix }}</span>
    <span class="number-roll" ref="number"></span>
    <span v-if="hasFloat">.</span>
    <span class="number-roll" ref="float"></span>
    <span class="suffix">{{ suffix }}</span>
  </div>
</template>

<script>
export default {
  name: "rollingNumbers",
  props: {
    value: [Number,String],
    suffix: String,
    prefix: String,
    delay: {
      type: Number,
      default: () => 800,
    },
  },
  data() {
    return {
      stat: null,
      float: null,
      currNum: null,
      hasFloat: false
    };
  },
  mounted() {
    this.stat = this.$refs.number;
    this.float = this.$refs.float;
    const [num, float] = this.value.toString().split(".");
    if (float) this.hasFloat = true;
    this.setup(num, this.stat);
    this.setup(float, this.float);
  },
  methods: {
    scrollNumber(digits, el) {
      el.querySelectorAll("span[data-value]").forEach((tick, i) => {
        tick.style.transform = `translateY(-${100 * parseInt(digits[i])}%)`;
      });
    },
    addDigit(digit, fresh, el) {
      const spanList = Array(10)
        .join(0)
        .split(0)
        .map((x, j) => `<span>${j}</span>`)
        .join("");

      el.insertAdjacentHTML(
        "beforeend",
        `<span style="transform: translateY(-1000%)" data-value="${digit}">
        ${spanList}
      </span>`
      );

      const firstDigit = el.lastElementChild;
      setTimeout(
        () => {
          firstDigit.className = "visible";
        },
        fresh ? 0 : 2000
      );
    },
    setup(startNum, el) {
      const digits = startNum.split("");
      const overshoot = (startNum + 5).toString().split("");
      for (let i = 0; i < digits.length; i++) this.addDigit("0", true, el);
      this.scrollNumber(
        digits.map(() => "0"),
        el
      );
      setTimeout(() => {
        setTimeout(() => {
          this.scrollNumber(overshoot, el);
        }, 400);
        setTimeout(() => this.scrollNumber(digits, el), 1400);
        this.currNum = startNum;
      }, this.delay);
    },
  },
};
</script>

<style>
.number-roll {
  position: relative;
  display: flex;
  /* width: auto; */
  overflow: hidden;
  height: 36px;
  transition: width 1.8s ease;
  flex-direction: row-reverse;
}

.number-roll > span {
  display: flex;
  text-align: center;
  flex-direction: column;
  opacity: 0;
  flex-shrink: 2;
  position: absolute;
  right: 0;
  left: 0;
  line-height: 36px;
  transition: all 2s ease;
}

.number-roll > span.visible {
  position: static;
  width: auto;
  opacity: 1;
  flex-shrink: 1;
}

.number-roll-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
}

.suffix {
  margin: 0 3px;
  font-size: 20px;
}
</style>
